import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useSearchHistory } from '@/hooks/useSearchHistoryStore';
import TextArea from "./TextArea";
import { Button } from "@/components/ui/button";
import VersionArrows from './VersionArrows';
import { AudioControls } from './AudioControls';
import AudioPlayer from "./AudioPlayer";
import toast from "react-hot-toast";
import { useRewrite } from '@/hooks/useRewrite';
import { __, TranslationOptions } from '@/utils/translationUtils';
import { twMerge } from 'tailwind-merge';
import { Note } from '@/lib/notes/Note';
import {formatDuration} from "@/components/AudioRecordingComponent";

interface NoteEditorProps {
    note: Note;
    onUpdate: (updatedNote: Note) => void;
    audioControlProps?: {
        showPlay?: boolean;
        showCopy?: boolean;
        showTranslate?: boolean;
    };
    className?: string;
}

export const NoteEditor: React.FC<NoteEditorProps> = ({ note, onUpdate, audioControlProps, className }) => {
    const [currentNote, setCurrentNote] = useState<Note>(note);
    const [versionIndex, setVersionIndex] = useState(0);
    const [content, setContent] = useState(note.content);
    const [hasChanges, setHasChanges] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isTranslating, setIsTranslating] = useState(false);
    const [currentAudioIndex, setCurrentAudioIndex] = useState(0);
    const { updateItem } = useSearchHistory();

    const translationOptions: TranslationOptions = { source: 'uiComponents' };

    const {
        isRewriting,
        handleRewrite: handleRewriteFromHook,
    } = useRewrite((updatedNote: Note) => {
        setCurrentNote(updatedNote);
        updateItem(updatedNote);
        onUpdate(updatedNote);
    });

    // Get verboseJson from the current audio's metas
    const verboseJson = useMemo(() => {
        if (!currentNote.audios?.[0]?.metas) return undefined;
        const verboseJsonMeta = currentNote.audios[0].metas.find(m => m.key === 'verboseJson');
        if (!verboseJsonMeta?.value) return undefined;
        try {
            return JSON.parse(verboseJsonMeta.value);
        } catch (e) {
            console.error('Failed to parse verboseJson:', e);
            return undefined;
        }
    }, [currentNote.audios]);

    useEffect(() => {
        setCurrentNote(note);
        setContent(note.content);
        setVersionIndex(0);
    }, [note]);

    const handleTextChange = (value: string, plainText: string) => {
        setContent(plainText);
        setHasChanges(true);
        setIsEditing(true);
    };

    const handleSaveEdit = useCallback(async () => {
        setIsSaving(true);
        try {
            const response = await fetch('/api/audio/edit', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: currentNote.id,
                    newText: content,
                }),
            });

            if (!response.ok) {
                throw new Error(__('Failed to update note', translationOptions));
            }

            const updatedNote: Note = await response.json();
            setCurrentNote(updatedNote);
            updateItem(updatedNote);
            onUpdate(updatedNote);
            toast.success(__('Changes saved successfully', translationOptions));
            setIsEditing(false);
        } catch (error) {
            console.error(__('Failed to save changes:', translationOptions), error);
            toast.error(__('Failed to save changes', translationOptions));
        } finally {
            setIsSaving(false);
        }
    }, [content, currentNote, onUpdate, updateItem]);

    const handleCancelEdit = () => {
        setContent(currentNote.content);
        setIsEditing(false);
    };

    const handleRewrite = useCallback(async (action: string, role?: string, tweakInstruction?: string) => {
        const currentVersion = versionIndex > 0 ? currentNote.versions[versionIndex - 1] : null;
        const versionId = currentVersion ? currentVersion.id : undefined;

        const updatedNote = await handleRewriteFromHook({
            id: currentNote.id,
            action,
            role,
            text: content,
            versionId,
            tweakInstruction,
        });

        if (updatedNote) {
            setCurrentNote(updatedNote);
            setContent(updatedNote.content);
            updateItem(updatedNote);
            onUpdate(updatedNote);
        }
    }, [currentNote, content, handleRewriteFromHook, versionIndex, updateItem, onUpdate]);

    const handleTranslate = useCallback(async (targetLanguage: string) => {
        setIsTranslating(true);
        try {
            const currentVersion = versionIndex > 0 ? currentNote.versions[versionIndex - 1] : null;
            const versionId = currentVersion ? currentVersion.id : undefined;

            const response = await fetch('/api/translate', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    id: currentNote.id,
                    targetLanguage,
                    versionId,
                }),
            });

            if (!response.ok) throw new Error(__('Translation failed', translationOptions));

            const updatedNote: Note = await response.json();
            setCurrentNote(updatedNote);
            setContent(updatedNote.content);
            updateItem(updatedNote);
            onUpdate(updatedNote);
            toast.success(__('Translation successful', translationOptions));
        } catch (error) {
            console.error(__('Translation error:', translationOptions), error);
            toast.error(__('Translation failed. Please try again.', translationOptions));
        } finally {
            setIsTranslating(false);
        }
    }, [currentNote, versionIndex, updateItem, onUpdate]);

    const handlePreviousVersion = useCallback(() => {
        if (currentNote.versions && versionIndex < currentNote.versions.length) {
            setVersionIndex(prevIndex => prevIndex + 1);
        }
    }, [currentNote, versionIndex]);

    const handleNextVersion = useCallback(() => {
        if (versionIndex > 0) {
            setVersionIndex(prevIndex => prevIndex - 1);
        }
    }, [versionIndex]);

    const getText = useCallback(() => {
        if (versionIndex === 0) {
            return currentNote.content;
        }
        const version = currentNote.versions[versionIndex - 1];
        return version.content;
    }, [currentNote, versionIndex]);

    useEffect(() => {
        setContent(getText());
    }, [getText]);

    const handlePlay = useCallback((e: React.MouseEvent, audioId: string) => {
        e.stopPropagation();
        const audioIndex = currentNote.audios.findIndex(audio => audio.id === audioId);
        setCurrentAudioIndex(audioIndex);
        setIsPlaying(true);
    }, [currentNote]);

    const handleStop = useCallback(() => {
        setIsPlaying(false);
    }, []);

    const handleCopy = useCallback(async () => {
        try {
            await navigator.clipboard.writeText(content);
            toast.success(__('Text copied to clipboard', translationOptions));
        } catch (err) {
            console.error(__('Failed to copy text:', translationOptions), err);
            toast.error(__('Failed to copy text', translationOptions));
        }
    }, [content]);

    const durationDisplay = useMemo(() => {
        if (currentNote.audios && currentNote.audios.length > 0) {
            const totalOriginalDuration = currentNote.audios.reduce((sum, audio) => sum + (audio.originalDurationSeconds || 0), 0);
            const totalProcessedDuration = currentNote.audios.reduce((sum, audio) => sum + (audio.processedDurationSeconds || 0), 0);
            const originalDuration = formatDuration(totalOriginalDuration);
            const processedDuration = formatDuration(totalProcessedDuration);

            return `${originalDuration} (${processedDuration})`;
        }
        return null;
    }, [currentNote]);

    const hasAudios = useMemo(() => currentNote.audios && currentNote.audios.length > 0, [currentNote.audios]);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
            handleSaveEdit();
        }
    };

    return (
        <div className={twMerge("walkthrough-noteeditor-container flex flex-col flex-grow", className)}>
            <div className="walkthrough-noteeditor-header flex justify-between items-center mb-4">
                <div className="walkthrough-noteeditor-info flex items-center space-x-4">
                    {durationDisplay && (
                        <span className="walkthrough-noteeditor-duration text-sm font-medium text-gray-600 dark:text-gray-400">
                            {durationDisplay}
                        </span>
                    )}
                </div>
            </div>
            <div className="walkthrough-noteeditor-textarea-container relative flex-grow flex flex-col">
                <TextArea
                    value={content}
                    onChange={handleTextChange}
                    mode="stt"
                    verboseJson={verboseJson}
                    // readOnly={isEditing}
                    onKeyDown={handleKeyDown}
                />
                {currentNote.versions && currentNote.versions.length > 0 && (
                    <VersionArrows
                        currentVersionIndex={versionIndex}
                        totalVersions={currentNote.versions.length + 1}
                        onPrevious={handlePreviousVersion}
                        onNext={handleNextVersion}
                        className="walkthrough-noteeditor-versionarrows absolute bottom-2 right-2"
                    />
                )}
            </div>
            {isEditing && (
                <div className="walkthrough-noteeditor-editbuttons mt-2 flex justify-end space-x-2">
                    <Button
                        onClick={handleCancelEdit}
                        variant="outline"
                        size="sm"
                        className="walkthrough-noteeditor-cancelbutton">
                        {__('Cancel', translationOptions)}
                    </Button>
                    <Button
                        onClick={handleSaveEdit}
                        variant="primary"
                        size="sm"
                        disabled={isSaving || !hasChanges}
                        className="walkthrough-noteeditor-savebutton"
                    >
                        {isSaving ? __('Saving...', translationOptions) : __('Save Changes', translationOptions)}
                    </Button>
                </div>
            )}
            {isPlaying && currentNote.audios && currentNote.audios.length > 0 && (
                <AudioPlayer
                    audioSrc={`/api/audio/${currentNote.audios[currentAudioIndex].id}`}
                    className="walkthrough-noteeditor-audioplayer mt-2 mb-2"
                    autoplay={isPlaying}
                />
            )}
            {hasAudios && (
                <div className="walkthrough-noteeditor-audiocontrols mt-2 flex items-center space-x-2">
                    <Button
                        variant="ghost"
                        size="sm"
                        onClick={(e) => isPlaying ? handleStop() : handlePlay(e, currentNote.audios[0].id)}
                        className="walkthrough-noteeditor-playbutton"
                    >
                        {isPlaying ? __('Pause', translationOptions) : __('Play', translationOptions)}
                    </Button>
                    {currentNote.audios.length > 1 && (
                        <div className="walkthrough-noteeditor-audiolist">
                            {currentNote.audios.map((audio, index) => (
                                <Button
                                    key={audio.id}
                                    variant="ghost"
                                    size="sm"
                                    onClick={(e) => handlePlay(e, audio.id)}
                                    className={`walkthrough-noteeditor-audioitem ${isPlaying && currentAudioIndex === index ? 'bg-blue-100 dark:bg-blue-900' : ''}`}
                                >
                                    {__('Audio {{index}}', { placeholders: { index: index + 1 }, ...translationOptions })}
                                </Button>
                            ))}
                        </div>
                    )}
                </div>
            )}
            <div className="walkthrough-noteeditor-audiocontrols mt-4">
                <AudioControls
                    item={currentNote}
                    onCopy={handleCopy}
                    onTranslate={handleTranslate}
                    text={content}
                    isTranslating={isTranslating}
                    noteId={currentNote.id}
                    onRewrite={handleRewrite}
                    isRewriting={isRewriting}
                    showPlay={audioControlProps?.showPlay}
                    showCopy={audioControlProps?.showCopy}
                    showTranslate={audioControlProps?.showTranslate}
                />
            </div>
        </div>
    );
};

export default NoteEditor;