import { __ } from '@/utils/translationUtils';
import React from 'react';

export interface WalkthroughStep {
  target: string | null;
  title: string;
  content: string | React.ReactNode | (() => React.ReactNode);
  contentClasses?: string;
  placement?: 'top' | 'top-start' | 'top-end' | 'bottom' | 'bottom-start' | 'bottom-end' | 'left' | 'right' | 'center';
  waitForInteraction?: boolean;
  optional?: boolean;
  condition?: () => boolean;
  checkInteraction?: () => boolean;
  showOverlay?: boolean;
  nextOrFinishButton?: string;
  waitBeforeDisplay?: number;
}

export interface Walkthrough {
  steps: WalkthroughStep[];
  displayFrequency: 'once' | 'always' | number;
  maxDisplayCount?: number;
  priority: number;
  skipDelay: number;
  requiredVisibleElements?: string[];
  type: 'standard' | 'progressive';
}

const createStep = (defaults: Partial<WalkthroughStep>) => (overrides: Partial<WalkthroughStep> = {}): WalkthroughStep => ({
  target: null, title: '', content: '', placement: 'center', ...defaults, ...overrides,
});

const commonSteps = {
  welcome: (props: Partial<WalkthroughStep> = {}) => createStep({
    target: null,
    title: __("Welcome to Sondeas! 👋", { source: 'uiComponents' }),
    content: (
      <div className="prose dark:prose-invert">
        <p className="font-semibold">{__("Sondeas is your ultimate tool for:", { source: 'uiComponents' })}</p>
        <ul className="list-disc pl-4 mt-2">
          <li>{__("🧠 Long-form thinking", { source: 'uiComponents' })}</li>
          <li>{__("💡 Brainstorming", { source: 'uiComponents' })}</li>
          <li>{__("🎙️ Voice-first note creation", { source: 'uiComponents' })}</li>
        </ul>
        <p className="font-semibold mt-3">{__("Create notes by:", { source: 'uiComponents' })}</p>
        <ul className="list-disc pl-4 mt-2">
          <li>{__("⌨️ Typing", { source: 'uiComponents' })}</li>
          <li>{__("🗣️ Speaking (100+ languages)", { source: 'uiComponents' })}</li>
        </ul>
      </div>
    ),
    placement: 'center',
  })(props),
  voiceFirstRevolution: (props: Partial<WalkthroughStep> = {}) => createStep({
    target: null,
    title: __("Voice-First Revolution 🚀", { source: 'uiComponents' }),
    content: (
      <div className="prose dark:prose-invert">
        <strong>{__("Key Features:", { source: 'uiComponents' })}</strong>
        <ul className="list-disc pl-4 mt-2">
          <li>{__("🔴 Record thoughts without worrying about silences", { source: 'uiComponents' })}</li>
          <li>{__("⏱️ Only speaking time is counted", { source: 'uiComponents' })}</li>
          <li>{__("🎧 Recordings are always available for you to listen to", { source: 'uiComponents' })}</li>
          <li>{__("🔄 Text and voice preserved across all devices", { source: 'uiComponents' })}</li>
        </ul>
      </div>
    ),
    placement: 'center',
  })(props),
  gettingStarted: (props: Partial<WalkthroughStep> = {}) => createStep({
    target: null,
    title: __("Getting Started 🏁", { source: 'uiComponents' }),
    content: (
      <div className="prose dark:prose-invert">
        <p><strong>{__("For the best experience:", { source: 'uiComponents' })}</strong></p>
        <ul className="list-disc pl-4 mt-2">
          <li>{__("🔊 Test your microphone before long recordings", { source: 'uiComponents' })}</li>
          <li>{__("🎧 Use a headset or microphone for better audio", { source: 'uiComponents' })}</li>
          <li>{__("📱 Install Sondeas as a web app (easy & secure)", { source: 'uiComponents' })}</li>
        </ul>
      </div>
    ),
    placement: 'center',
  })(props),
  hereToHelp: (props: Partial<WalkthroughStep> = {}) => createStep({
    target: null,
    title: __("We're Here to Help 🤝", { source: 'uiComponents' }),
    content: (
      <div className="prose dark:prose-invert">
        <p><strong>{__("Need assistance?", { source: 'uiComponents' })}</strong></p>
        <ul className="list-disc pl-5 mt-3">
          <li>{__("📧 Contact us: jesus@sondeas.com", { source: 'uiComponents' })}</li>
          <li>{__("💬 Use the feedback option in the menu", { source: 'uiComponents' })}</li>
        </ul>
        <p className="font-bold mt-2">{__("Welcome to Sondeas - enjoy the voice-first revolution! 🎉", { source: 'uiComponents' })}</p>
      </div>
    ),
    placement: 'center',
  })(props),
};

export const walkthroughs: Record<string, Walkthrough> = {
  'welcome': {
    steps: [commonSteps.welcome(), commonSteps.voiceFirstRevolution(), commonSteps.gettingStarted(), commonSteps.hereToHelp()],
    displayFrequency: 'once',
    maxDisplayCount: 5,
    priority: 10,
    skipDelay: 24 * 60 * 60 * 1000,
    type: 'standard',
    requiredVisibleElements: ['body'],
  },
  'navigation-intro-desktop': {
    steps: [
      createStep({ target: '.walkthrough-sidebar-theme-toggle, .desktop-sidebar-theme-toggle', title: __("Theme Toggle", { source: 'uiComponents' }), content: __("Tap this button to switch between dark and light mode.", { source: 'uiComponents' }), placement: 'right' })(),
      createStep({ target: '.walkthrough-sidebar-notes, .sidebar-notes-link', title: __("Your Notes", { source: 'uiComponents' }), content: __("This button takes you to your notes. Tap here to access all your saved notes.", { source: 'uiComponents' }), placement: 'top' })(),
      createStep({ target: '.walkthrough-sidebar-dashboard, .sidebar-dashboard-link', title: __("Dashboard", { source: 'uiComponents' }), content: __("Tap here to view your dashboard with activity summaries and key metrics.", { source: 'uiComponents' }), placement: 'right' })(),
      createStep({ target: '.walkthrough-sidebar-conversation, .sidebar-conversation-link', title: __("Conversation Mode", { source: 'uiComponents' }), content: __("Explore our new Conversation Mode (alpha) for an interactive note-taking experience.", { source: 'uiComponents' }), placement: 'right' })(),
      createStep({
        target: '.walkthrough-sidebar-more-options',
        title: __("More Options", { source: 'uiComponents' }),
        content: (
          <div className="prose dark:prose-invert">
            <p>{__("This menu provides access to additional features and settings:", { source: 'uiComponents' })}</p>
            <ul className="list-disc pl-3 mt-1">
              <li><span className="font-semibold">{__("Subscriptions:", { source: 'uiComponents' })}</span> {__("View and manage your subscription plans", { source: 'uiComponents' })}</li>
              <li><span className="font-semibold">{__("Transactions:", { source: 'uiComponents' })}</span> {__("Check your transaction history and manage payments", { source: 'uiComponents' })}</li>
              <li><span className="font-semibold">{__("Changelog:", { source: 'uiComponents' })}</span> {__("Stay updated with the latest features and improvements", { source: 'uiComponents' })}</li>
            </ul>
          </div>
        ),
        placement: 'right',
      })(),
    ],
    displayFrequency: 'always',
    maxDisplayCount: 5,
    priority: 9,
    skipDelay: 24 * 60 * 60 * 1000,
    type: 'standard',
  },
  'navigation-intro-mobile': {
    steps: [
      createStep({ target: '.mobile-navbar-link.mobile-notes-link', title: __("Your Notes", { source: 'uiComponents' }), content: __("This button takes you to your notes. Tap here to access all your saved notes.", { source: 'uiComponents' }), placement: 'top' })(),
      createStep({ target: '.mobile-navbar-link.mobile-dashboard-link', title: __("Dashboard", { source: 'uiComponents' }), content: __("Tap here to view your dashboard with activity summaries and key metrics.", { source: 'uiComponents' }), placement: 'top' })(),
      createStep({ target: '.mobile-more-options', title: __("More Options", { source: 'uiComponents' }), content: __("Tap here to access additional features and settings.", { source: 'uiComponents' }), placement: 'top', waitForInteraction: true })(),
      createStep({
        target: '.walkthrough-sidebar-mobile-popover-content',
        title: __("More Options Menu", { source: 'uiComponents' }),
        content: (
          <div className="prose dark:prose-invert text-xs sm:text-sm">
            <p className="text-2xs sm:text-xs">{__("This menu provides access to additional features and settings:", { source: 'uiComponents' })}</p>
            <ul className="list-disc pl-4 mt-2 text-2xs sm:text-xs">
              <li><span className="font-semibold">{__("Subscriptions:", { source: 'uiComponents' })}</span> {__("Manage your subscription plans", { source: 'uiComponents' })}</li>
              <li><span className="font-semibold">{__("Transactions:", { source: 'uiComponents' })}</span> {__("Check your transaction history", { source: 'uiComponents' })}</li>
              <li><span className="font-semibold">{__("Changelog:", { source: 'uiComponents' })}</span> {__("Stay updated with the latest features", { source: 'uiComponents' })}</li>
            </ul>
          </div>
        ),
        placement: 'top'
      })(),
      createStep({ target: '.mobile-conversation-link', title: __("Conversation Mode", { source: 'uiComponents' }), content: __("Explore our new Conversation Mode (alpha) for an interactive note-taking experience.", { source: 'uiComponents' }), placement: 'top' })(),
    ],
    displayFrequency: 'always',
    requiredVisibleElements: ['.mobile-navbar'],
    maxDisplayCount: 1,
    priority: 9,
    skipDelay: 24 * 60 * 60 * 1000,
    type: 'standard',
  },
  'note-taker': {
    steps: [
      createStep({ target: '.walkthrough-notetaker-floating', title: __("Note Taker", { source: 'uiComponents' }), content: __("This is your Note Taker. Tap it to start creating new notes or access existing ones.", { source: 'uiComponents' }), placement: 'top', waitForInteraction: true})(),
      createStep({ target: '.walkthrough-modetoggle-container', title: __("Note Taking Modes", { source: 'uiComponents' }), content: __("Switch between 'Type' and 'Speak' modes. 'Type' allows you to enter text notes manually and have them read back to you, while 'Speak' lets you record audio notes that are automatically transcribed.", { source: 'uiComponents' }), placement: 'top' })(),
    ],
    displayFrequency: 'once',
    maxDisplayCount: 5,
    priority: 8,
    skipDelay: 24 * 60 * 60 * 1000,
    type: 'standard',
  },
  'stt': {
    steps: [
      createStep({ target: '.walkthrough-stt-mode', title: __("Voice Recording Mode", { source: 'uiComponents' }), content: __("This is the Voice Recording mode. Here you can record your voice and have it automatically converted into written text.", { source: 'uiComponents' }), placement: 'center' })(),
      createStep({ target: '.walkthrough-microphonetest-button', title: __("Test Microphone", { source: 'uiComponents' }), content: __("Before recording, you can test your microphone here to ensure it's working properly.", { source: 'uiComponents' }), placement: 'top' , waitForInteraction: true})(),
      createStep({ target: '.walkthrough-microphonetest-button', title: __("Test Microphone", { source: 'uiComponents' }), content: __("Say a few words to test your microphone. This ensures your audio is being captured correctly before you start recording.", { source: 'uiComponents' }), placement: 'top' })(),
      createStep({ target: '.walkthrough-recordingcontrols-startbutton', title: __("Start Recording", { source: 'uiComponents' }), content: __("Tap this button to start recording your voice note. The button will turn red when recording is in progress.", { source: 'uiComponents' }), placement: 'top' })(),
      createStep({ target: '.walkthrough-fileuploadbutton-container', title: __("Upload Audio", { source: 'uiComponents' }), content: __("You can also upload audio files for transcription. Click this button to select a file, or simply drag and drop an audio file onto this area.", { source: 'uiComponents' }), placement: 'top' })(),
      createStep({ target: '.walkthrough-stt-container', title: __("Transcription Area", { source: 'uiComponents' }), content: __("As you speak, your words will be transcribed and appear in this area. You can edit the text after the transcription is complete.", { source: 'uiComponents' }), placement: 'top' })(),
      createStep({ target: '.walkthrough-stt-textarea-container .walkthrough-audiocontrols-container', title: __("Actions", { source: 'uiComponents' }), content: __("You can use these buttons to replay the recording, share your note, copy the text, translate your note, rewrite it with AI, and more.", { source: 'uiComponents' }), placement: 'top' })(),
      createStep({ target: '.walkthrough-recordingcontrols-container', title: __("Recording Controls", { source: 'uiComponents' }), content: __("Use these controls to manage your recording. You can pause, resume, or stop the recording at any time.", { source: 'uiComponents' }), placement: 'top' })(),
    ],
    displayFrequency: 'once',
    maxDisplayCount: 5,
    priority: 8,
    skipDelay: 24 * 60 * 60 * 1000,
    type: 'standard',
    requiredVisibleElements: ['.walkthrough-stt-mode', '.walkthrough-microphonetest-button', '.walkthrough-fileuploadbutton-container', '.walkthrough-recordingcontrols-startbutton', '.walkthrough-recordingcontrols-container', '.walkthrough-stt-container'],
  },
  // 'note-taker-intro': {
  //   steps: [
  //     createStep({ target: '.walkthrough-sidebar-notes, .sidebar-notes-link', title: __("Notef Taker", { source: 'uiComponents' }), content: __("This is your Notes section. Tap it to start creating new notes or access existing ones.", { source: 'uiComponents' }), placement: 'right' })(),
  //     createStep({ target: null, title: __("Note Taker Expanded", { source: 'uiComponents' }), content: __("Great! You've opened the Note Taker. Let's explore its features.", { source: 'uiComponents' }), placement: 'center' })(),
  //     createStep({ target: '.walkthrough-modetoggle-container', title: __("Note Taking Modes", { source: 'uiComponents' }), content: __("Switch between 'Type' and 'Speak' modes. 'Speak' lets you record audio notes, while 'Type' allows you to type text notes.", { source: 'uiComponents' }), placement: 'top' })(),
  //   ],
  //   displayFrequency: 'once',
  //   maxDisplayCount: 1,
  //   priority: 7,
  //   skipDelay: 24 * 60 * 60 * 1000,
  //   type: 'standard',
  // },
  // 'voice-note-creation': {
  //   steps: [
  //     createStep({ target: '.walkthrough-microphonetest-button', title: __("Test Microphone", { source: 'uiComponents' }), content: __("Before recording, you can test your microphone here to ensure it's working properly.", { source: 'uiComponents' }), placement: 'top' })(),
  //     createStep({ target: '.walkthrough-recordingcontrols-startbutton', title: __("Start Recording", { source: 'uiComponents' }), content: __("In 'Speak' mode, tap this button to start recording your voice note. Try recording a short message now!", { source: 'uiComponents' }), placement: 'left', waitForInteraction: true, checkInteraction: () => !!document.querySelector('.walkthrough-recording-stop-button') })(),
  //     createStep({ target: '.stt-mode-visualizer-and-processing-container', title: __("Visualizer and Processing", { source: 'uiComponents' }), content: __("This area shows your audio being captured in real-time. If you're using a phone, keep your finger on this part of the screen to prevent it from turning off. This is crucial to keep the recording going without interruption.", { source: 'uiComponents' }), placement: 'left' })(),
  //     createStep({ target: '.walkthrough-recordingcontrols-stopbutton', title: __("Stop Recording", { source: 'uiComponents' }), content: __("When you're done speaking, tap this button to stop the recording and process your note.", { source: 'uiComponents' }), placement: 'left', waitForInteraction: true, checkInteraction: () => !document.querySelector('.walkthrough-recording-stop-button') })(),
  //     createStep({ target: '.stt-mode-visualizer-and-processing-container', title: __("Processing", { source: 'uiComponents' }), content: __("This is the processing area. We're now removing silent parts and enhancing the audio quality. This process can take some time, the longer the recording, the more processing is needed.", { source: 'uiComponents' }), placement: 'left' })(),
  //     createStep({ target: '.walkthrough-stt-textarea', title: __("Transcribed Text", { source: 'uiComponents' }), content: __("This is the transcribed text of your voice note. It will appear here as you speak.", { source: 'uiComponents' }), placement: 'left' })(),
  //   ],
  //   displayFrequency: 'once',
  //   maxDisplayCount: 1,
  //   priority: 6,
  //   skipDelay: 24 * 60 * 60 * 1000,
  //   type: 'standard',
  // },
  // 'ai-features': {
  //   steps: [
  //     createStep({ target: '.walkthrough-audiocontrols-translate', title: __("Translate", { source: 'uiComponents' }), content: __("Use this button to translate your note into different languages.", { source: 'uiComponents' }), placement: 'left', waitForInteraction: true })(),
  //     createStep({ target: '.walkthrough-translatemodal-content', title: __("Translate", { source: 'uiComponents' }), content: __("Select a language to translate your note, there are 100+ to choose from.", { source: 'uiComponents' }), placement: 'bottom' })(),
  //     createStep({ target: '.walkthrough-stt-mode .audio-control-rewrite', title: __("AI Rewrite", { source: 'uiComponents' }), content: __("This AI feature can help improve the syntax and structure of your note.", { source: 'uiComponents' }), placement: 'top' })(),
  //   ],
  //   displayFrequency: 'once',
  //   maxDisplayCount: 1,
  //   priority: 5,
  //   skipDelay: 24 * 60 * 60 * 1000,
  //   type: 'standard',
  // },
  // 'ai-rewrite-options': {
  //   steps: [
  //     createStep({ target: '.walkthrough-airewritepopover-content', title: __("Rewrite with AI", { source: 'uiComponents' }), content: __("Let's explore the AI rewrite feature. Click the rewrite button to open the AI options.", { source: 'uiComponents' }), placement: 'top', waitForInteraction: true })(),
  //     createStep({ target: '.walkthrough-airewritepopover-tweakinput', title: __("Tweak Your Rewrite", { source: 'uiComponents' }), content: __("Enter a specific instruction to customize how the AI rewrites your text.", { source: 'uiComponents' }), placement: 'top' })(),
  //     createStep({ target: '.walkthrough-airewritepopover-applytweak', title: __("Apply Your Tweak", { source: 'uiComponents' }), content: __("Click this button to see how the AI applies your tweak instruction.", { source: 'uiComponents' }), placement: 'top', waitForInteraction: true })(),
  //     createStep({ target: '.walkthrough-audiorecording-versionarrows', title: __("Navigate Versions", { source: 'uiComponents' }), content: __("Use these arrows to switch between different versions of your rewritten text.", { source: 'uiComponents' }), placement: 'bottom' })(),
  //   ],
  //   displayFrequency: 'once',
  //   maxDisplayCount: 1,
  //   priority: 4,
  //   skipDelay: 24 * 60 * 60 * 1000,
  //   type: 'standard',
  // },
  // 'advanced-ai-features': {
  //   steps: [
  //     createStep({ target: '.ai-rewrite-structure-button', title: __("Structure Your Text", { source: 'uiComponents' }), content: __("Now, let's try structuring your text. Click the 'Structure' button to organize your note better.", { source: 'uiComponents' }), placement: 'top', waitForInteraction: true })(),
  //     createStep({ target: '.walkthrough-notetaker-textarea', title: __("Structured Text", { source: 'uiComponents' }), content: __("Here's your structured text. Notice how it's been organized for better readability.", { source: 'uiComponents' }), placement: 'top' })(),
  //     createStep({ target: '.walkthrough-airewritepopover-rewriteasbutton', title: __("Rewrite as a Character", { source: 'uiComponents' }), content: __("Try rewriting your note as a famous character. Click this button and type any name you like! It can be a real person or a fictional character.", { source: 'uiComponents' }), placement: 'top', waitForInteraction: true })(),
  //     createStep({ target: '.walkthrough-notetaker-textarea', title: __("Rewritten Text", { source: 'uiComponents' }), content: __("Here's your rewritten text. Notice how it's been transformed to match the character you chose. Your choice is remembered for future notes.", { source: 'uiComponents' }), placement: 'top' })(),
  //     createStep({ target: '.walkthrough-airewritepopover-tweakbutton', title: __("Custom Tweak", { source: 'uiComponents' }), content: __("Finally, let's try a custom tweak. Click 'Tweak with AI' and type something like 'convert into an email'.", { source: 'uiComponents' }), placement: 'top', waitForInteraction: true })(),
  //     createStep({ target: '.walkthrough-notetaker-textarea', title: __("Customized Text", { source: 'uiComponents' }), content: __("Here's your customized text. Notice how it's been transformed to match your request. Your choice is remembered for future notes.", { source: 'uiComponents' }), placement: 'top' })(),
  //   ],
  //   displayFrequency: 'once',
  //   maxDisplayCount: 1,
  //   priority: 3,
  //   skipDelay: 24 * 60 * 60 * 1000,
  //   type: 'standard',
  // },
  // 'note-actions': {
  //   steps: [
  //     createStep({ target: '.walkthrough-audiocontrols-share', title: __("Share", { source: 'uiComponents' }), content: __("Easily share your note with others using this button.", { source: 'uiComponents' }), placement: 'top' })(),
  //     createStep({ target: '.walkthrough-audiocontrols-playstopbutton', title: __("Play Audio", { source: 'uiComponents' }), content: __("You can play back the audio of your recorded note at any time by tapping this button.", { source: 'uiComponents' }), placement: 'top' })(),
  //   ],
  //   displayFrequency: 'once',
  //   maxDisplayCount: 1,
  //   priority: 2,
  //   skipDelay: 24 * 60 * 60 * 1000,
  //   type: 'standard',
  // },
  // 'text-note-creation': {
  //   steps: [
  //     createStep({ target: '.walkthrough-modetoggle-container', title: __("Switch to Type Mode", { source: 'uiComponents' }), content: __("Now, let's switch to 'Type' mode to create a text note.", { source: 'uiComponents' }), placement: 'bottom', optional: true, condition: () => document.querySelector('.notetaker-tts-mode') !== null, waitForInteraction: true })(),
  //     createStep({ target: '.walkthrough-notetaker-textarea', title: __("Type Your Note", { source: 'uiComponents' }), content: __("In 'Type' mode, you can type your note here. Try typing a short message.", { source: 'uiComponents' }), placement: 'top', waitForInteraction: true })(),
  //     createStep({ target: '.walkthrough-notetaker-newnotebutton', title: __("Save Text Note", { source: 'uiComponents' }), content: __("Tap here to save your typed note.", { source: 'uiComponents' }), placement: 'left', waitForInteraction: true })(),
  //     createStep({ target: '.walkthrough-audiocontrols-generateaudiobutton', title: __("Generate Audio", { source: 'uiComponents' }), content: __("You can also generate an audio version of your typed note by tapping this button.", { source: 'uiComponents' }), placement: 'bottom' })(),
  //   ],
  //   displayFrequency: 'once',
  //   maxDisplayCount: 1,
  //   priority: 1,
  //   skipDelay: 24 * 60 * 60 * 1000,
  //   type: 'standard',
  // },
  // 'note-taker-final': {
  //   steps: [
  //     createStep({ target: '.walkthrough-notetakerbutton-container', title: __("Collapse Note Taker", { source: 'uiComponents' }), content: __("When you're done, you can collapse the Note Taker by tapping this button.", { source: 'uiComponents' }), placement: 'top', waitForInteraction: true })(),
  //     createStep({ target: '.mobile-navbar-link.note-taker-button, .note-taker-button', title: __("Expand Note Taker", { source: 'uiComponents' }), content: __("Remember, you can always expand the Note Taker again by tapping this button from anywhere in the app.", { source: 'uiComponents' }), placement: 'top' })(),
  //   ],
  //   displayFrequency: 'once',
  //   maxDisplayCount: 1,
  //   priority: 0,
  //   skipDelay: 24 * 60 * 60 * 1000,
  //   type: 'standard',
  // },
};