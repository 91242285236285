'use client';

import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {
    ArrowLeftIcon,
    ArrowsRightLeftIcon,
    CheckCircleIcon,
    EyeIcon,
    EyeSlashIcon,
    XCircleIcon,
    XMarkIcon
} from '@heroicons/react/24/solid';
import {useEUP} from '@/contexts/EUPContext';
import LoadingSpinner from '@/components/LoadingSpinner';
import {__} from '@/utils/translationUtils';
import {useRouter} from 'next/navigation';

/* --- Reusable Components --- */

// Password Input Component
const PasswordInput = ({
                           id,
                           label,
                           value,
                           onChange,
                           showPassword,
                           toggleShowPassword,
                           placeholder,
                           required = false,
                           minLength
                       }: {
    id: string,
    label: string,
    value: string,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    showPassword: boolean,
    toggleShowPassword: () => void,
    placeholder: string,
    required?: boolean,
    minLength?: number
}) => (
    <div>
        <label htmlFor={id} className="block text-sm font-medium text-gray-700 dark:text-gray-200">{label}</label>
        <div className="mt-1 relative rounded-md shadow-sm">
            <input
                id={id}
                type={showPassword ? "text" : "password"}
                value={value}
                onChange={onChange}
                className="block w-full pr-10 py-3 border-gray-300 dark:border-gray-600 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-gray-50 dark:bg-gray-800 dark:text-gray-100"
                placeholder={placeholder}
                minLength={minLength}
                required={required}
            />
            <button
                type="button"
                className="absolute inset-y-0 right-0 pr-3 flex items-center"
                onClick={toggleShowPassword}
            >
                {showPassword ? (
                    <EyeSlashIcon className="h-5 w-5 text-gray-400 dark:text-gray-500"/>
                ) : (
                    <EyeIcon className="h-5 w-5 text-gray-400 dark:text-gray-500"/>
                )}
            </button>
        </div>
    </div>
);

// Password Match Indicator Component
const PasswordMatchIndicator = ({passwordsMatch}: { passwordsMatch: boolean }) => (
    <div className="h-6">
        <div className="flex items-center">
            {passwordsMatch ? (
                <>
                    <CheckCircleIcon className="h-5 w-5 text-green-500 dark:text-green-400 mr-2"/>
                    <span className="text-green-500 dark:text-green-400 text-sm">{__('Passwords match')}</span>
                </>
            ) : (
                <>
                    <XCircleIcon className="h-5 w-5 text-red-500 dark:text-red-400 mr-2"/>
                    <span className="text-red-500 dark:text-red-400 text-sm">{__('Passwords do not match')}</span>
                </>
            )}
        </div>
    </div>
);

// Progress Bar Component
const ProgressBar = ({progress}: { progress: number }) => (
    <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2.5 mb-4">
        <div
            className="bg-blue-600 dark:bg-blue-500 h-2.5 rounded-full transition-all duration-500 ease-in-out"
            style={{width: `${progress * 100}%`}}
        ></div>
    </div>
);

// Step Component
const Step = ({title, content, buttonText, onNext}: {
    title: string,
    content: string,
    buttonText: string,
    onNext: () => void
}) => (
    <div className="text-center">
        <h2 className="text-2xl font-bold mb-4 text-indigo-600 dark:text-indigo-400">{title}</h2>
        <p className="mb-6 text-gray-600 dark:text-gray-300">{content}</p>
        <button
            onClick={onNext}
            className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600 transition-colors duration-200"
        >
            {buttonText}
        </button>
    </div>
);

// Password Form Component
const PasswordForm = ({
                          title,
                          description,
                          up,
                          setUP,
                          showPassword,
                          setShowPassword,
                          upConfirm,
                          setUPConfirm,
                          showConfirmPassword,
                          setShowConfirmPassword,
                          passwordsMatch,
                          error,
                          continueInBackground,
                          setContinueInBackground,
                          handleSubmit,
                          handleSkip,
                          buttonText = __("Secure My Data"),
                          isLoading,
                      }: {
    title?: string,
    description?: string,
    up: string,
    setUP: (value: string) => void,
    showPassword: boolean,
    setShowPassword: (value: boolean) => void,
    upConfirm?: string,
    setUPConfirm?: (value: string) => void,
    showConfirmPassword?: boolean,
    setShowConfirmPassword?: (value: boolean) => void,
    passwordsMatch?: boolean,
    error?: string,
    continueInBackground?: boolean,
    setContinueInBackground?: (value: boolean) => void,
    handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void,
    handleSkip?: () => void,
    buttonText?: string,
    isLoading: boolean,
}) => (
    <form onSubmit={handleSubmit} className="space-y-4">
        {title && <h2 className="text-2xl font-bold mb-4 text-indigo-600 dark:text-indigo-400">{title}</h2>}
        {description && <p className="mb-4 text-gray-600 dark:text-gray-300">{description}</p>}
        <PasswordInput
            id="password"
            label={__("Your Privacy Password")}
            value={up}
            onChange={(e) => setUP(e.target.value)}
            showPassword={showPassword}
            toggleShowPassword={() => setShowPassword(!showPassword)}
            placeholder={__("Enter your privacy password")}
            required={true}
            minLength={4}
        />
        {upConfirm !== undefined && setUPConfirm && showConfirmPassword !== undefined && setShowConfirmPassword && (
            <>
                <PasswordInput
                    id="confirmPassword"
                    label={__("Confirm Privacy Password")}
                    value={upConfirm}
                    onChange={(e) => setUPConfirm(e.target.value)}
                    showPassword={showConfirmPassword}
                    toggleShowPassword={() => setShowConfirmPassword(!showConfirmPassword)}
                    placeholder={__("Confirm your privacy password")}
                    required={true}
                    minLength={4}
                />
                {up && upConfirm && passwordsMatch !== undefined && (
                    <PasswordMatchIndicator passwordsMatch={passwordsMatch}/>
                )}
            </>
        )}
        {error && <p className="text-red-500 dark:text-red-400 text-sm">{error}</p>}
        <div className="flex justify-between">
            <button
                type="submit"
                className="flex-1 mr-2 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600 disabled:opacity-50 transition-colors duration-200"
                disabled={isLoading}
            >
                {isLoading ? (
                    <LoadingSpinner size="1.5em" thickness={2} speed={0.8}/>
                ) : (
                    buttonText
                )}
            </button>
            {handleSkip && (
                <button
                    type="button"
                    onClick={handleSkip}
                    className="flex-1 ml-2 py-2 px-4 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 disabled:opacity-50 transition-colors duration-200"
                    disabled={isLoading}
                >
                    {__("Skip for Now")}
                </button>
            )}
        </div>
    </form>
);

// Encryption Progress Component
const EncryptionProgress = ({progress, continueInBackground, handleToggleBackground, error}: {
    progress: number, continueInBackground: boolean, handleToggleBackground: () => void, error: string
}) => (
    <div className="text-center">
        <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-gray-100">{__("Encrypting Your Data")}</h2>
        <p className="mb-4 text-gray-600 dark:text-gray-300">
            {continueInBackground
                ? __("Encryption is running in the background. You can continue using the app.")
                : __("Please keep this window open while we secure your data.")}
        </p>
        <p className="text-sm text-gray-600 dark:text-gray-400 mb-4">
            {__("This process may take a few minutes to complete. If it gets stuck or fails, simply refresh the page. Do not close your browser during encryption.")}
        </p>
        <ProgressBar progress={progress}/>
        <p className="text-sm text-gray-600 dark:text-gray-400 mb-4">{Math.round(progress * 100)}% {__("complete")}</p>
        <button
            onClick={handleToggleBackground}
            className="flex items-center justify-center w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600 transition-colors duration-200"
        >
            <ArrowsRightLeftIcon className="h-5 w-5 mr-2"/>
            {continueInBackground ? __("Switch to Foreground") : __("Continue in Background")}
        </button>
        {error && <p className="text-red-500 dark:text-red-400 text-sm mt-4">{error}</p>}
    </div>
);

// Background Encryption Component
const BackgroundEncryption = ({setStep, totalSteps}: { setStep: (value: number) => void, totalSteps: number }) => (
    <div className="text-center">
        <h2 className="text-2xl font-bold mb-4 text-indigo-600 dark:text-indigo-400">{__("Encryption in Progress")}</h2>
        <p className="mb-4 text-gray-600 dark:text-gray-300">{__("Your data is being encrypted in the background. You can continue using the app.")}</p>
        <button
            onClick={() => setStep(totalSteps + 1)}
            className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600 transition-colors duration-200"
        >
            {__("View Progress")}
        </button>
    </div>
);

// Floating Progress Notification Component
export const FloatingProgressNotification = ({progress, onExpand}: { progress: number, onExpand: () => void }) => (
    <div className="fixed bottom-4 right-4 bg-white dark:bg-gray-800 rounded-lg shadow-lg p-4 z-50 flex items-center">
        <div className="mr-4">
            <div className="text-sm font-medium text-gray-900 dark:text-gray-100">{__("Encryption Progress")}</div>
            <div className="mt-1 w-48 bg-gray-200 dark:bg-gray-700 rounded-full h-2.5">
                <div
                    className="bg-blue-600 dark:bg-blue-500 h-2.5 rounded-full transition-all duration-500 ease-in-out"
                    style={{width: `${progress * 100}%`}}
                ></div>
            </div>
            <div
                className="text-xs text-gray-500 dark:text-gray-400 mt-1">{Math.round(progress * 100)}% {__("complete")}</div>
        </div>
        <button onClick={onExpand}
                className="text-gray-400 hover:text-gray-500 dark:text-gray-500 dark:hover:text-gray-400">
            <ArrowsRightLeftIcon className="h-5 w-5"/>
        </button>
    </div>
);

// Skip Warning Component
const SkipWarning = ({onConfirmSkip, onCancel}: { onConfirmSkip: () => void, onCancel: () => void }) => (
    <div className="text-center">
        <h2 className="text-2xl font-bold mb-4 text-red-600 dark:text-red-500">{__("Important Notice")}</h2>
        <p className="mb-6 text-gray-600 dark:text-gray-300">
            {__("You can skip the encryption setup to view existing notes, but you won't be able to create new ones. Encryption is now mandatory for all new content. You can set up encryption later, but it's recommended to do it now for the best experience.")}
        </p>
        <div className="flex space-x-4">
            <button
                onClick={onCancel}
                className="flex-1 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600 transition-colors duration-200"
            >
                {__("Go Back")}
            </button>
            <button
                onClick={onConfirmSkip}
                className="flex-1 py-2 px-4 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors duration-200"
            >
                {__("Skip Anyway")}
            </button>
        </div>
    </div>
);

// First, add this new component near the other component definitions
const EncryptionComplete = ({onClose}: { onClose: () => void }) => (
    <div className="text-center">
        <div className="mb-6">
            <CheckCircleIcon className="h-16 w-16 text-green-500 dark:text-green-400 mx-auto animate-bounce"/>
        </div>
        <h2 className="text-2xl font-bold mb-4 text-green-600 dark:text-green-500">{__("Encryption Complete! 🎉")}</h2>
        <p className="mb-6 text-gray-600 dark:text-gray-300">
            {__("Your private space is now fully secured. All your notes and recordings will be automatically encrypted, ensuring your thoughts remain private and protected.")}
        </p>
        <button
            onClick={onClose}
            className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 dark:bg-green-500 dark:hover:bg-green-600 transition-colors duration-200"
        >
            {__("Start Using Sondeas Securely")}
        </button>
    </div>
);

/* --- Main Component --- */

const SetEUPPasswordPrompt = forwardRef((props, ref) => {
    console.log('Rendering SetEUPPasswordPrompt');

    const {
        setEUP,
        isProcessing,
        progress: contextProgress,
        skipEUPSetup,
        continueInBackground,
        setContinueInBackground,
        showFloatingNotification,
        setShowFloatingNotification,
        showPromptAfterSkip,
        setShowPromptAfterSkip,
    } = useEUP();

    const router = useRouter(); // Add this near other hooks

    const [step, setStep] = useState(0);
    const [up, setUP] = useState('');
    const [upConfirm, setUPConfirm] = useState('');
    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(false);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [skipped, setSkipped] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const wakeLockRef = useRef<WakeLockSentinel | null>(null);

    const steps = [
        {
            title: __("True Digital Freedom"),
            content: __("Your mind is your sanctuary. At Sondeas, we've built an impenetrable vault where your thoughts remain yours alone - a level of privacy unmatched by any other platform."),
        },
        {
            title: __("Zero-Knowledge Architecture"),
            content: __("Our military-grade encryption ensures absolute privacy. Not even our own systems can peek inside your encrypted content - this is privacy by design, not by promise."),
        },
        {
            title: __("Sovereign Digital Space"),
            content: __("Your content remains completely private, protected by mathematical certainty. No entity - corporate or governmental - can access your notes without your explicit consent."),
        },
        {
            title: __("The Power of Your Key"),
            content: __("Your encryption password is the only gateway to your private realm. Choose it thoughtfully - without it, your encrypted content becomes mathematically impossible to recover."),
        },
        {
            title: __("One Password, One Vault"),
            content: __("Our robust encryption requires a permanent password. While we'll enable password changes in future versions, for now, choose a password you'll safeguard for the long term."),
        },
        {
            title: __("Embrace True Privacy"),
            content: __("You're about to enter a space of absolute digital privacy. Your password will be the master key to your encrypted sanctuary. Choose it carefully - it's the foundation of your private digital space."),
        },
    ];

    useImperativeHandle(ref, () => ({}));

    // Keep screen awake during encryption
    useEffect(() => {
        const acquireWakeLock = async () => {
            if ('wakeLock' in navigator && isProcessing) {
                try {
                    wakeLockRef.current = await navigator.wakeLock.request('screen');
                    console.log('Wake Lock acquired for encryption process');
                } catch (err) {
                    console.error('Failed to acquire Wake Lock:', err);
                }
            }
        };

        const releaseWakeLock = async () => {
            if (wakeLockRef.current) {
                try {
                    await wakeLockRef.current.release();
                    wakeLockRef.current = null;
                    console.log('Wake Lock released');
                } catch (err) {
                    console.error('Failed to release Wake Lock:', err);
                }
            }
        };

        if (isProcessing) {
            acquireWakeLock();
        } else {
            releaseWakeLock();
        }

        return () => {
            releaseWakeLock();
        };
    }, [isProcessing]);

    useEffect(() => {
        console.log('isProcessing changed:', isProcessing);
        if (isProcessing) {
            setStep(steps.length + 1);
        }
    }, [isProcessing, steps.length]);

    useEffect(() => {
        console.log('Password fields changed - checking match');
        if (up && upConfirm) {
            setPasswordsMatch(up === upConfirm);
        } else {
            setPasswordsMatch(false);
        }
    }, [up, upConfirm]);

    useEffect(() => {
        console.log('Progress check - isProcessing:', isProcessing, 'contextProgress:', contextProgress);
        if (isProcessing && contextProgress === 1) {
            console.log('Setting isComplete to true');
            setIsComplete(true);
        }
    }, [isProcessing, contextProgress]);

    const goToNextStep = () => {
        console.log('Going to next step from:', step);
        setIsTransitioning(true);
        setTimeout(() => {
            setStep((prevStep) => {
                console.log('Setting step from', prevStep, 'to', prevStep + 1);
                return prevStep + 1;
            });
            setIsTransitioning(false);
        }, 300);
    };

    const goToPreviousStep = () => {
        console.log('Going to previous step from:', step);
        setIsTransitioning(true);
        setTimeout(() => {
            setStep((prevStep) => {
                console.log('Setting step from', prevStep, 'to', prevStep - 1);
                return prevStep - 1;
            });
            setIsTransitioning(false);
        }, 300);
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        console.log('Handling form submission');
        e.preventDefault();
        setError('');
        setIsSubmitting(true);

        try {
            if (up !== upConfirm) {
                console.log('Password mismatch detected');
                setError(__('Passwords do not match. Please try again.'));
                setIsSubmitting(false);
                return;
            }
            console.log('Attempting to set EUP with continueInBackground:', continueInBackground);
            await setEUP(up, continueInBackground);
            if (continueInBackground) {
                console.log('Setting showFloatingNotification to true');
                setShowFloatingNotification(true);
            } else {
                console.log('Moving to encryption step');
                setStep(steps.length + 1);
            }
        } catch (error) {
            console.error('Error setting EUP:', error);
            setError(__('Oops! Something went wrong. Please try again.'));
        } finally {
            console.log('Form submission complete');
            setIsSubmitting(false);
        }
    };

    const handleSkip = () => {
        console.log('Handling skip, moving to step:', steps.length + 3);
        setStep(steps.length + 3);
    };

    const handleConfirmSkip = () => {
        console.log('Confirming skip');
        setIsSubmitting(true);
        skipEUPSetup(); // This now includes the timeout to show the prompt again
        setIsSubmitting(false);
        setSkipped(true);
    };

    const handleToggleBackground = () => {
        console.log('Toggling background mode from:', continueInBackground, 'to:', !continueInBackground);
        setContinueInBackground(!continueInBackground);
        setShowFloatingNotification(!continueInBackground);
    };

    const handleCompletionClose = () => {
        console.log('Handling completion close');
        setSkipped(true);
        setShowFloatingNotification(false);
        window.location.reload();
    };

    // Add effect to reset state when prompt is shown again
    useEffect(() => {
        if (showPromptAfterSkip) {
            console.log('Showing prompt again after skip');
            setSkipped(false);
            setStep(0);
            setShowPromptAfterSkip(false); // Reset the flag
        }
    }, [showPromptAfterSkip, setShowPromptAfterSkip]);

    const renderStepContent = () => {
        console.log('Rendering step content for step:', step, 'isComplete:', isComplete);

        if (isComplete) {
            console.log('Rendering completion screen');
            return <EncryptionComplete onClose={handleCompletionClose}/>;
        }

        if (step < steps.length) {
            console.log('Rendering intro step:', step);
            const currentStep = steps[step];
            return (
                <Step
                    title={currentStep.title}
                    content={currentStep.content}
                    buttonText={step === steps.length - 1 ? __("Set My Privacy Password") : __("Next")}
                    onNext={goToNextStep}
                />
            );
        }

        if (step === steps.length) {
            console.log('Rendering password form');
            return (
                <PasswordForm
                    title={__("Set Your Privacy Password")}
                    description={__("Choose a strong password to protect your private data. This password will be used to encrypt and decrypt your sensitive information.")}
                    up={up}
                    setUP={setUP}
                    showPassword={showPassword}
                    setShowPassword={setShowPassword}
                    upConfirm={upConfirm}
                    setUPConfirm={setUPConfirm}
                    showConfirmPassword={showConfirmPassword}
                    setShowConfirmPassword={setShowConfirmPassword}
                    passwordsMatch={passwordsMatch}
                    error={error}
                    continueInBackground={continueInBackground}
                    setContinueInBackground={setContinueInBackground}
                    handleSubmit={handleSubmit}
                    handleSkip={handleSkip}
                    buttonText={__("Set Password")}
                    isLoading={isSubmitting}
                />
            );
        }

        if (step === steps.length + 2) {
            console.log('Rendering background encryption');
            return <BackgroundEncryption setStep={setStep} totalSteps={steps.length}/>;
        }

        if (step === steps.length + 3) {
            console.log('Rendering skip warning');
            return (
                <SkipWarning
                    onConfirmSkip={handleConfirmSkip}
                    onCancel={() => setStep(steps.length)}
                />
            );
        }

        if (isProcessing || step > steps.length) {
            console.log('Rendering encryption progress');
            return (
                <EncryptionProgress
                    progress={contextProgress}
                    continueInBackground={continueInBackground}
                    handleToggleBackground={handleToggleBackground}
                    error={error}
                />
            );
        }

        console.log('No content to render');
        return null;
    };

    return (
        <>
            {!skipped && ((!continueInBackground || !isProcessing || isComplete) && (
                <div
                    className="fixed inset-0 bg-gray-600 dark:bg-gray-900 bg-opacity-50 dark:bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white dark:bg-gray-800 p-10 pb-4 rounded-lg shadow-xl max-w-md w-full relative">
                        {step > 0 && step <= steps.length && !isComplete && (
                            <button onClick={goToPreviousStep}
                                    className="absolute top-2 left-2 text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200">
                                <ArrowLeftIcon className="h-6 w-6"/>
                            </button>
                        )}
                        <div
                            className={`transition-opacity duration-300 ${isTransitioning ? 'opacity-0' : 'opacity-100'}`}>
                            {renderStepContent()}
                        </div>
                        {step === steps.length && !isComplete && (
                            <p className="mt-4 text-sm text-gray-500 dark:text-gray-400">
                                {__("Remember: This password is the key to your private data. Make sure it's memorable to you but hard for others to guess.")}
                            </p>
                        )}
                    </div>
                </div>
            ))}
            {showFloatingNotification && isProcessing && !isComplete && (
                <FloatingProgressNotification
                    progress={contextProgress}
                    onExpand={() => {
                        console.log('Expanding floating notification');
                        handleToggleBackground();
                    }}
                />
            )}
        </>
    );
});

SetEUPPasswordPrompt.displayName = 'SetEUPPasswordPrompt';

const EnterEUPPasswordPrompt = forwardRef((props, ref) => {
    const {
        verifyEUP,
        isProcessing,
        progress: contextProgress,
        setShowSuccessPrompt,
        showSuccessPrompt,
    } = useEUP();

    const [up, setUP] = useState('');
    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [isVerified, setIsVerified] = useState(false); // New state for tracking verification

    useImperativeHandle(ref, () => ({}));

    useEffect(() => {
        if (isProcessing && contextProgress === 1) {
            setIsComplete(true);
        }
    }, [isProcessing, contextProgress]);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError('');
        setIsSubmitting(true);

        try {
            const isValid = await verifyEUP(up);
            if (!isValid) {
                setError(__('Incorrect password. Please try again.'));
            } else {
                setIsVerified(true); // Set verified state to true on success
            }
        } catch (error) {
            console.error('Error verifying EUP:', error);
            if (error instanceof Error && error.message === '401') {
                setError(__('Incorrect password. Please try again.'));
            } else {
                setError(__('Oops! Something went wrong. Please try again.'));
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleCloseCompletion = () => {
        window.location.reload();
    };

    // Update the VerificationSuccess component
    const VerificationSuccess = ({onClose}: { onClose: () => void }) => (
        <div className="text-center">
            <div className="mb-6">
                <CheckCircleIcon className="h-16 w-16 text-green-500 dark:text-green-400 mx-auto animate-bounce"/>
            </div>
            <h2 className="text-2xl font-bold mb-4 text-green-600 dark:text-green-500">
                {__("Welcome Back! 🎉")}
            </h2>
            <p className="mb-6 text-gray-600 dark:text-gray-300">
                {__("Your identity has been verified. You now have full access to your encrypted notes and recordings.")}
            </p>
            <button
                onClick={() => {
                    onClose();
                }}
                className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 dark:bg-green-500 dark:hover:bg-green-600 transition-colors duration-200"
            >
                {__("Continue to Sondeas")}
            </button>
        </div>
    );

    return (
        <div
            className="fixed inset-0 bg-gray-600 dark:bg-gray-900 bg-opacity-50 dark:bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white dark:bg-gray-800 p-10 pb-4 rounded-lg shadow-xl max-w-md w-full relative">
                {isComplete ? (
                    <EncryptionComplete onClose={() => {
                    }}/>
                ) : isVerified ? ( // Show success message when verified
                    <VerificationSuccess onClose={handleCloseCompletion}/>
                ) : (
                    <PasswordForm
                        title={__("Enter Your Privacy Password")}
                        description={__("It looks like you're connecting from a new device. Please enter your existing privacy password to access your data.")}
                        up={up}
                        setUP={setUP}
                        showPassword={showPassword}
                        setShowPassword={setShowPassword}
                        handleSubmit={handleSubmit}
                        error={error}
                        buttonText={__("Access My Data")}
                        isLoading={isSubmitting}
                    />
                )}
            </div>
        </div>
    );
});

EnterEUPPasswordPrompt.displayName = 'EnterEUPPasswordPrompt';

// Add a new component for the reminder notification
const SkipReminder = ({onSetup}: { onSetup: () => void }) => (
    <div className="fixed bottom-4 right-4 bg-white rounded-lg shadow-lg p-4 z-50 max-w-md">
        <div className="flex items-start">
            <div className="flex-1">
                <h3 className="text-lg font-medium text-gray-900">{__("Secure Your Notes")}</h3>
                <p className="mt-1 text-sm text-gray-500">
                    {__("Your notes are currently unprotected. Set up encryption now to keep them private and secure.")}
                </p>
                <div className="mt-3">
                    <button
                        onClick={onSetup}
                        className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                    >
                        {__("Set Up Encryption")}
                    </button>
                </div>
            </div>
            <button
                onClick={() => {
                }} // This could be used to dismiss the reminder if needed
                className="ml-4 text-gray-400 hover:text-gray-500"
            >
                <XMarkIcon className="h-5 w-5"/>
            </button>
        </div>
    </div>
);

// Update the main EUPPrompt component to include the reminder
export const EUPPrompt = forwardRef((props, ref) => {
    const {
        isNewDevice,
        needsEUPSetup,
        showPromptAfterSkip,
        setShowPromptAfterSkip,
        showSuccessPrompt,
        setShowSuccessPrompt,
    } = useEUP();

    if (isNewDevice || showSuccessPrompt) {
        return <EnterEUPPasswordPrompt ref={ref}/>;
    }

    if (needsEUPSetup || showPromptAfterSkip) {
        return (
            <>
                <SetEUPPasswordPrompt ref={ref}/>
                {showPromptAfterSkip && (
                    <SkipReminder
                        onSetup={() => setShowPromptAfterSkip(false)}
                    />
                )}
            </>
        );
    }

    return null;
});

EUPPrompt.displayName = 'EUPPrompt';

