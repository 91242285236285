'use client';

import React, { useState } from 'react';

const ErrorTrigger: React.FC = () => {
  const [shouldError, setShouldError] = useState(false);

  if (shouldError) {
    throw new Error("This is a test error triggered by the user");
  }

  return (
    <button
      onClick={() => setShouldError(true)}
      className="fixed bottom-4 right-4 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition duration-300"
    >
      Trigger Error
    </button>
  );
};

export default ErrorTrigger;