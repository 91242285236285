import React from 'react';
import { RecordingState } from "@/constants/events";
import { FiPlus } from 'react-icons/fi';
import { useNoteTaker } from '@/contexts/NoteTakerContext';
import { useAudioRecordingStore } from '@/contexts/AudioRecordingContext';
import { __, TranslationOptions } from '@/utils/translationUtils';

interface NoteTakerButtonProps {
  className?: string;
  size?: number;
  expandedTailwindClasses?: string;
  collapsedTailwindClasses?: string;
  expandedIcon?: React.ReactNode;
  collapsedIcon?: React.ReactNode;
  indicatorSize?: number;
  indicatorTailwindClasses?: string;
  onClick?: () => void;
}

const NoteTakerButton: React.FC<NoteTakerButtonProps> = ({
  className = '',
  size = 64,
  expandedTailwindClasses = 'bg-gray-300 dark:bg-gray-600',
  collapsedTailwindClasses = 'bg-indigo-600',
  expandedIcon,
  collapsedIcon,
  indicatorSize = 16,
  indicatorTailwindClasses = 'bg-red-500',
  onClick,
}) => {
  const { isExpanded, toggleExpanded } = useNoteTaker();
  const { recordingState } = useAudioRecordingStore();

  const translationOptions: TranslationOptions = { source: 'uiComponents' };

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      toggleExpanded();
    }
  };

  const defaultExpandedIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="walkthrough-notetakerbutton-expandedicon h-6 w-6 text-gray-600 dark:text-gray-300"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M19 9l-7 7-7-7"
      />
    </svg>
  );

  const defaultCollapsedIcon = <FiPlus className="walkthrough-notetakerbutton-collapsedicon h-6 w-6 text-white" />;

  const isRecording = recordingState === RecordingState.RECORDING;

  return (
    <div
      className={`walkthrough-notetakerbutton-container relative flex items-center justify-center cursor-pointer note-taker-button ${className}`}
      onClick={handleClick}
      style={{ width: `${size}px`, height: `${size}px` }}
      aria-label={isExpanded ? __('Collapse note taker', translationOptions) : __('Expand note taker', translationOptions)}
    >
      <div
        className={`walkthrough-notetakerbutton-inner rounded-full flex items-center justify-center transition-all duration-300 ease-in-out transform hover:scale-105 active:scale-95 ${
          isExpanded ? expandedTailwindClasses : collapsedTailwindClasses
        } hover:shadow-lg note-taker-button-inner`}
        style={{ width: `${size}px`, height: `${size}px` }}
      >
        {isExpanded
          ? (expandedIcon || defaultExpandedIcon)
          : (collapsedIcon || defaultCollapsedIcon)}
      </div>
      {isRecording && !isExpanded && (
        <div
          className={`walkthrough-notetakerbutton-indicator absolute top-0 right-0 rounded-full animate-slow-pulse ${indicatorTailwindClasses} note-taker-recording-indicator`}
          style={{
            width: `${indicatorSize}px`,
            height: `${indicatorSize}px`,
          }}
          aria-label={__('Recording indicator', translationOptions)}
        ></div>
      )}
    </div>
  );
};

export default NoteTakerButton;