'use client';

import Script from 'next/script';

interface NewRelicMonitoringProps {
  browserTimingHeader?: string;
}

export function NewRelicMonitoring({ browserTimingHeader }: NewRelicMonitoringProps) {
  if (!browserTimingHeader) return null;
  
  return (
    <>
      <Script
        id="newrelic-browser-agent"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{ __html: browserTimingHeader }}
      />
      <Script
        id="newrelic-browser-init"
        strategy="afterInteractive"
      >
        {`
          window.NREUM||(NREUM={});NREUM.init={privacy:{cookies_enabled:true}};
        `}
      </Script>
    </>
  );
}
