'use client';

import React from 'react';
import {ErrorBoundary, FallbackProps} from 'react-error-boundary';
import CoolErrorComponent from './CoolErrorComponent';
import {__} from '@/utils/translationUtils';

interface ClientErrorBoundaryProps {
    children: React.ReactNode;
}

const ErrorFallback: React.FC<FallbackProps> = ({error, resetErrorBoundary}) => (
    <CoolErrorComponent error={error} reset={resetErrorBoundary}/>
);

const ClientErrorBoundary: React.FC<ClientErrorBoundaryProps> = ({children}) => {
    const errorHandler = (error: Error, info: React.ErrorInfo) => {
        console.error(__("Logging error:", {source: 'core'}), error, info);

        try {
            fetch('/api/error-notification', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    error: {message: error.message, stack: error.stack},
                    errorInfo: info,
                    componentStack: info.componentStack,
                }),
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(__('Failed to send error notification', {source: 'core'}));
                    }
                })
                .catch(fetchError => {
                    console.error(__("Failed to send error notification:", {source: 'core'}), fetchError);
                });
        } catch (error) {
            console.error(__("Error in errorHandler:", {source: 'core'}), error);
        }
    };

    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onError={errorHandler}
            onReset={() => {
                // Perform a hard reload of the page
                window.location.reload();
            }}
        >
            {children}
        </ErrorBoundary>
    );
};

export default ClientErrorBoundary;
