'use client';

import React from 'react';
import { __, TranslationOptions } from '@/utils/translationUtils';
import { getEnv } from '@/lib/utils/env';

interface CoolErrorComponentProps {
  error: Error;
  reset: () => void;
}

const CoolErrorComponent: React.FC<CoolErrorComponentProps> = ({ error, reset }) => {
  const translationOptions: TranslationOptions = { source: 'uiComponents' };
  const contactEmail = getEnv('CONTACT_EMAIL') || 'hey@sondeas.com';

  const handleContactSupport = () => {
    const subject = __("Oops, I found a bug!", translationOptions);
    const body = __("Here's what happened: {{errorMessage}}", { ...translationOptions, placeholders: { errorMessage: error.message } });
    window.location.href = `mailto:${contactEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  };

  const isApiError = error.message.includes('Failed to fetch') || error.message.includes('500 (Internal Server Error)');

  return (
    <div className="cool-error-component flex flex-col items-center justify-center min-h-screen bg-gray-100 text-gray-800 p-4">
      <h1 className="text-4xl font-bold mb-4">{__("Whoops! 🙈 We hit a snag", translationOptions)}</h1>
      <p className="text-xl mb-6">
        {isApiError 
          ? __("It looks like we're having trouble connecting to our servers. This could be a temporary issue.", translationOptions)
          : __("Don't worry, it's not you - it's us. Let's get you back on track!", translationOptions)}
      </p>
      <pre className="bg-gray-200 p-4 rounded-lg mb-6 max-w-full overflow-x-auto">
        <code>{error.message}</code>
      </pre>
      <div className="flex flex-col space-y-4 w-full max-w-md">
        <button
          onClick={reset}
          className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition duration-300 w-full"
        >
          {__("1. 🚀 Let's try that again", translationOptions)}
        </button>
        <button
          onClick={() => window.location.reload()}
          className="bg-indigo-500 hover:bg-indigo-600 text-white font-bold py-2 px-4 rounded transition duration-300 w-full"
        >
          {__("2. 🔄 Give it a fresh start", translationOptions)}
        </button>
        <button
          onClick={handleContactSupport}
          className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded transition duration-300 w-full"
        >
          {__("3. 📬 Drop me a line", translationOptions)}
        </button>
      </div>
      <p className="mt-8 text-sm text-center">
        {__("Sorry about that! If you're still stuck after trying these steps, I'm here to help. Just hit that \"Drop me a line\" button and I'll get right on it.", translationOptions)}
      </p>
    </div>
  );
};

export default CoolErrorComponent;