'use client';

import { useEffect } from 'react';
import { usePathname, useRouter } from 'next/navigation';
import { useSession } from 'next-auth/react';

export function RedirectToNotes({ children }: { children: React.ReactNode }) {
  const pathname = usePathname();
  const router = useRouter();
  const { status } = useSession();

  useEffect(() => {
    if (status === 'authenticated' && pathname === '/') {
      router.replace('/s/notes');
    }
  }, [pathname, router, status]);

  return <>{children}</>;
}