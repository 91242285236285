'use client';

import React, { useEffect, useState, useCallback, useRef } from 'react';
import { FaMicrophone } from 'react-icons/fa';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { useRecordAnywhere } from './RecordAnywhereContext';
import { RecordingState } from '@/constants/events';
import { useSession } from 'next-auth/react';

export const CaretMic = () => {
  const { status } = useSession();
  const { startRecording, recordingState, isTranscribing } = useRecordAnywhere();
  const [caretPosition, setCaretPosition] = useState<{ top: number; left: number } | null>(null);
  const [visible, setVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const activeElementRef = useRef<HTMLElement | null>(null);
  const selectionStartRef = useRef<number | null>(null);
  const selectionEndRef = useRef<number | null>(null);

  const updateCaretPosition = useCallback(() => {
    const activeElement = document.activeElement as HTMLInputElement | HTMLTextAreaElement;
    if (activeElement && (activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA')) {
      // Ignore password inputs
      if (activeElement instanceof HTMLInputElement && activeElement.type === 'password') {
        setVisible(false);
        return;
      }
      
      activeElementRef.current = activeElement;
      const { selectionStart, selectionEnd } = activeElement;
      selectionStartRef.current = selectionStart;
      selectionEndRef.current = selectionEnd;
      if (selectionStart !== null && selectionEnd !== null && selectionStart === selectionEnd) {
        const caretCoords = getCaretCoordinates(activeElement, selectionStart);
        const rect = activeElement.getBoundingClientRect();
        
        setCaretPosition({
          top: rect.top + caretCoords.top + caretCoords.height,
          left: rect.left + caretCoords.left,
        });
        setVisible(true);
      } else {
        setVisible(false);
      }
    } else {
      setVisible(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('selectionchange', updateCaretPosition);
    window.addEventListener('scroll', updateCaretPosition);
    window.addEventListener('resize', updateCaretPosition);

    const intervalId = setInterval(updateCaretPosition, 100); // Update every 100ms

    return () => {
      document.removeEventListener('selectionchange', updateCaretPosition);
      window.removeEventListener('scroll', updateCaretPosition);
      window.removeEventListener('resize', updateCaretPosition);
      clearInterval(intervalId);
    };
  }, [updateCaretPosition]);

  // Only render if the user is authenticated
  if (status !== 'authenticated') {
    return null;
  }

  if (!visible || !caretPosition || (recordingState !== RecordingState.IDLE && !isTranscribing)) {
    return null;
  }

  const handleStartRecording = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const activeElement = activeElementRef.current;
    const selectionStart = selectionStartRef.current;
    
    if (activeElement && (activeElement instanceof HTMLInputElement || activeElement instanceof HTMLTextAreaElement) && selectionStart !== null) {
      startRecording(e.clientX, e.clientY, activeElement, selectionStart);
    }
  };

  return (
    <div
      className="fixed z-[9999] caret-mic"
      style={{
        top: caretPosition.top + 4,
        left: caretPosition.left + 4,
      }}
      onMouseDown={handleStartRecording}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className={`rounded-full ${isTranscribing ? 'bg-gray-400' : 'bg-blue-500 hover:bg-blue-600'} p-2 cursor-pointer transition-all duration-200 ease-in-out 
                   flex justify-center items-center shadow-md
                   w-12 h-12 md:w-8 md:h-8 caret-mic-button`}
        style={{
          opacity: isHovered ? 1 : 0.5
        }}
      >
        {isTranscribing ? (
          <AiOutlineLoading3Quarters className="w-6 h-6 md:w-4 md:h-4 text-white caret-mic-icon animate-spin" />
        ) : (
          <FaMicrophone className="w-6 h-6 md:w-4 md:h-4 text-white caret-mic-icon" />
        )}
      </div>
    </div>
  );
};

function getCaretCoordinates(element: HTMLInputElement | HTMLTextAreaElement, position: number) {
  const { offsetLeft: elementLeft, offsetTop: elementTop } = element;
  const div = document.createElement('div');
  const style = div.style;
  const computed = getComputedStyle(element);

  style.whiteSpace = 'pre-wrap';
  style.wordWrap = 'break-word';
  style.position = 'absolute';
  style.visibility = 'hidden';

  // Copy styles that affect text layout
  const properties = [
    'direction', 'boxSizing', 'width', 'height', 'overflowX', 'overflowY',
    'borderTopWidth', 'borderRightWidth', 'borderBottomWidth', 'borderLeftWidth',
    'paddingTop', 'paddingRight', 'paddingBottom', 'paddingLeft',
    'fontStyle', 'fontVariant', 'fontWeight', 'fontStretch', 'fontSize', 'fontSizeAdjust',
    'lineHeight', 'fontFamily', 'textAlign', 'textTransform', 'textIndent', 'textDecoration',
    'letterSpacing', 'wordSpacing'
  ];

  properties.forEach((prop) => {
    style[prop as any] = computed[prop as any];
  });

  div.textContent = element.value.substring(0, position);

  const span = document.createElement('span');
  span.textContent = element.value.substring(position) || '.';
  div.appendChild(span);

  document.body.appendChild(div);
  const { offsetLeft: spanLeft, offsetTop: spanTop } = span;
  document.body.removeChild(div);

  return {
    left: spanLeft,
    top: spanTop,
    height: parseInt(computed.lineHeight),
  };
}
