'use client';
import React, { useEffect, useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import { FaMicrophone } from 'react-icons/fa';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { useRecordAnywhere } from './RecordAnywhereContext';
import { RecordingControls } from './RecordingControls';
import { CaretMic } from './CaretMic';
import { useSession } from 'next-auth/react';

const MicButton = ({ onClick, inputRef }: { onClick: (event: React.MouseEvent) => void, inputRef: React.RefObject<HTMLInputElement | HTMLTextAreaElement> }) => {
  const { isTranscribing } = useRecordAnywhere();

  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
        inputRef.current?.focus();
      }}
      className="record-anywhere-mic-button absolute top-1/2 right-1 transform -translate-y-1/2 bg-transparent border-none cursor-pointer opacity-50 hover:opacity-100 transition-opacity"
      disabled={isTranscribing}
    >
      {isTranscribing ? (
        <AiOutlineLoading3Quarters size={16} className="animate-spin" />
      ) : (
        <FaMicrophone size={16} />
      )}
    </button>
  );
};

const isValidInput = (element: Element): element is HTMLInputElement | HTMLTextAreaElement => {
  const isVisible = (el: Element): boolean => {
    const style = window.getComputedStyle(el);
    return style.display !== 'none' &&
           style.visibility !== 'hidden' &&
           style.opacity !== '0' &&
           (el as HTMLElement).offsetParent !== null;
  };

  if (element instanceof HTMLInputElement) {
    const validTypes = ['text', 'search', 'url', 'tel', 'email'] as const;
    type ValidInputType = typeof validTypes[number];
    
    return validTypes.includes(element.type as ValidInputType) &&
           element.type !== '' &&
           !element.readOnly &&
           !element.disabled &&
           isVisible(element);
  }
  
  return element instanceof HTMLTextAreaElement &&
         !element.readOnly &&
         !element.disabled &&
         isVisible(element);
};

export const RecordAnywhere = () => {
  const { status } = useSession();
  const { startRecording } = useRecordAnywhere();
  const [inputs, setInputs] = useState<(HTMLInputElement | HTMLTextAreaElement)[]>([]);
  const [portalContainer, setPortalContainer] = useState<HTMLElement | null>(null);
  const focusedInputRef = useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);

  const handleStartRecording = (event: React.MouseEvent, input: HTMLInputElement | HTMLTextAreaElement) => {
    const caretPosition = input.selectionStart !== null ? input.selectionStart : input.value.length;
    startRecording(event.clientX, event.clientY, input, caretPosition);
  };

  useEffect(() => {
    const container = document.createElement('div');
    container.id = 'record-anywhere-portal';
    document.body.appendChild(container);
    setPortalContainer(container);

    const observeInputs = () => {
      const observer = new MutationObserver((mutations) => {
        let newInputs: (HTMLInputElement | HTMLTextAreaElement)[] = [];
        mutations.forEach((mutation) => {
          mutation.addedNodes.forEach((node) => {
            if (node instanceof Element) {
              if (isValidInput(node)) {
                newInputs.push(node);
              } else {
                const validInputs = Array.from(node.querySelectorAll('input, textarea'))
                  .filter(isValidInput) as (HTMLInputElement | HTMLTextAreaElement)[];
                newInputs = newInputs.concat(validInputs);
              }
            }
          });
        });
        if (newInputs.length > 0) {
          setInputs(prev => [...prev, ...newInputs]);
        }
      });

      observer.observe(document.body, { childList: true, subtree: true });

      return observer;
    };

    const initialInputs = Array.from(document.querySelectorAll('input, textarea'))
      .filter(isValidInput) as (HTMLInputElement | HTMLTextAreaElement)[];
    setInputs(initialInputs);

    const observer = observeInputs();

    const handleFocus = (e: FocusEvent) => {
      if (isValidInput(e.target as Element)) {
        focusedInputRef.current = e.target as HTMLInputElement | HTMLTextAreaElement;
      }
    };

    document.addEventListener('focus', handleFocus, true);

    return () => {
      observer.disconnect();
      if (container && container.parentNode) {
        container.parentNode.removeChild(container);
      }
      document.removeEventListener('focus', handleFocus, true);
    };
  }, []);

  // Only render the microphone buttons and recording controls if the user is authenticated
  if (status !== 'authenticated') {
    return null;
  }

  return (
    <>
      {portalContainer && createPortal(<RecordingControls focusedInputRef={focusedInputRef} />, portalContainer)}
      <CaretMic />
    </>
  );
};
