'use client';

import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useInstallPrompt } from '@/hooks/useInstallPrompt';
import { FiX, FiDownload, FiShare, FiPlus } from 'react-icons/fi';
import { __, TranslationOptions } from '@/utils/translationUtils';

const InstallPromptContent: React.FC = () => {
  const { showInstallPrompt, handleInstall, handleDismiss, isIOS, isDesktop } = useInstallPrompt();

  if (!showInstallPrompt) return null;

  const translationOptions: TranslationOptions = { source: 'uiComponents' };

  const DesktopPrompt = () => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      className="fixed bottom-4 right-4 bg-white dark:bg-gray-800 shadow-lg rounded-lg z-50 overflow-hidden max-w-sm"
    >
      <div className="p-4 flex flex-col">
        <div className="flex items-center justify-between mb-3">
          <div className="flex items-center">
            <img src="/images/logo-256x256.png" alt={__("Sondeas Logo", translationOptions)} className="w-10 h-10 rounded-full mr-3" />
            <div>
              <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100">{__("Install Sondeas", translationOptions)}</h3>
              <p className="text-sm text-gray-600 dark:text-gray-400">{__("Add our app to your desktop for quick access", translationOptions)}</p>
            </div>
          </div>
          <button
            onClick={handleDismiss}
            className="text-gray-400 hover:text-gray-600 dark:text-gray-500 dark:hover:text-gray-300 transition-colors duration-300"
          >
            <FiX size={20} />
          </button>
        </div>
        <button
          onClick={handleInstall}
          className="w-full bg-indigo-600 text-white px-4 py-2 rounded-lg text-sm font-medium hover:bg-indigo-700 transition-colors duration-300 shadow-md hover:shadow-lg flex items-center justify-center"
        >
          <FiPlus className="mr-2" size={16} />
          {__("Add to desktop", translationOptions)}
        </button>
      </div>
    </motion.div>
  );

  const MobilePrompt = () => (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      className="fixed top-0 left-0 right-0 bg-white dark:bg-gray-900 shadow-lg z-50 overflow-hidden"
    >
      <div className="max-w-screen-md mx-auto p-4 flex flex-col items-start justify-between">
        <div className="flex items-center w-full mb-3">
          <img src="/images/logo-256x256.png" alt={__("Sondeas Logo", translationOptions)} className="w-10 h-10 rounded-full mr-3" />
          <div className="flex-grow">
            <h3 className="text-base font-medium text-gray-900 dark:text-gray-100">{__("Add Sondeas to Home screen", translationOptions)}</h3>
            <p className="text-xs text-gray-600 dark:text-gray-400 mt-0.5">{__("For quick access", translationOptions)}</p>
          </div>
          <button
            onClick={handleDismiss}
            className="text-gray-400 hover:text-gray-600 dark:text-gray-500 dark:hover:text-gray-300 transition-colors duration-300"
          >
            <FiX size={20} />
          </button>
        </div>
        <div className="flex flex-col sm:flex-row items-center justify-end w-full space-y-2 sm:space-y-0 sm:space-x-3">
          {isIOS ? (
            <div className="flex items-center text-sm text-gray-600 dark:text-gray-400">
              <FiShare className="mr-2" size={20} />
              {__("Tap share, then \"Add to Home Screen\"", translationOptions)}
            </div>
          ) : (
            <button
              onClick={handleInstall}
              className="w-full sm:w-auto bg-indigo-600 text-white px-4 py-2 rounded-full text-sm font-medium hover:bg-indigo-700 transition-colors duration-300 shadow-md hover:shadow-lg flex items-center justify-center"
            >
              <FiDownload className="mr-2" />
              {__("Install", translationOptions)}
            </button>
          )}
        </div>
      </div>
    </motion.div>
  );

  return (
    <AnimatePresence>
      {isDesktop ? <DesktopPrompt /> : <MobilePrompt />}
    </AnimatePresence>
  );
};

const InstallPrompt: React.FC = () => (
  <InstallPromptContent />
);

export default InstallPrompt;