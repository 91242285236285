import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ArrowDownIcon, ArrowDownTrayIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { __ } from '@/utils/translationUtils';
import { Toggle } from '@/components/ui/Toggle';
import { AutoCopyToggle } from '@/components/AutoCopyToggle';
import { Select2 } from '@/components/ui/select2';
import { AudioVisualizer } from '@/components/AudioVisualizer';
import { RecordingControls } from '@/components/RecordingControls';
import { FileUploadButton } from '@/components/ui/FileUploadButton';
import { MicrophoneTest } from '@/components/MicrophoneTest';
import LoadingSpinner from '@/components/LoadingSpinner';
import { Button } from '@/components/ui/button';
import { Provider as TooltipProvider, Root as TooltipRoot, Trigger as TooltipTrigger, Portal as TooltipPortal, Content as TooltipContent, Arrow as TooltipArrow } from '@radix-ui/react-tooltip';
import { NoteEditorProvider } from '@/contexts/NoteEditor/NoteEditorContext';
import { NoteEditorLayout } from '@/contexts/NoteEditor/NoteEditorLayout';
import InsufficientCreditsModal from '@/components/InsufficientCreditsModal';
import { STTViewProps } from './types';
import { RecordingState } from '@/constants/events';

const languages = [
    { value: 'auto', label: __('Auto Detect') },
    { value: 'es', label: __('Spanish') },
    { value: 'en', label: __('English') },
    { value: 'fr', label: __('French') },
    { value: 'de', label: __('German') },
    { value: 'it', label: __('Italian') },
    { value: 'pt', label: __('Portuguese') },
    { value: 'nl', label: __('Dutch') },
    { value: 'pl', label: __('Polish') },
    { value: 'ru', label: __('Russian') },
    { value: 'ja', label: __('Japanese') },
    { value: 'ko', label: __('Korean') },
    { value: 'zh', label: __('Chinese') },
    { value: 'ar', label: __('Arabic') },
    { value: 'hi', label: __('Hindi') },
];

export const STTView: React.FC<STTViewProps> = ({
    detectSpeakers,
    numberOfSpeakers,
    language,
    showLanguageSelector,
    showMobileWarning,
    currentAudioItem,
    showInsufficientCreditsModal,
    showNoteEditor,
    recordingState,
    isProcessing,
    isTranscribing,
    hasTranscription,
    sttText,
    failedRecording,
    retryCountdown,
    isLoading,
    autocopy,
    analyserNode,
    handleSpeakerDetectionChange,
    handleNumberOfSpeakersChange,
    handleLanguageChange,
    handleResetLanguage,
    handleStartRecording,
    handleStopRecording,
    handleCancelRecording,
    handleFileSelect,
    handleRetry,
    handleDownload,
    handleAutoCopyChange,
    dismissWarning,
    handleToggleLanguageSelector
}) => {
    // Filter options based on speaker detection
    const filterLanguageOptions = (option: { value: string; label: string }) => {
        return !(detectSpeakers && option.value === 'auto');
    };

    const showTextArea = hasTranscription || sttText.trim().length > 0;

    return (
        <div className="walkthrough-stt-mode notetaker-stt-mode flex flex-col h-full flex-grow relative">
            <AnimatePresence>
                {showMobileWarning && (
                    <motion.div
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.3 }}
                        className="walkthrough-stt-warning absolute top-0 left-0 right-0 z-50 bg-gradient-to-r from-yellow-400 to-orange-500 text-white p-4 rounded-md shadow-lg"
                    >
                        <div className="flex items-center justify-between">
                            <div className="flex-1 mr-4">
                                <h3 className="text-lg font-bold mb-1">{__('Keep Your Screen On')}</h3>
                                <p className="text-sm">
                                    {__('To prevent recording interruptions, keep your finger on the screen or disable auto-lock. The recording may stop if the screen turns off.')}
                                </p>
                            </div>
                            <button
                                onClick={dismissWarning}
                                className="walkthrough-stt-dismiss-warning text-white hover:text-gray-200 transition-colors flex-shrink-0"
                                aria-label={__('Dismiss warning')}
                            >
                                <XMarkIcon className="h-6 w-6" />
                            </button>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>

            <div className="flex-shrink-0 p-4 space-y-4">
                <div className="flex flex-wrap gap-4 items-center justify-between">
                    <div className="flex items-center space-x-4">
                        <Toggle
                            checked={detectSpeakers}
                            onCheckedChange={handleSpeakerDetectionChange}
                            label={__("Detect Speakers", { source: 'uiComponents' })}
                            ariaLabel={__("Toggle speaker detection", { source: 'uiComponents' })}
                            tooltip={__('Best for conversations over 2 minutes where each speaker has uninterrupted speaking time. All speakers must use the same language.')}
                            disabled={isLoading}
                        />
                        <AutoCopyToggle autoCopy={autocopy} onAutoCopyChange={handleAutoCopyChange} disabled={isLoading} />
                    </div>
                    <div className="flex items-center space-x-4 min-w-[160px]">
                        {showLanguageSelector ? (
                            <div className="flex items-center space-x-2 w-full">
                                <Select2
                                    value={language}
                                    onValueChange={handleLanguageChange}
                                    options={languages}
                                    placeholder={__('Select Language')}
                                    searchPlaceholder={__('Search languages...')}
                                    className="w-full"
                                    autoFocusSearch
                                    filterOptions={filterLanguageOptions}
                                    required={detectSpeakers}
                                    needsAttention={detectSpeakers && language === 'auto'}
                                />
                                {detectSpeakers && (
                                    <div className="flex items-center space-x-2 flex-shrink-0">
                                        <label htmlFor="numSpeakers" className="text-sm whitespace-nowrap text-muted-foreground">
                                            {__("Speakers")}:
                                        </label>
                                        <input
                                            id="numSpeakers"
                                            type="number"
                                            min="2"
                                            max="10"
                                            value={numberOfSpeakers}
                                            onChange={(e) => handleNumberOfSpeakersChange(e.target.value)}
                                            className="w-16 h-9 px-2 rounded-md border border-input bg-background text-sm"
                                            title={__("Enter number of speakers (2-10)")}
                                        />
                                    </div>
                                )}
                                {!detectSpeakers && (
                                    <button
                                        onClick={handleResetLanguage}
                                        className="p-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 flex-shrink-0"
                                        title={__('Reset to auto-detection')}
                                    >
                                        <XMarkIcon className="h-4 w-4" />
                                    </button>
                                )}
                            </div>
                        ) : (
                            <button
                                onClick={handleToggleLanguageSelector}
                                className="w-full h-9 px-3 flex items-center text-sm text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 border border-transparent hover:border-gray-300 dark:hover:border-gray-600 rounded-md transition-colors"
                            >
                                {__('Language:')} {languages.find(l => l.value === language)?.label || __('Auto Detect')}
                            </button>
                        )}
                    </div>
                </div>
                {detectSpeakers && (
                    <div className="bg-yellow-50 dark:bg-yellow-900/30 border border-yellow-200 dark:border-yellow-700 rounded-md p-3 text-sm text-yellow-800 dark:text-yellow-200">
                        <div className="flex items-start">
                            <div className="flex-shrink-0">
                                <svg className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                                </svg>
                            </div>
                            <div className="ml-3">
                                <p>{__('Speaker detection can take significantly longer to process than regular transcription. Please be patient while we analyze the audio.')}</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className="walkthrough-stt-container flex-grow min-h-0 relative flex flex-col stt-mode-visualizer-and-processing-container">
                {isProcessing ? (
                    <div className="walkthrough-stt-processing absolute inset-0 flex items-center justify-center bg-gray-100 dark:bg-gray-800 bg-opacity-75 dark:bg-opacity-75 z-10">
                        <LoadingSpinner
                            size="3rem"
                            thickness={3}
                            speed={1.5}
                            className="text-indigo-600 dark:text-indigo-400"
                            messages={[
                                __("Enhancing voice quality..."),
                                __("Removing silent parts..."),
                                __("Analyzing speech patterns..."),
                                __("Optimizing audio clarity..."),
                                __("Transcribing audio...")
                            ]}
                            messageDelay={2000}
                        />
                    </div>
                ) : recordingState === RecordingState.RECORDING || recordingState === RecordingState.PAUSED ? (
                    <>
                        <AudioVisualizer
                            isRecording={recordingState === RecordingState.RECORDING}
                            audioContext={new AudioContext()}
                            analyserNode={analyserNode}
                        />
                    </>
                ) : (
                    <>
                        {showTextArea && currentAudioItem ? (
                            <div className="walkthrough-stt-textarea-container relative flex-grow flex flex-col">
                                <NoteEditorProvider note={currentAudioItem}>
                                    <NoteEditorLayout className="flex-grow shadow-none p-0" />
                                </NoteEditorProvider>
                            </div>
                        ) : (
                            <div className="walkthrough-stt-empty-state flex-grow flex-col flex items-center justify-around">
                                <div></div>
                                <p className="text-center text-lg text-gray-500">{__('Press the recording button and start dictating')}</p>
                                <motion.div
                                    animate={{ y: [0, 10, 0] }}
                                    transition={{ duration: 1.5, repeat: Infinity, ease: "easeInOut" }}
                                >
                                    <ArrowDownIcon className="w-20 h-20 mx-auto mb-2 text-gray-400" />
                                </motion.div>
                            </div>
                        )}
                    </>
                )}
            </div>

            {!isProcessing && (
                <div className="walkthrough-stt-controls flex-shrink-0">
                    <div className="flex items-center justify-center space-x-4">
                        {recordingState === RecordingState.IDLE && (
                            <MicrophoneTest onTestStart={() => {}} onTestEnd={() => {}} />
                        )}
                        <RecordingControls
                            onStartRecording={handleStartRecording}
                            onStopRecording={handleStopRecording}
                            onCancelRecording={handleCancelRecording}
                        />
                        {recordingState === RecordingState.IDLE && (
                            <FileUploadButton onFileSelect={handleFileSelect} />
                        )}
                    </div>
                </div>
            )}

            {failedRecording && (
                <div className="walkthrough-stt-failed-recording mt-4">
                    <h3 className="text-lg font-semibold mb-2">{__('Failed Recording:')}</h3>
                    <div className="flex items-center justify-between mb-2">
                        <span>{__('Last failed recording')}</span>
                        <div className="flex space-x-2">
                            <TooltipProvider>
                                <TooltipRoot>
                                    <TooltipTrigger asChild>
                                        <Button
                                            onClick={() => handleRetry(failedRecording, undefined)}
                                            disabled={retryCountdown !== null}
                                            variant="outline"
                                            className="walkthrough-stt-retry retry-button"
                                        >
                                            {retryCountdown !== null ? __('Retry ({{seconds}}s)', { placeholders: { seconds: retryCountdown } }) : __('Retry')}
                                        </Button>
                                    </TooltipTrigger>
                                    <TooltipPortal>
                                        <TooltipContent className="bg-gray-800 text-white px-2 py-1 rounded text-sm">
                                            {__('Retry transcription')}
                                            <TooltipArrow className="fill-gray-800" />
                                        </TooltipContent>
                                    </TooltipPortal>
                                </TooltipRoot>
                            </TooltipProvider>
                            <TooltipProvider>
                                <TooltipRoot>
                                    <TooltipTrigger asChild>
                                        <Button onClick={handleDownload} variant="outline"
                                            className="walkthrough-stt-download flex items-center download-button">
                                            <ArrowDownTrayIcon className="w-5 h-5" />
                                        </Button>
                                    </TooltipTrigger>
                                    <TooltipPortal>
                                        <TooltipContent className="bg-gray-800 text-white px-2 py-1 rounded text-sm">
                                            {__('Download failed recording')}
                                            <TooltipArrow className="fill-gray-800" />
                                        </TooltipContent>
                                    </TooltipPortal>
                                </TooltipRoot>
                            </TooltipProvider>
                        </div>
                    </div>
                </div>
            )}

            {showInsufficientCreditsModal && (
                <InsufficientCreditsModal />
            )}
        </div>
    );
};
