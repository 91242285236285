'use client';

import React, { createContext, useContext, useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useSession } from 'next-auth/react';
import { useEventListener } from '@/hooks/useEventListener';
import WalkthroughsStateManager from '@/lib/WalkthroughsStateManager';
import { walkthroughs, WalkthroughStep } from '@/data/walkthroughs';
import { getFirstVisibleElement, isElementVisible } from '@/utils/walkthroughUtils';

type WalkthroughId = keyof typeof walkthroughs;

interface WalkthroughContextType {
  currentWalkthrough: WalkthroughId | null;
  currentStep: number;
  startWalkthrough: (id: WalkthroughId) => Promise<void>;
  nextStep: () => void;
  previousStep: () => void;
  endWalkthrough: () => void;
  skipWalkthrough: () => void;
  getCurrentStepContent: () => WalkthroughStep | null;
  isMetaLoaded: boolean;
  getNextAvailableWalkthrough: () => Promise<WalkthroughId | null>;
  isElementVisible: (selector: string) => boolean;
  getFirstVisibleElement: (selector: string) => Element | null;
}

const WalkthroughContext = createContext<WalkthroughContextType | undefined>(undefined);

export const useWalkthrough = () => {
  const context = useContext(WalkthroughContext);
  if (!context) throw new Error('useWalkthrough must be used within a WalkthroughProvider');
  return context;
};

export const WalkthroughProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { data: session } = useSession();
  const [stateManager] = useState(() => new WalkthroughsStateManager());
  const [isMetaLoaded, setIsMetaLoaded] = useState(false);
  const reevaluateIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const newWalkthroughIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const [currentWalkthroughState, setCurrentWalkthroughState] = useState<{
    currentWalkthrough: WalkthroughId | null;
    currentStep: number;
  }>({ currentWalkthrough: null, currentStep: 0 });

  const fetchWalkthroughs = useCallback(async () => {
    try {
      const res = await fetch('/api/walkthroughs');
      const data = await res.json();
      stateManager.initializeMeta(data);
      setIsMetaLoaded(true);
    } catch (error) {
      console.error('Failed to fetch walkthroughs:', error);
      setIsMetaLoaded(false);
    }
  }, [stateManager]);

  useEffect(() => {
    if (session && !isMetaLoaded) {
      fetchWalkthroughs();
    } else if (!session) {
      setIsMetaLoaded(false);
    }
  }, [session, isMetaLoaded, fetchWalkthroughs]);

  const updateWalkthroughMeta = useCallback(async () => {
    if (!isMetaLoaded) return;
    try {
      await fetch('/api/walkthroughs', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(stateManager.meta),
      });
    } catch (err) {
      console.error('Failed to update walkthrough meta:', err);
    }
  }, [stateManager, isMetaLoaded]);

  const updateCurrentWalkthroughState = useCallback(() => {
    setCurrentWalkthroughState({
      currentWalkthrough: stateManager.currentWalkthrough,
      currentStep: stateManager.currentStep,
    });
  }, [stateManager]);

  const startWalkthrough = useCallback(async (id: WalkthroughId) => {
    if (!isMetaLoaded) return;
    stateManager.startWalkthrough(id);
    updateCurrentWalkthroughState();
    await updateWalkthroughMeta();
  }, [stateManager, updateWalkthroughMeta, isMetaLoaded, updateCurrentWalkthroughState]);

  const nextStep = useCallback(async () => {
    if (!isMetaLoaded) return;
    stateManager.nextStep();
    updateCurrentWalkthroughState();
    await updateWalkthroughMeta();
  }, [stateManager, updateWalkthroughMeta, isMetaLoaded, updateCurrentWalkthroughState]);

  const previousStep = useCallback(async () => {
    if (!isMetaLoaded) return;
    stateManager.previousStep();
    updateCurrentWalkthroughState();
    await updateWalkthroughMeta();
  }, [stateManager, updateWalkthroughMeta, isMetaLoaded, updateCurrentWalkthroughState]);

  const endWalkthrough = useCallback(async () => {
    if (!isMetaLoaded) return;
    stateManager.endWalkthrough();
    await updateWalkthroughMeta();
  }, [stateManager, updateWalkthroughMeta, isMetaLoaded]);

  const skipWalkthrough = useCallback(async () => {
    if (!isMetaLoaded) return;
    stateManager.skipWalkthrough();
    await updateWalkthroughMeta();
    updateCurrentWalkthroughState();
  }, [stateManager, updateWalkthroughMeta, isMetaLoaded]);

  const getCurrentStepContent = useCallback(() => {
    if (!isMetaLoaded) return null;
    return stateManager.getCurrentStepContent();
  }, [stateManager, isMetaLoaded]);

  const getNextAvailableWalkthrough = useCallback(async (): Promise<WalkthroughId | null> => {
    if (!isMetaLoaded) return null;
    return stateManager.getNextAvailableWalkthrough();
  }, [stateManager, isMetaLoaded]);

  const handleClick = useCallback((event: MouseEvent) => {
    if (!isMetaLoaded) return;
    const currentStep = stateManager.getCurrentStepContent();
    if (currentStep?.target) {
      const targetElement = getFirstVisibleElement(currentStep.target);
      if (targetElement && (targetElement === event.target || targetElement.contains(event.target as Node))) {
        nextStep();
      }
    }
  }, [stateManager, nextStep, isMetaLoaded]);

  useEventListener('click', handleClick, true);

  useEffect(() => {
    if (!isMetaLoaded) return;
    const currentStep = stateManager.getCurrentStepContent();
    if (currentStep?.checkInteraction) {
      const interval = setInterval(() => {
        if (currentStep.checkInteraction && currentStep.checkInteraction()) {
          nextStep();
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [stateManager, nextStep, isMetaLoaded]);

  // Smarter auto-trigger logic
  useEffect(() => {
    if (!isMetaLoaded) return;

    const reevaluateWalkthroughs = () => {
      stateManager.reevaluateWalkthroughs();
    };

    const checkAndStartNewWalkthrough = async () => {
      if (!stateManager.currentWalkthrough) {
        const nextWalkthroughId = await getNextAvailableWalkthrough();
        if (nextWalkthroughId) {
          await startWalkthrough(nextWalkthroughId);
        }
      }
    };

    // Reevaluate walkthroughs more frequently
    const startReevaluateInterval = () => {
      if (!reevaluateIntervalRef.current) {
        reevaluateIntervalRef.current = setInterval(reevaluateWalkthroughs, 2000);
      }
    };

    // Check for new walkthroughs less frequently
    const startNewWalkthroughInterval = () => {
      if (!newWalkthroughIntervalRef.current) {
        newWalkthroughIntervalRef.current = setInterval(checkAndStartNewWalkthrough, 10000);
      }
    };

    const stopIntervals = () => {
      if (reevaluateIntervalRef.current) {
        clearInterval(reevaluateIntervalRef.current);
        reevaluateIntervalRef.current = null;
      }
      if (newWalkthroughIntervalRef.current) {
        clearInterval(newWalkthroughIntervalRef.current);
        newWalkthroughIntervalRef.current = null;
      }
    };

    reevaluateWalkthroughs();
    checkAndStartNewWalkthrough();
    startReevaluateInterval();
    startNewWalkthroughInterval();

    return () => stopIntervals();
  }, [getNextAvailableWalkthrough, startWalkthrough, stateManager, isMetaLoaded]);

  const contextValue = useMemo(() => ({
    currentWalkthrough: currentWalkthroughState.currentWalkthrough,
    currentStep: currentWalkthroughState.currentStep,
    startWalkthrough,
    nextStep,
    previousStep,
    endWalkthrough,
    skipWalkthrough,
    getCurrentStepContent,
    isMetaLoaded,
    getNextAvailableWalkthrough,
    isElementVisible,
    getFirstVisibleElement
  }), [currentWalkthroughState, startWalkthrough, nextStep, previousStep, endWalkthrough, skipWalkthrough, getCurrentStepContent, isMetaLoaded, getNextAvailableWalkthrough]);

  return (
    <WalkthroughContext.Provider value={contextValue}>
      {children}
    </WalkthroughContext.Provider>
  );
};
