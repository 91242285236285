'use client';

import { toast, Toaster } from 'react-hot-toast';
import React, { useCallback } from 'react';

export default function CustomToaster() {
    const handleDismiss = useCallback((e: React.MouseEvent, id: string) => {
        e.stopPropagation();
        toast.dismiss(id);
    }, []);

    return (
        <Toaster
            position="bottom-center"
            toastOptions={{
                duration: 2000,
                style: { background: 'transparent', boxShadow: 'none' },
            }}
        >
            {(t) => (
                <div
                    className="custom-toaster bg-gray-800 bg-opacity-80 text-white rounded-lg p-2 mb-2 w-64 flex items-center justify-between"
                >
                    <span className="flex items-center">
                        {t.type === 'success' ? '✅ ' :
                         t.type === 'error' ? '❌ ' : 'ℹ️ '}
                        <p className="ml-2 text-sm">{typeof t.message === 'function' ? t.message(t) : t.message}</p>
                    </span>
                    <button
                        onClick={(e) => handleDismiss(e, t.id)}
                        className="text-white opacity-50 hover:opacity-100 transition-opacity"
                    >
                        &times;
                    </button>
                </div>
            )}
        </Toaster>
    );
}