import React from 'react';
import { Switch } from '@/components/ui/switch';
import { Provider, Root, Trigger, Portal, Content, Arrow } from '@radix-ui/react-tooltip';

interface ToggleProps {
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
  label: string;
  ariaLabel?: string;
  tooltip?: string;
  disabled?: boolean;
}

export const Toggle: React.FC<ToggleProps> = ({
  checked,
  onCheckedChange,
  label,
  ariaLabel,
  tooltip,
  disabled,
}) => {
  const toggleContent = (
    <label className="flex items-center cursor-pointer">
      <div className="mr-3 text-sm">{label}</div>
      <Switch
        checked={checked}
        onCheckedChange={onCheckedChange}
        aria-label={ariaLabel || label}
        disabled={disabled}
      />
    </label>
  );

  if (tooltip) {
    return (
      <Provider>
        <Root>
          <Trigger asChild>
            {toggleContent}
          </Trigger>
          <Portal>
            <Content className="bg-gray-800 text-white px-3 py-2 rounded text-sm max-w-xs">
              {tooltip}
              <Arrow className="fill-gray-800" />
            </Content>
          </Portal>
        </Root>
      </Provider>
    );
  }

  return toggleContent;
};