'use client'

import { usePathname, useSearchParams } from "next/navigation";
import { useEffect } from "react";
import { usePostHog } from 'posthog-js/react';

export default function PostHogPageView() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const posthog = usePostHog();

  useEffect(() => {
    if (!pathname || !posthog) return;

    try {
      const url = new URL(pathname, window.origin);
      if (searchParams?.toString()) {
        url.search = searchParams.toString();
      }

      posthog.capture('$pageview', {
        $current_url: url.toString(),
      });
    } catch (error) {
      console.error('Error capturing pageview:', error);
      // Optionally, you could send this error to an error tracking service
    }
  }, [pathname, searchParams, posthog]);

  return null;
}
