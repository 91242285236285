import React, { useState, useEffect } from 'react';
import { useTextSelection } from '@/contexts/TextSelectionContext';

type PopoverOption = {
  label: string;
  render: (props: { selectedText: string; isEditable: boolean; closePopover: () => void }) => React.ReactNode;
  isAvailable?: (text: string, isEditable: boolean) => boolean;
};

type TextSelectionPopoverProps = {
  options: PopoverOption[];
};

export const TextSelectionPopover: React.FC<TextSelectionPopoverProps> = ({ options }) => {
  const { selectedText, isEditable, position, setPosition, clearSelection } = useTextSelection();
  const [renderedOptions, setRenderedOptions] = useState<React.ReactNode[]>([]);

  useEffect(() => {
    const availableOptions = options.filter(
      (option) => !option.isAvailable || option.isAvailable(selectedText, isEditable)
    );

    const newRenderedOptions = availableOptions.map((option) => (
      <div key={option.label}>
        {option.render({ selectedText, isEditable, closePopover })}
      </div>
    ));

    setRenderedOptions(newRenderedOptions);
  }, [options, selectedText, isEditable]);

  if (!position || renderedOptions.length === 0) return null;

  const closePopover = () => setPosition(null);

  return (
    <div
      className="text-selection-popover fixed bg-white shadow-md rounded-md p-2 z-[9999]"
      style={{ 
        left: `${position.x}px`, 
        top: `${position.y}px`,
        width: 'max-content',
        maxWidth: '300px', // Prevent excessive width on large screens
      }}
    >
      <button
        className="absolute top-1 right-1 text-gray-500 hover:text-gray-700"
        onClick={clearSelection}
        aria-label="Close"
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
      <div className="mt-4">
        {renderedOptions}
      </div>
    </div>
  );
};
