'use client';

import { ThemeProvider } from "next-themes";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import { NextIntlClientProvider } from 'next-intl';
import PostHogContextProvider from '@/contexts/PostHogContextProvider';
import { EventProvider } from "@/contexts/EventContext";
import { EUPProvider } from "@/contexts/EUPContext";
import { ShortcutsProvider } from "@/contexts/ShortcutsContext";
import { CreditsProvider } from "@/contexts/CreditsContext";
import { PermissionsProvider } from "@/contexts/PermissionsContext";
import { SettingsProvider } from "@/contexts/SettingsContext";
import { NoteTakerProvider } from "@/contexts/NoteTakerContext";
import { UserMetaProvider } from "@/contexts/UserMetaContext";
import { WalkthroughProvider } from "@/contexts/WalkthroughContext";
import { AudioPlayerProvider } from "@/contexts/AudioPlayerContext";
import { TextSelectionProvider } from "@/contexts/TextSelectionContext";
import { RecordAnywhereProvider } from "@/components/record-anywhere/RecordAnywhereContext";
import { VersionCheckerProvider } from '@/contexts/VersionCheckerContext';

export function BaseProviders({ children, locale }: { children: React.ReactNode; locale: string }) {
  return (
    <ThemeProvider attribute="class" defaultTheme="system" enableSystem>
      <NextIntlClientProvider locale={locale}>
        <TooltipProvider>
          <PostHogContextProvider>
            <EventProvider>
              <VersionCheckerProvider>
                <TextSelectionProvider>
                  <CreditsProvider>
                    <NoteTakerProvider>
                      {children}
                    </NoteTakerProvider>
                  </CreditsProvider>
                </TextSelectionProvider>
              </VersionCheckerProvider>
            </EventProvider>
          </PostHogContextProvider>
        </TooltipProvider>
      </NextIntlClientProvider>
    </ThemeProvider>
  );
}

export function AuthProviders({ children }: { children: React.ReactNode }) {
    return (
        <EUPProvider>
            <ShortcutsProvider>
                    <PermissionsProvider>
                        <SettingsProvider>
                                <UserMetaProvider>
                                    <WalkthroughProvider>
                                        <AudioPlayerProvider>
                                            <RecordAnywhereProvider>
                                                {children}
                                            </RecordAnywhereProvider>
                                        </AudioPlayerProvider>
                                    </WalkthroughProvider>
                                </UserMetaProvider>
                        </SettingsProvider>
                    </PermissionsProvider>
            </ShortcutsProvider>
        </EUPProvider>
    );
}
