'use client';

import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { Rnd } from 'react-rnd';
import { useNoteTaker } from '@/contexts/NoteTakerContext';
import NoteTakerButton from './NoteTakerButton';
import NoteTakerContent from './NoteTakerContent';
import { isMobile } from '@/utils/deviceDetection';
import { motion } from 'framer-motion';
import { eventBus } from '@/utils/EventBus';
import { EVENTS } from '@/constants/events';
import { AudioRecordingProvider } from '@/contexts/AudioRecordingContext';
import { TranscriptionProvider } from '@/contexts/TranscriptionContext';
import { __, TranslationOptions } from '@/utils/translationUtils';
import dynamic from 'next/dynamic';

const MIN_EXPANDED_WIDTH = 400;
const SAFE_MARGIN = 20;
const BUBBLE_SIZE = 56;

const DynamicRnd = dynamic(() => import('react-rnd').then((mod) => mod.Rnd), { ssr: false });

const NoteTaker: React.FC = () => {
    const { isExpanded, setIsExpanded } = useNoteTaker();
    const [size, setSize] = useState({ width: MIN_EXPANDED_WIDTH, height: 700 });
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [minExpandedHeight, setMinExpandedHeight] = useState(700);
    const [isMounted, setIsMounted] = useState(false);

    const isMobileDevice = isMobile();
    const noteTakerContentRef = useRef<HTMLDivElement>(null);

    const translationOptions: TranslationOptions = { source: 'uiComponents' };

    useEffect(() => {
        setIsMounted(true);
    }, []);

    const updateSizeAndPosition = useCallback(() => {
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        const maxWidth = windowWidth - (SAFE_MARGIN * 2);
        const maxHeight = windowHeight - (SAFE_MARGIN * 2);
        
        // Update minimum height
        const newMinHeight = Math.min(windowHeight * 0.8, 700);
        setMinExpandedHeight(newMinHeight);
        
        // Clamp current size
        const newWidth = Math.min(size.width, maxWidth);
        const newHeight = Math.max(Math.min(size.height, maxHeight), newMinHeight);
        
        setSize({ width: newWidth, height: newHeight });
        
        // Ensure position stays within bounds
        const newX = Math.min(Math.max(SAFE_MARGIN, windowWidth - newWidth - SAFE_MARGIN), windowWidth - newWidth - SAFE_MARGIN);
        const newY = Math.min(Math.max(SAFE_MARGIN, windowHeight - newHeight - SAFE_MARGIN), windowHeight - newHeight - SAFE_MARGIN);
        
        setPosition({ x: newX, y: newY });
    }, [size.width, size.height]);

    useEffect(() => {
        updateSizeAndPosition();
        window.addEventListener('resize', updateSizeAndPosition);
        return () => window.removeEventListener('resize', updateSizeAndPosition);
    }, [updateSizeAndPosition]);

    useEffect(() => {
        const handleStartOrStopRecording = () => {
            setIsExpanded(true);
        };

        eventBus.on(EVENTS.START_OR_STOP_RECORDING, handleStartOrStopRecording);
        return () => eventBus.off(EVENTS.START_OR_STOP_RECORDING, handleStartOrStopRecording);
    }, [setIsExpanded]);

    useEffect(() => {
        const handlePopState = (event: PopStateEvent) => {
            if (isExpanded) {
                event.preventDefault();
                setIsExpanded(false);
            }
        };

        window.addEventListener('popstate', handlePopState);
        return () => window.removeEventListener('popstate', handlePopState);
    }, [isExpanded, setIsExpanded]);

    const memoizedContent = useMemo(() => (
        <div
            ref={noteTakerContentRef}
            className="walkthrough-notetaker-container w-full h-full flex flex-col bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden notetaker-content">
            <div
                className="walkthrough-notetaker-header w-full h-10 flex-shrink-0 drag-handle cursor-move flex items-center justify-between px-4 bg-indigo-600 text-white notetaker-header">
                <div className="walkthrough-notetaker-title text-sm font-semibold notetaker-title">{__('Note Taker', translationOptions)}</div>
                <button
                    onClick={() => setIsExpanded(false)}
                    className="walkthrough-notetaker-close text-white hover:text-gray-200 notetaker-close-button">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
            <div className="walkthrough-notetaker-body flex-grow overflow-auto flex flex-col notetaker-body">
                <NoteTakerContent />
            </div>
            <div className="walkthrough-notetaker-button absolute bottom-2 right-2 notetaker-button-container">
                <NoteTakerButton size={48} />
            </div>
        </div>
    ), [translationOptions, setIsExpanded]);

    useEffect(() => {
        if (isExpanded) {
            window.history.pushState({ expanded: true }, '');

            const handlePopState = (event: PopStateEvent) => {
                if (event.state && event.state.expanded) {
                    setIsExpanded(false);
                }
            };

            window.addEventListener('popstate', handlePopState);
            return () => window.removeEventListener('popstate', handlePopState);
        }
    }, [isExpanded, setIsExpanded]);

    const handleExpand = useCallback(() => {
        setIsExpanded(true);
    }, [setIsExpanded]);

    const handleCollapse = useCallback(() => {
        setIsExpanded(false);
        window.history.back();
    }, [setIsExpanded]);

    const content = memoizedContent;

    if (!isMounted) {
        return null;
    }

    return (
        <>
            <AudioRecordingProvider recordingId="stt-mode">
                <TranscriptionProvider>
                    {isMobileDevice ? (
                        <motion.div
                            className="walkthrough-notetaker-mobile fixed inset-0 z-50 notetaker-mobile-container"
                            style={{
                                top: 'env(safe-area-inset-top)',
                            }}
                            initial={{ y: '100%' }}
                            animate={{ y: isExpanded ? 0 : '100%' }}
                            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
                        >
                            <div className="walkthrough-notetaker-mobile-content w-full h-full bg-white dark:bg-gray-800 notetaker-mobile-content">
                                {content}
                            </div>
                        </motion.div>
                    ) : (
                        <DynamicRnd
                            size={size}
                            position={position}
                            onDragStop={(e, d) => {
                                const windowWidth = window.innerWidth;
                                const windowHeight = window.innerHeight;
                                const newX = Math.min(Math.max(SAFE_MARGIN, d.x), windowWidth - size.width - SAFE_MARGIN);
                                const newY = Math.min(Math.max(SAFE_MARGIN, d.y), windowHeight - size.height - SAFE_MARGIN);
                                setPosition({ x: newX, y: newY });
                            }}
                            onResizeStop={(e, direction, ref, delta, newPosition) => {
                                const windowWidth = window.innerWidth;
                                const windowHeight = window.innerHeight;
                                const maxWidth = windowWidth - (SAFE_MARGIN * 2);
                                const maxHeight = windowHeight - (SAFE_MARGIN * 2);

                                let newWidth = parseInt(ref.style.width, 10);
                                let newHeight = parseInt(ref.style.height, 10);

                                // Clamp size within window bounds
                                newWidth = Math.min(Math.max(MIN_EXPANDED_WIDTH, newWidth), maxWidth);
                                newHeight = Math.min(Math.max(minExpandedHeight, newHeight), maxHeight);

                                // Ensure position stays within bounds
                                const newX = Math.min(Math.max(SAFE_MARGIN, newPosition.x), windowWidth - newWidth - SAFE_MARGIN);
                                const newY = Math.min(Math.max(SAFE_MARGIN, newPosition.y), windowHeight - newHeight - SAFE_MARGIN);

                                setSize({ width: newWidth, height: newHeight });
                                setPosition({ x: newX, y: newY });
                            }}
                            minWidth={MIN_EXPANDED_WIDTH}
                            minHeight={minExpandedHeight}
                            bounds="window"
                            dragHandleClassName="drag-handle"
                            disableDragging={!isExpanded}
                            style={{
                                zIndex: 50,
                                visibility: isExpanded ? 'visible' : 'hidden',
                                pointerEvents: isExpanded ? 'auto' : 'none',
                            }}
                            className="walkthrough-notetaker-desktop h-full notetaker-desktop-container"
                        >
                            <motion.div
                                initial={false}
                                animate={{
                                    opacity: isExpanded ? 1 : 0,
                                    scale: isExpanded ? 1 : 0.8,
                                }}
                                transition={{ type: 'spring', stiffness: 300, damping: 25 }}
                                className="walkthrough-notetaker-desktop-content h-full notetaker-desktop-content"
                            >
                                <div className="h-full">
                                    {content}
                                </div>
                            </motion.div>
                        </DynamicRnd>
                    )}
                    {!isMobileDevice && (
                        <motion.div
                            className="walkthrough-notetaker-floating fixed bottom-5 right-5 z-50 notetaker-floating-button"
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{
                                opacity: isExpanded ? 0 : 1,
                                scale: isExpanded ? 0.8 : 1,
                                pointerEvents: isExpanded ? 'none' : 'auto'
                            }}
                            transition={{ type: 'spring', stiffness: 300, damping: 25 }}
                        >
                            <NoteTakerButton onClick={handleExpand} />
                        </motion.div>
                    )}
                </TranscriptionProvider>
            </AudioRecordingProvider>
        </>
    );
};

export default NoteTaker;
