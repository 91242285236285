'use client';

import React, { useState, useEffect, useCallback, useRef, Suspense } from 'react';
import Link from 'next/link';
import { useRouter, usePathname } from 'next/navigation';
import { useTheme } from 'next-themes';
import { FiSun, FiMoon, FiMenu, FiX } from 'react-icons/fi';
import { AnimatePresence, motion, useScroll, useMotionValueEvent } from 'framer-motion';
import { useNavigationLoadingState } from '@/hooks/useNavigationLoadingState';
import { __, getLocale, SupportedLocale, TranslationOptions } from '@/utils/translationUtils';
import LocaleSwitcher from "@/components/LocaleSwitcher";

const NavBarContent = () => {
  const { theme, setTheme } = useTheme();
  const [mounted, setMounted] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuAnimating, setIsMenuAnimating] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const { isNavigating, startNavigation } = useNavigationLoadingState();
  const router = useRouter();
  const pathname = usePathname();
  const lastScrollY = useRef(0);
  const { scrollY } = useScroll();
  const locale = getLocale();

  const translationOptions: TranslationOptions = { source: 'uiComponents' };

  useEffect(() => {
    setMounted(true);
  }, []);

  useMotionValueEvent(scrollY, "change", (latest) => {
    const currentScrollY = latest;
    if (Math.abs(currentScrollY - lastScrollY.current) > 10) {
      setIsVisible(currentScrollY < lastScrollY.current || currentScrollY < 10);
      lastScrollY.current = currentScrollY;
    }
  });

  const toggleMenu = useCallback(() => {
    if (!isMenuAnimating) {
      setIsMenuAnimating(true);
      setIsMenuOpen((prev) => !prev);
    }
  }, [isMenuAnimating]);

  const handleNavigation = useCallback((href: string) => {
    const localizedHref = href === '/' ? `/${locale}` : `/${locale}${href}`;
    console.log('localizedHref', localizedHref);
    console.log('pathname', pathname);
    if (localizedHref !== pathname) {
      startNavigation();
      router.push(localizedHref);
    }
    if (isMenuOpen) {
      toggleMenu();
    }
  }, [startNavigation, router, isMenuOpen, toggleMenu, pathname, locale]);

  const handleAnimationComplete = useCallback(() => {
    setIsMenuAnimating(false);
  }, []);

  return (
    <>
      <motion.nav 
        className="bg-white dark:bg-gray-800 shadow-md sticky top-0 w-full z-50"
        initial={{ y: 0 }}
        animate={{ y: isVisible ? 0 : -100 }}
        transition={{ duration: 0.3 }}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <Link href="/" onClick={() => handleNavigation('/')} className="flex-shrink-0">
                <div className="h-8 w-8 rounded-full overflow-hidden">
                  <img className="h-full w-full object-cover" src="/images/logo-128x128.png" alt={__("Sondeas Logo", translationOptions)} />
                </div>
              </Link>
              <div className="hidden md:block">
                <div className="ml-10 flex items-baseline space-x-4">
                  <Link href="/" onClick={() => handleNavigation('/')} className="text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium">
                    {__("Home", translationOptions)}
                  </Link>
                  <Link href="/pricing" onClick={() => handleNavigation('/pricing')} className="text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium">
                    {__("Pricing", translationOptions)}
                  </Link>
                  <Link href="/contact" onClick={() => handleNavigation('/contact')} className="text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium">
                    {__("Contact", translationOptions)}
                  </Link>
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <div className="hidden md:block">
                <button
                  onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
                  className="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white p-1 rounded-full mr-4"
                >
                  {mounted && (
                    theme === 'dark' ? <FiSun className="h-6 w-6" /> : <FiMoon className="h-6 w-6" />
                  )}
                </button>
              </div>
              <div className="hidden md:block">
                <div className="ml-4 flex items-center md:ml-6">
                  <Link href="/auth/signin" onClick={() => handleNavigation('/auth/signin')} className="text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                    {__("Log in", translationOptions)}
                  </Link>
                  <button onClick={() => handleNavigation('/pricing')} className="ml-2 bg-indigo-600 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150 ease-in-out">
                    {__("Get Started", translationOptions)}
                  </button>
                </div>
              </div>
              <div className="flex items-center md:hidden">
                <button onClick={() => handleNavigation('/pricing')} className="mr-2 bg-indigo-600 text-white px-3 py-1 rounded-md text-sm font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150 ease-in-out">
                  {__("Get Started", translationOptions)}
                </button>
                <button
                  onClick={toggleMenu}
                  disabled={isMenuAnimating}
                  className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                >
                  <span className="sr-only">{__("Open main menu", translationOptions)}</span>
                  {isMenuOpen ? (
                    <FiX className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <FiMenu className="block h-6 w-6" aria-hidden="true" />
                  )}
                </button>
              </div>
              <div className="hidden md:block ml-4">
                <LocaleSwitcher />
              </div>
            </div>
          </div>
        </div>
      </motion.nav>

      <AnimatePresence>
        {isNavigating && (
          <motion.div 
            initial={{ scaleX: 0 }}
            animate={{ scaleX: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
            className="sticky top-0 left-0 w-full h-1 bg-blue-500 origin-left z-[60]"
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isMenuOpen && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.5 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="fixed inset-0 bg-black z-[70]"
              onClick={toggleMenu}
            />
            <motion.div
              initial={{ opacity: 0, x: '100%' }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: '100%' }}
              transition={{ type: 'tween', ease: 'easeInOut', duration: 0.3 }}
              onAnimationComplete={handleAnimationComplete}
              className="fixed top-0 right-0 h-full w-[90vw] bg-white dark:bg-gray-800 shadow-lg z-[80] md:hidden flex flex-col"
            >
              <div className="flex-grow overflow-y-auto">
                <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                  <Link href="/" onClick={() => handleNavigation('/')} className="text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 block px-3 py-2 rounded-md text-base font-medium">
                    {__("Home", translationOptions)}
                  </Link>
                  <Link href="/pricing" onClick={() => handleNavigation('/pricing')} className="text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 block px-3 py-2 rounded-md text-base font-medium">
                    {__("Pricing", translationOptions)}
                  </Link>
                  <Link href="/contact" onClick={() => handleNavigation('/contact')} className="text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 block px-3 py-2 rounded-md text-base font-medium">
                    {__("Contact", translationOptions)}
                  </Link>
                </div>
                <div className="px-2 pt-2 pb-3">
                  <LocaleSwitcher/>
                </div>
              </div>
              <div className="border-t border-gray-700 p-4">
                <button
                  onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
                  className="w-full text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 px-3 py-2 rounded-md text-base font-medium flex items-center justify-center mb-2"
                >
                  {mounted && (
                    theme === 'dark' ? (
                      <>
                        <FiSun className="h-5 w-5 mr-2" />
                        {__("Switch to Light Mode", translationOptions)}
                      </>
                    ) : (
                      <>
                        <FiMoon className="h-5 w-5 mr-2" />
                        {__("Switch to Dark Mode", translationOptions)}
                      </>
                    )
                  )}
                </button>
                <Link href="/auth/signin" onClick={() => handleNavigation('/auth/signin')} className="w-full text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 px-3 py-2 rounded-md text-base font-medium text-center block mb-2">
                  {__("Log in", translationOptions)}
                </Link>
                <button onClick={() => handleNavigation('/pricing')} className="w-full bg-indigo-600 text-white px-4 py-2 rounded-md text-base font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150 ease-in-out">
                  {__("Get Started", translationOptions)}
                </button>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

const NavBar = () => {
  return (
    <Suspense fallback={<div className="h-16 bg-white dark:bg-gray-800" />}>
      <NavBarContent />
    </Suspense>
  );
};

export default NavBar;