import React, { useCallback, useEffect, useState } from 'react';
import { useSettings } from "@/contexts/SettingsContext";
import { useAudioRecordingContext } from '@/contexts/AudioRecordingContext';
import { useTranscription } from '@/contexts/TranscriptionContext';
import { useCredits } from '@/contexts/CreditsContext';
import { EVENTS, RecordingState } from "@/constants/events";
import { showToast } from "@/utils/toast";
import { isMobile } from '@/utils/deviceDetection';
import { __ } from '@/utils/translationUtils';
import { STTModeProps, STTState, STTViewProps } from './types';
import { STTView } from './STTView';
import { eventBus } from '@/utils/EventBus';
import { Note } from '@/lib/notes/Note';

export const STTController: React.FC<STTModeProps> = ({ onSTTComplete, onRecordingStateChange }) => {
    const { autocopy, dismissedMobileWarning, updateSettings, transcriptionLanguage, isLoading } = useSettings();
    const recordingId = "stt-mode";
    const audioRecording = useAudioRecordingContext(recordingId);
    const {
        sttText, isProcessing, failedRecording, hasTranscription, isTranscribing, retryCountdown,
        handleSTT, setDetectSpeakers, detectSpeakers, numberOfSpeakers, setNumberOfSpeakers, language, setLanguage
    } = useTranscription();
    const { insufficientCredits, isLoading: isCreditsLoading } = useCredits();

    // State management
    const [state, setState] = useState<STTState>({
        showLanguageSelector: false,
        showMobileWarning: false,
        shouldTranscribe: false,
        audioUrl: null,
        currentAudioItem: null,
        showInsufficientCreditsModal: false,
        showNoteEditor: false
    });

    // Helper function to focus and expand Select2
    const focusLanguageSelect = useCallback(() => {
        setTimeout(() => {
            const selectTrigger = document.querySelector('[aria-haspopup="listbox"]') as HTMLButtonElement;
            if (selectTrigger) {
                selectTrigger.click();
            }
        }, 0);
    }, []);

    // Action handlers
    const handleSpeakerDetectionChange = useCallback((newValue: boolean) => {
        setDetectSpeakers(newValue);
        if (newValue) {
            const browserLang = navigator.language.split('-')[0];
            setLanguage(browserLang);
            setState(prev => ({ ...prev, showLanguageSelector: true }));
        } else {
            setLanguage('auto');
            setState(prev => ({ ...prev, showLanguageSelector: false }));
        }
    }, [setDetectSpeakers, setLanguage]);

    const handleNumberOfSpeakersChange = useCallback((value: string) => {
        const num = parseInt(value, 10);
        if (!isNaN(num) && num >= 2 && num <= 10) {
            setNumberOfSpeakers(num);
        }
    }, [setNumberOfSpeakers]);

    const handleLanguageChange = useCallback(async (newLanguage: string) => {
        setLanguage(newLanguage);
        if (newLanguage !== 'auto') {
            await updateSettings({ transcriptionLanguage: newLanguage });
        }
    }, [updateSettings, setLanguage]);

    const handleResetLanguage = useCallback(async () => {
        setLanguage('auto');
        setState(prev => ({
            ...prev,
            showLanguageSelector: false
        }));
        await updateSettings({ transcriptionLanguage: 'auto' });
    }, [updateSettings, setLanguage]);

    const handleBeforeNewRecording = useCallback(async () => {
        if (state.currentAudioItem && state.showNoteEditor) {
            setState(prev => ({
                ...prev,
                currentAudioItem: null,
                showNoteEditor: false
            }));
        }
    }, [state.currentAudioItem, state.showNoteEditor]);

    const handleStartRecording = useCallback(async () => {
        if (detectSpeakers && language === 'auto') {
            showToast(__('Please select a language when speaker detection is enabled'), 'error');
            focusLanguageSelect();
            return;
        }

        if (insufficientCredits) {
            setState(prev => ({ ...prev, showInsufficientCreditsModal: true }));
            return;
        }

        await handleBeforeNewRecording();
        
        try {
            await audioRecording.startRecording();
            onRecordingStateChange?.(true);
        } catch (error) {
            console.error('Failed to start recording:', error);
            showToast(__('Failed to start recording. Please try again.', {source: 'audio'}), 'error');
        }
    }, [detectSpeakers, language, insufficientCredits, audioRecording, onRecordingStateChange, handleBeforeNewRecording, focusLanguageSelect]);

    const handleStopRecording = useCallback(async () => {
        try {
            await audioRecording.stopRecording();
            setState(prev => ({ ...prev, shouldTranscribe: true }));
            onRecordingStateChange?.(false);
        } catch (error) {
            console.error('Failed to stop recording:', error);
            showToast(__('Failed to stop recording. Please try again.', {source: 'audio'}), 'error');
        }
    }, [audioRecording, onRecordingStateChange]);

    const handleCancelRecording = useCallback(() => {
        audioRecording.cancelRecording();
        setState(prev => ({
            ...prev,
            currentAudioItem: null,
            shouldTranscribe: false
        }));
        onRecordingStateChange?.(false);
    }, [audioRecording, onRecordingStateChange]);

    const handleFileSelect = useCallback(async (file: File) => {
        if (detectSpeakers && language === 'auto') {
            showToast(__('Please select a language when speaker detection is enabled'), 'error');
            focusLanguageSelect();
            return;
        }

        try {
            const note = await handleSTT(file);
            if (note) {
                onSTTComplete(note);
                setState(prev => ({...prev, shouldTranscribe: false}));
            }
        } catch (error) {
            console.error('File transcription failed:', error);
            setState(prev => ({
                ...prev,
                shouldTranscribe: false,
                showNoteEditor: false
            }));
        }
    }, [handleSTT, onSTTComplete, detectSpeakers, language, focusLanguageSelect]);

    const handleDownload = useCallback(() => {
        if (!failedRecording) return;

        const url = URL.createObjectURL(failedRecording);
        const a = document.createElement('a');
        a.href = url;
        a.download = `failed_recording_${Date.now()}.webm`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }, [failedRecording]);

    const handleAutoCopyChange = async () => {
        const newValue = !autocopy;
        try {
            await updateSettings({autocopy: newValue});
            showToast(__('Auto-copy {{status}}', {
                placeholders: {status: newValue ? __('enabled') : __('disabled')},
                source: 'webapp'
            }), 'success');
        } catch (error) {
            console.error('Failed to update autocopy setting:', error);
            showToast(__('Failed to update setting. Please try again.'), 'error');
        }
    };

    const dismissWarning = async () => {
        setState(prev => ({ ...prev, showMobileWarning: false }));
        try {
            await updateSettings({dismissedMobileWarning: true});
        } catch (error) {
            console.error('Failed to update dismissedMobileWarning setting:', error);
            showToast(__('Failed to update setting. Please try again.'), 'error');
        }
    };

    // Add new handler for toggling language selector
    const handleToggleLanguageSelector = useCallback(() => {
        setState(prev => ({
            ...prev,
            showLanguageSelector: !prev.showLanguageSelector
        }));
    }, []);

    // Effects
    useEffect(() => {
        if (!isCreditsLoading && insufficientCredits) {
            setState(prev => ({ ...prev, showInsufficientCreditsModal: true }));
        }
    }, [isCreditsLoading, insufficientCredits]);

    useEffect(() => {
        if (isMobile() && audioRecording.recordingState === RecordingState.RECORDING && !dismissedMobileWarning) {
            setState(prev => ({ ...prev, showMobileWarning: true }));
        } else {
            setState(prev => ({ ...prev, showMobileWarning: false }));
        }
    }, [audioRecording.recordingState, dismissedMobileWarning]);

    useEffect(() => {
        if (state.shouldTranscribe && audioRecording.audioBlob && !isTranscribing) {
            console.log('Starting transcription');
            handleSTT(
                audioRecording.audioBlob
            )
                .then((data) => {
                    if (data) {
                        setState(prev => ({
                            ...prev,
                            currentAudioItem: data,
                            showNoteEditor: true
                        }));
                        onSTTComplete(data);
                    }
                })
                .catch(console.error);
            setState(prev => ({ ...prev, shouldTranscribe: false }));
        }
    }, [state.shouldTranscribe, isTranscribing, handleSTT, audioRecording.audioBlob, onSTTComplete]);

    useEffect(() => {
        return () => {
            if (state.audioUrl) {
                URL.revokeObjectURL(state.audioUrl);
            }
        };
    }, [state.audioUrl]);

    useEffect(() => {
        const handleTranscriptionComplete = (note: Note) => {
            setState(prev => ({
                ...prev,
                currentAudioItem: note,
                showNoteEditor: true
            }));
        };

        eventBus.on(EVENTS.TRANSCRIPTION_COMPLETED, handleTranscriptionComplete);
        return () => eventBus.off(EVENTS.TRANSCRIPTION_COMPLETED, handleTranscriptionComplete);
    }, []);

    // Compute view props
    const viewProps: STTViewProps = {
        ...state,
        recordingState: audioRecording.recordingState,
        isProcessing,
        isTranscribing,
        hasTranscription,
        sttText,
        failedRecording,
        retryCountdown,
        isLoading,
        autocopy,
        analyserNode: audioRecording.analyserNode,
        detectSpeakers,
        numberOfSpeakers: numberOfSpeakers.toString(),
        language,
        handleSpeakerDetectionChange: handleSpeakerDetectionChange as (newValue: boolean) => void,
        handleNumberOfSpeakersChange,
        handleLanguageChange,
        handleResetLanguage,
        handleStartRecording,
        handleStopRecording,
        handleCancelRecording,
        handleFileSelect,
        handleRetry: handleSTT as (audioFile: Blob | File | null, tmpfilepath?: string) => Promise<Note | null>,
        handleDownload,
        handleAutoCopyChange,
        dismissWarning,
        handleToggleLanguageSelector
    };

    return <STTView {...viewProps} />;
}; 
