'use client';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useRecordAnywhere } from './RecordAnywhereContext';
import { RecordingState } from '@/constants/events';
import { FaPlay, FaPause, FaStop, FaTimes, FaRedo, FaMicrophone } from 'react-icons/fa';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

interface RecordingControlsProps {
  focusedInputRef: React.RefObject<HTMLInputElement | HTMLTextAreaElement | null>;
}

export const RecordingControls: React.FC<RecordingControlsProps> = ({ focusedInputRef }) => {
  const {
    recordingState,
    cursorPosition,
    duration,
    isTranscribing,
    transcriptionError,
    creditError,
    stopRecording,
    pauseRecording,
    resumeRecording,
    cancelRecording,
    retryTranscription,
    analyserNode,
    isLoading,
  } = useRecordAnywhere();
  const controlsRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [cancelClicked, setCancelClicked] = useState(false);
  const [cancelCountdown, setCancelCountdown] = useState<number | null>(null);
  const [cancelTimer, setCancelTimer] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (recordingState !== RecordingState.IDLE && cursorPosition && controlsRef.current) {
      const controlsRect = controlsRef.current.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      let top = cursorPosition.y + 20;
      let left = cursorPosition.x;

      if (left + controlsRect.width > viewportWidth) {
        left = viewportWidth - controlsRect.width;
      }
      if (left < 0) {
        left = 0;
      }

      if (top + controlsRect.height > viewportHeight) {
        top = cursorPosition.y - controlsRect.height - 10;
      }
      if (top < 0) {
        top = 0;
      }

      setPosition({ top, left });
    }
  }, [recordingState, cursorPosition]);

  useEffect(() => {
    let animationFrameId: number;
    const canvas = canvasRef.current;
    const canvasCtx = canvas?.getContext('2d');

    if (canvas && canvasCtx && analyserNode && recordingState === RecordingState.RECORDING) {
      const bufferLength = analyserNode.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      const draw = () => {
        animationFrameId = requestAnimationFrame(draw);

        analyserNode.getByteTimeDomainData(dataArray);

        canvasCtx.fillStyle = 'rgb(200, 200, 200)';
        canvasCtx.fillRect(0, 0, canvas.width, canvas.height);

        canvasCtx.lineWidth = 2;
        canvasCtx.strokeStyle = 'rgb(0, 0, 0)';

        canvasCtx.beginPath();

        const sliceWidth = (canvas.width * 1.0) / bufferLength;
        let x = 0;

        for (let i = 0; i < bufferLength; i++) {
          const v = dataArray[i] / 128.0;
          const y = (v * canvas.height) / 2;

          if (i === 0) {
            canvasCtx.moveTo(x, y);
          } else {
            canvasCtx.lineTo(x, y);
          }

          x += sliceWidth;
        }

        canvasCtx.lineTo(canvas.width, canvas.height / 2);
        canvasCtx.stroke();
      };

      draw();
    }

    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [analyserNode, recordingState]);

  const handleDragStart = (e: React.MouseEvent | React.TouchEvent) => {
    if (controlsRef.current) {
      const rect = controlsRef.current.getBoundingClientRect();
      const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
      const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY;
      setDragOffset({
        x: clientX - rect.left,
        y: clientY - rect.top,
      });
      setIsDragging(true);
    }
  };

  const handleDragMove = (e: MouseEvent | TouchEvent) => {
    if (isDragging) {
      const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
      const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY;
      const newLeft = clientX - dragOffset.x;
      const newTop = clientY - dragOffset.y;
      setPosition({ top: newTop, left: newLeft });
    }
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleDragMove);
      window.addEventListener('touchmove', handleDragMove);
      window.addEventListener('mouseup', handleDragEnd);
      window.addEventListener('touchend', handleDragEnd);
    } else {
      window.removeEventListener('mousemove', handleDragMove);
      window.removeEventListener('touchmove', handleDragMove);
      window.removeEventListener('mouseup', handleDragEnd);
      window.removeEventListener('touchend', handleDragEnd);
    }

    return () => {
      window.removeEventListener('mousemove', handleDragMove);
      window.removeEventListener('touchmove', handleDragMove);
      window.removeEventListener('mouseup', handleDragEnd);
      window.removeEventListener('touchend', handleDragEnd);
    };
  }, [isDragging]);

  const startCancelCountdown = useCallback(() => {
    if (cancelClicked) {
      handleButtonClick(cancelRecording);
      return;
    }
    setCancelClicked(true);
    setCancelCountdown(3);
    const timer = setInterval(() => {
      setCancelCountdown(prev => {
        if (prev === null || prev <= 1) {
          clearInterval(timer);
          return null;
        }
        return prev - 1;
      });
    }, 1000);
    setCancelTimer(timer);
  }, [cancelClicked, cancelRecording]);

  const stopCancelCountdown = useCallback(() => {
    if (cancelTimer) {
      clearInterval(cancelTimer);
      setCancelTimer(null);
    }
    setCancelCountdown(null);
    setCancelClicked(false);
  }, [cancelTimer]);

  if (recordingState === RecordingState.IDLE || !cursorPosition) {
    return null;
  }

  const formatDuration = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const controlsStyle: React.CSSProperties = {
    position: 'fixed',
    top: `${position.top}px`,
    left: `${position.left}px`,
    zIndex: 9999,
    cursor: isDragging ? 'grabbing' : 'grab',
    userSelect: 'none',
    padding: '4px',
    borderRadius: '8px',
    overflow: 'hidden',
    touchAction: 'none',
  };

  const handleButtonClick = (action: () => void) => {
    action();
    focusedInputRef.current?.focus();
  };

  const handleStopRecording = () => {
    stopRecording();
    focusedInputRef.current?.focus();
  };

  const buttonClass = "rounded-full flex items-center justify-center transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-opacity-50";
  const smallButtonClass = `${buttonClass} w-8 h-8 text-xs md:w-10 md:h-10 md:text-sm`;
  const largeButtonClass = `${buttonClass} w-10 h-10 text-base md:w-14 md:h-14 md:text-lg`;

  return (
    <div 
      ref={controlsRef} 
      style={controlsStyle} 
      className="bg-white shadow transform hover:scale-105"
      onMouseDown={handleDragStart}
      onTouchStart={handleDragStart}
    >
      <div className="p-2">
        <div className="flex flex-col space-y-2">
          <div className="text-center font-bold">{formatDuration(duration)}</div>
          <canvas ref={canvasRef} width="200" height="60" className="border rounded"></canvas>
          {transcriptionError && (
            <div className="text-red-500 text-sm text-center animate-pulse"><FaTimes /></div>
          )}
          {creditError && (
            <div className="text-red-500 text-sm text-center animate-pulse"><FaTimes /></div>
          )}
          <div className="flex space-x-2 justify-center items-center">
            {isLoading ? (
              <div className="flex items-center justify-center">
                <AiOutlineLoading3Quarters className="animate-spin h-6 w-6 text-blue-500" />
              </div>
            ) : isTranscribing ? (
              <div className="flex items-center justify-center">
                <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-blue-500"></div>
              </div>
            ) : transcriptionError ? (
              <>
                <button
                  onClick={() => handleButtonClick(retryTranscription)}
                  className={`${smallButtonClass} bg-blue-500 text-white hover:bg-blue-600 focus:ring-blue-500`}
                  aria-label="Retry"
                >
                  <FaRedo />
                </button>
                <button
                  onClick={startCancelCountdown}
                  onMouseLeave={stopCancelCountdown}
                  className={`${largeButtonClass} bg-red-500 text-white hover:bg-red-600 focus:ring-red-500 relative`}
                  aria-label="Cancel"
                >
                  <FaTimes />
                  {cancelCountdown && (
                    <span className="absolute -top-2 -right-2 bg-red-600 text-white rounded-full w-6 h-6 flex items-center justify-center text-xs">
                      {cancelCountdown}
                    </span>
                  )}
                </button>
              </>
            ) : (
              <>
                {recordingState === RecordingState.PAUSED ? (
                  <button
                    onClick={() => handleButtonClick(resumeRecording)}
                    className={`${largeButtonClass} bg-green-500 text-white hover:bg-green-600 focus:ring-green-500`}
                    aria-label="Resume"
                  >
                    <FaPlay />
                  </button>
                ) : (
                  <button
                    onClick={() => handleButtonClick(pauseRecording)}
                    className={`${largeButtonClass} bg-yellow-500 text-white hover:bg-yellow-600 focus:ring-yellow-500`}
                    aria-label="Pause"
                  >
                    <FaPause />
                  </button>
                )}
                <button
                  onClick={handleStopRecording}
                  className={`${largeButtonClass} bg-green-400 text-white hover:bg-green-500 focus:ring-green-400`}
                  aria-label="Stop"
                >
                  <FaStop />
                </button>
                <button
                  onClick={startCancelCountdown}
                  onMouseLeave={stopCancelCountdown}
                  className={`${largeButtonClass} bg-red-500 text-white hover:bg-red-600 focus:ring-red-500 relative`}
                  aria-label="Cancel"
                >
                  <FaTimes />
                  {cancelCountdown && (
                    <span className="absolute -top-2 -right-2 bg-red-600 text-white rounded-full w-6 h-6 flex items-center justify-center text-xs">
                      {cancelCountdown}
                    </span>
                  )}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
