'use client';

import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useSession } from 'next-auth/react';
import { UserPermissions } from '@/lib/services/permissionsService';
import debounce from 'lodash/debounce';

interface PermissionsContextProps {
    permissions: UserPermissions | null;
    isLoading: boolean;
    error: string | null;
}

export const PermissionsContext = createContext<PermissionsContextProps>({
    permissions: null,
    isLoading: true,
    error: null,
});

export function PermissionsProvider({children}: { children: ReactNode }) {
    const [permissions, setPermissions] = useState<UserPermissions | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const { data: session, status } = useSession();

    const fetchPermissions = useCallback(async () => {
        if (status === 'authenticated') {
            try {
                const response = await fetch('/api/user/permissions');
                if (!response.ok) {
                    throw new Error('Failed to fetch permissions');
                }
                const data = await response.json();
                setPermissions(data);
                setError(null);
            } catch (err) {
                setError('Error fetching permissions');
            } finally {
                setIsLoading(false);
            }
        } else {
            setPermissions(null);
            setIsLoading(false);
        }
    }, [status]);

    const debouncedFetchPermissions = useCallback(
        debounce(fetchPermissions, 1000),
        [fetchPermissions]
    );

    useEffect(() => {
        debouncedFetchPermissions();
        return debouncedFetchPermissions.cancel;
    }, [debouncedFetchPermissions, session]);

    return (
        <PermissionsContext.Provider value={{permissions, isLoading, error}}>
            {children}
        </PermissionsContext.Provider>
    );
}

export const usePermissions = () => {
    const context = useContext(PermissionsContext);
    if (context === undefined) {
        throw new Error('usePermissions must be used within a PermissionsProvider');
    }
    return context;
};