export function getMaxZIndex(element: HTMLElement | null): number {
  let maxZ = 0;

  while (element) {
    const computedStyle = window.getComputedStyle(element);
    const currentZIndex = parseInt(computedStyle.zIndex) || 0;
    maxZ = Math.max(maxZ, currentZIndex);
    element = element.parentElement;
  }

  const result = maxZ + 1;
  return result;
}