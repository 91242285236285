import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FiX, FiUpload } from 'react-icons/fi';
import { toast } from 'react-hot-toast';
import { useSession } from "next-auth/react";
import { __, TranslationOptions } from '@/utils/translationUtils';

interface FeedbackFormProps {
    isOpen: boolean;
    onClose: () => void;
    predefinedMessage?: string;
    predefinedUrgency?: string;
    onSubmit?: (feedbackData: FeedbackData) => Promise<void>;
}

interface FeedbackData {
    message: string;
    urgency: string;
    email?: string;
    screenshots: File[];
}

const FeedbackForm: React.FC<FeedbackFormProps> = ({ 
    isOpen, 
    onClose, 
    predefinedMessage = '', 
    predefinedUrgency = 'low',
    onSubmit 
}) => {
    const [feedbackData, setFeedbackData] = useState<FeedbackData>({
        message: predefinedMessage,
        urgency: predefinedUrgency,
        email: '',
        screenshots: [],
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { data: session } = useSession();

    const translationOptions: TranslationOptions = { source: 'userContent' };

    useEffect(() => {
        setFeedbackData(prevData => ({
            ...prevData,
            message: predefinedMessage,
            urgency: predefinedUrgency
        }));
    }, [predefinedMessage, predefinedUrgency]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            if (onSubmit) {
                await onSubmit(feedbackData);
            } else {
                const response = await fetch('/api/feedback', {
                    method: 'POST',
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        message: feedbackData.message,
                        email: session ? undefined : feedbackData.email,
                        urgency: feedbackData.urgency
                    }),
                });

                if (!response.ok) {
                    throw new Error(__('Failed to submit feedback', translationOptions));
                }
            }

            toast.success(__('Feedback submitted successfully!', translationOptions));
            setFeedbackData({
                message: '',
                urgency: 'low',
                email: '',
                screenshots: [],
            });
            onClose();
        } catch (error) {
            console.error(__('Error submitting feedback:', translationOptions), error);
            toast.error(__('An error occurred. Please try again later.', translationOptions));
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFeedbackData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFeedbackData(prevData => ({ ...prevData, screenshots: Array.from(e.target.files!) }));
        }
    };

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                >
                    <motion.div
                        className="bg-white dark:bg-gray-800 rounded-lg p-8 max-w-2xl w-full"
                        initial={{ scale: 0.9, y: 20 }}
                        animate={{ scale: 1, y: 0 }}
                        exit={{ scale: 0.9, y: 20 }}
                    >
                        <div className="flex justify-between items-center mb-6">
                            <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-100">{__('Provide Feedback', translationOptions)}</h2>
                            <button onClick={onClose} className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200">
                                <FiX size={24} />
                            </button>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label htmlFor="message" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                                    {__('Your Feedback', translationOptions)}
                                </label>
                                <textarea
                                    id="message"
                                    name="message"
                                    rows={4}
                                    className="w-full px-3 py-2 text-gray-700 dark:text-gray-300 border rounded-lg focus:outline-none focus:border-indigo-500 dark:border-gray-600 dark:bg-gray-700"
                                    value={feedbackData.message}
                                    onChange={handleInputChange}
                                    required
                                ></textarea>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="urgency" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                                    {__('Urgency', translationOptions)}
                                </label>
                                <select
                                    id="urgency"
                                    name="urgency"
                                    className="w-full px-3 py-2 text-gray-700 dark:text-gray-300 border rounded-lg focus:outline-none focus:border-indigo-500 dark:border-gray-600 dark:bg-gray-700"
                                    value={feedbackData.urgency}
                                    onChange={handleInputChange}
                                >
                                    <option value="low">{__('Low', translationOptions)}</option>
                                    <option value="medium">{__('Medium', translationOptions)}</option>
                                    <option value="high">{__('High', translationOptions)}</option>
                                </select>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="screenshots" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                                    {__('Upload Screenshots (optional)', translationOptions)}
                                </label>
                                <div className="flex items-center justify-center w-full">
                                    <label htmlFor="screenshots" className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500">
                                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                            <FiUpload className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" />
                                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">{__('Click to upload', translationOptions)}</span> {__('or drag and drop', translationOptions)}</p>
                                            <p className="text-xs text-gray-500 dark:text-gray-400">{__('PNG, JPG, GIF up to 10MB', translationOptions)}</p>
                                        </div>
                                        <input id="screenshots" name="screenshots" type="file" className="hidden" onChange={handleFileChange} multiple accept="image/*" />
                                    </label>
                                </div>
                                {feedbackData.screenshots.length > 0 && (
                                    <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                                        {__('{{count}} file(s) selected', { placeholders: { count: feedbackData.screenshots.length } })}
                                    </p>
                                )}
                            </div>
                            {!session && (
                                <div className="mb-4">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                                        {__('Email (optional)', translationOptions)}
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        className="w-full px-3 py-2 text-gray-700 dark:text-gray-300 border rounded-lg focus:outline-none focus:border-indigo-500 dark:border-gray-600 dark:bg-gray-700"
                                        value={feedbackData.email}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            )}
                            <div className="flex justify-end">
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50 disabled:opacity-50"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? __('Submitting...', translationOptions) : __('Submit Feedback', translationOptions)}
                                </button>
                            </div>
                        </form>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default FeedbackForm;