// src/components/AutoCopyToggle.tsx
import React from 'react';
import { __, TranslationOptions } from '@/utils/translationUtils';
import { Toggle } from '@/components/ui/Toggle';

export const AutoCopyToggle: React.FC<{ 
  autoCopy: boolean; 
  onAutoCopyChange: () => void;
  disabled?: boolean;
}> = ({ autoCopy, onAutoCopyChange, disabled }) => {
  const translationOptions: TranslationOptions = { source: 'uiComponents' };

  return (
    <div className="flex items-center justify-end">
      <Toggle
        checked={autoCopy}
        onCheckedChange={onAutoCopyChange}
        label={__("Auto-copy", translationOptions)}
        ariaLabel={__("Toggle auto-copy", translationOptions)}
        disabled={disabled}
      />
    </div>
  );
};