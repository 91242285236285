import { FaKeyboard, FaMicrophone } from "react-icons/fa";
import { twMerge } from 'tailwind-merge';
import { __, TranslationOptions } from '@/utils/translationUtils';

interface ModeToggleProps {
  mode: 'tts' | 'stt';
  onModeChange: (mode: 'tts' | 'stt') => void;
  containerClassName?: string;
  toggleClassName?: string;
  sliderClassName?: string;
  buttonClassName?: string;
  activeButtonClassName?: string;
  inactiveButtonClassName?: string;
}

export const ModeToggle: React.FC<ModeToggleProps> = ({
  mode,
  onModeChange,
  containerClassName = '',
  toggleClassName = '',
  sliderClassName = '',
  buttonClassName = '',
  activeButtonClassName = '',
  inactiveButtonClassName = '',
}) => {
  const translationOptions: TranslationOptions = { source: 'uiComponents' };

  return (
    <div className={twMerge("walkthrough-modetoggle-container inline-flex rounded-full note-taker-mode-switch", containerClassName)}>
      <div className={twMerge("walkthrough-modetoggle-toggle relative flex items-center rounded-full", toggleClassName)}>
        <div
          className={twMerge(
            "walkthrough-modetoggle-slider absolute h-full w-1/2 rounded-full transition-transform duration-300 ease-in-out",
            mode === 'tts' ? 'transform translate-x-0' : 'transform translate-x-full',
            sliderClassName
          )}
        />
        <button
          className={twMerge(
            "walkthrough-modetoggle-type-button relative z-10 px-4 py-2 rounded-full text-sm font-medium transition-colors duration-300 note-taker-type-button",
            buttonClassName,
            mode === 'tts' ? twMerge("text-white", activeButtonClassName) : twMerge("text-gray-700 dark:text-gray-300", inactiveButtonClassName)
          )}
          onClick={() => onModeChange('tts')}
        >
          <FaKeyboard className="walkthrough-modetoggle-type-icon inline-block mr-2"/>
          {__('Type', translationOptions)}
        </button>
        <button
          className={twMerge(
            "walkthrough-modetoggle-speak-button relative z-10 px-4 py-2 rounded-full text-sm font-medium transition-colors duration-300 note-taker-speak-button",
            buttonClassName,
            mode === 'stt' ? twMerge("text-white", activeButtonClassName) : twMerge("text-gray-700 dark:text-gray-300", inactiveButtonClassName)
          )}
          onClick={() => onModeChange('stt')}
        >
          <FaMicrophone className="walkthrough-modetoggle-speak-icon inline-block mr-2"/>
          {__('Speak', translationOptions)}
        </button>
      </div>
    </div>
  );
};