import { useEffect, useRef, useState } from "react";
import { useTheme } from "next-themes";
import { eventBus } from "@/utils/EventBus";
import { EVENTS } from "@/constants/events";
import { __, TranslationOptions } from "@/utils/translationUtils";

interface AudioVisualizerProps {
    isRecording: boolean;
    audioContext: AudioContext | null;
    analyserNode: AnalyserNode | null;
}

interface DecibelPoint {
    time: number; // Absolute time in milliseconds (Date.now())
    value: number;
}

export const AudioVisualizer: React.FC<AudioVisualizerProps & {
    quality?: 'low' | 'medium' | 'high'
}> = ({
          isRecording,
          audioContext,
          analyserNode,
          quality = 'medium'
      }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const { theme } = useTheme();
    const animationFrameRef = useRef<number | null>(null);
    const [currentDecibels, setCurrentDecibels] = useState(0);
    const decibelHistoryRef = useRef<DecibelPoint[]>([]);
    const historyDuration = 15 * 1000; // 15 seconds
    const baseTimeRef = useRef<number>(Date.now());

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas || !audioContext || !analyserNode) return;

        const canvasCtx = canvas.getContext('2d', { alpha: false });
        if (!canvasCtx) return;

        const resizeCanvas = () => {
            const { width, height } = canvas.parentElement!.getBoundingClientRect();
            canvas.width = width * window.devicePixelRatio;
            canvas.height = height * window.devicePixelRatio;
            canvasCtx.scale(window.devicePixelRatio, window.devicePixelRatio);
        };

        resizeCanvas();
        window.addEventListener('resize', resizeCanvas);

        const bufferLength = analyserNode.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        const getQualitySettings = () => {
            switch (quality) {
                case 'low':
                    return { lineWidth: 1, smoothingTimeConstant: 0.8, fftSize: 1024 };
                case 'high':
                    return { lineWidth: 3, smoothingTimeConstant: 0.5, fftSize: 4096 };
                default:
                    return { lineWidth: 2, smoothingTimeConstant: 0.6, fftSize: 2048 };
            }
        };

        const { lineWidth, smoothingTimeConstant, fftSize } = getQualitySettings();
        analyserNode.smoothingTimeConstant = smoothingTimeConstant;
        analyserNode.fftSize = fftSize;

        const calculateDecibels = (data: Uint8Array): number => {
            let sumSquares = 0;
            for (let i = 0; i < data.length; i++) {
                const normalized = (data[i] - 128) / 128;
                sumSquares += normalized * normalized;
            }
            const rms = Math.sqrt(sumSquares / data.length);
            const decibels = 20 * Math.log10(rms || 1);
            return decibels;
        };

        const draw = () => {
            if (!isRecording) {
                // Cancel the animation frame if not recording
                if (animationFrameRef.current) {
                    cancelAnimationFrame(animationFrameRef.current);
                    animationFrameRef.current = null;
                }
                return;
            }

            // Retrieve the current audio data
            analyserNode.getByteTimeDomainData(dataArray);
            const decibels = calculateDecibels(dataArray);
            setCurrentDecibels(decibels);
            const now = Date.now();

            // Update decibel history: remove old entries and add the current decibel value
            decibelHistoryRef.current = decibelHistoryRef.current.filter(
                (point: DecibelPoint) => now - point.time < historyDuration
            );
            decibelHistoryRef.current.push({ time: now, value: decibels });

            // Resize the canvas to match its container's size
            const { width, height } = canvas.getBoundingClientRect();
            canvas.width = width * window.devicePixelRatio;
            canvas.height = height * window.devicePixelRatio;
            canvasCtx.scale(window.devicePixelRatio, window.devicePixelRatio);

            // Fill the background based on the current theme
            canvasCtx.fillStyle = theme === 'dark' ? 'rgba(17, 24, 39, 0.8)' : 'rgba(255, 255, 255, 0.8)';
            canvasCtx.fillRect(0, 0, width, height);

            // Draw Waveform
            canvasCtx.lineWidth = lineWidth;
            canvasCtx.strokeStyle = 'rgba(255, 0, 0, 0.8)';
            canvasCtx.beginPath();

            const sliceWidth = width / bufferLength;
            let x = 0;

            for (let i = 0; i < bufferLength; i++) {
                const v = dataArray[i] / 128.0;
                const y = (v * height) / 2;

                if (i === 0) {
                    canvasCtx.moveTo(x, y);
                } else {
                    canvasCtx.lineTo(x, y);
                }

                x += sliceWidth;
            }

            canvasCtx.lineTo(width, height / 2);
            canvasCtx.stroke();

            // Draw Decibel History
            const dbHeight = 50;
            const historyWidth = width - 20;
            const historyHeight = 100;
            canvasCtx.strokeStyle = 'rgba(128, 0, 128, 0.8)';
            canvasCtx.beginPath();
            const historyData = decibelHistoryRef.current;
            if (historyData.length > 0) {
                const startTime = now - historyDuration;
                const timeScale = historyWidth / historyDuration;
                historyData.forEach((point, index) => {
                    const xPos = 10 + (point.time - startTime) * timeScale;
                    const yPos = height - dbHeight - 20 - ((point.value + 60) / 60) * historyHeight;
                    if (index === 0) {
                        canvasCtx.moveTo(xPos, yPos);
                    } else {
                        canvasCtx.lineTo(xPos, yPos);
                    }
                });
                canvasCtx.stroke();
            }

            // Request the next animation frame to continue drawing
            animationFrameRef.current = requestAnimationFrame(draw);
        };

        if (isRecording) {
            draw();
        } else {
            const { width, height } = canvas.getBoundingClientRect();
            canvas.width = width * window.devicePixelRatio;
            canvas.height = height * window.devicePixelRatio;
            canvasCtx.scale(window.devicePixelRatio, window.devicePixelRatio);
            canvasCtx.fillStyle = theme === 'dark' ? 'rgba(17, 24, 39, 0.8)' : 'rgba(255, 255, 255, 0.8)';
            canvasCtx.fillRect(0, 0, width, height);
        }

        const handleAudioTrimmed = (data: { trimmedSeconds: number; newStartTime: number; currentTime: number; accumulatedDuration: number }) => {
            const { trimmedSeconds, currentTime } = data;
            const cutoffTime = currentTime - trimmedSeconds * 1000; // Convert to milliseconds
        
            // Remove only the last n seconds from the decibel history
            decibelHistoryRef.current = decibelHistoryRef.current.filter(
                (point: DecibelPoint) => point.time <= cutoffTime
            );

            // Update the baseTimeRef to reflect the new start time
            baseTimeRef.current = cutoffTime;
        };        

        // Subscribe to AUDIO_TRIMMED event
        eventBus.on(EVENTS.AUDIO_TRIMMED, handleAudioTrimmed);

        return () => {
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
                animationFrameRef.current = null;
            }
            window.removeEventListener('resize', resizeCanvas);
            eventBus.off(EVENTS.AUDIO_TRIMMED, handleAudioTrimmed);
        };
    }, [isRecording, audioContext, analyserNode, theme, quality]);

    const translationOptions: TranslationOptions = { source: 'audio' };

    return (
        <div className="relative w-full h-full rounded-lg">
            <canvas
                ref={canvasRef}
                className="w-full h-full rounded-lg"
            />
            <div className="absolute bottom-2 left-2">
                <div className="text-white text-sm">
                    {__("Current dB: {{decibels}}", {
                        ...translationOptions,
                        placeholders: { decibels: currentDecibels.toFixed(2) }
                    })}
                </div>
                <div className="text-white text-xs">
                    {__("Decibel History (Last 15s)", translationOptions)}
                </div>
            </div>
        </div>
    );
};
