'use client';

import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useWalkthrough } from '@/contexts/WalkthroughContext';
import { WalkthroughStep } from './WalkthroughStep';
import { walkthroughs } from '@/data/walkthroughs';
import { usePopper } from 'react-popper';

export const WalkthroughController: React.FC = () => {
    const {
        currentWalkthrough,
        currentStep,
        nextStep,
        previousStep,
        skipWalkthrough,
        getCurrentStepContent,
        getFirstVisibleElement
    } = useWalkthrough();

    const [portalContainer, setPortalContainer] = useState<HTMLElement | null>(null);
    const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
    const overlayRef = useRef<HTMLDivElement>(null);
    const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
        placement: 'auto',
        modifiers: [
            { name: 'offset', options: { offset: [0, 8] } },
            { name: 'preventOverflow', options: { boundary: 'clippingParents' } },
            { name: 'flip', options: { fallbackPlacements: ['top', 'bottom', 'left', 'right'] } }
        ]
    });

    // Force re-render when currentWalkthrough changes
    const [, forceUpdate] = useState({});
    useEffect(() => {
        forceUpdate({});
    }, [currentWalkthrough]);

    useEffect(() => {
        const container = document.createElement('div');
        container.style.position = 'fixed';
        container.style.top = '0';
        container.style.left = '0';
        container.style.width = '100%';
        container.style.height = '100%';
        container.style.pointerEvents = 'none';
        container.style.zIndex = '2147483647'; // Maximum z-index value

        document.body.appendChild(container);
        setPortalContainer(container);

        return () => {
            if (document.body.contains(container)) {
                document.body.removeChild(container);
            }
        };
    }, []);

    useEffect(() => {
        if (!currentWalkthrough || !walkthroughs[currentWalkthrough]) {
            setReferenceElement(null);
            return;
        }

        const step = getCurrentStepContent();
        if (!step) {
            setReferenceElement(null);
            return;
        }

        if (step.target) {
            const targetElement = getFirstVisibleElement(step.target);
            setReferenceElement(targetElement as HTMLElement || null);
        } else {
            setReferenceElement(null);
        }

        if (update) {
            update();
        }

    }, [currentWalkthrough, currentStep, update, getCurrentStepContent, getFirstVisibleElement]);

    if (!currentWalkthrough || !portalContainer) {
        return null;
    }

    const walkthrough = walkthroughs[currentWalkthrough];
    const step = getCurrentStepContent();
    if (!step) {
        return null;
    }

    const isCentered = step.placement === 'center' || !referenceElement;

    const generateClipPath = (referenceElement: HTMLElement): string => {
        const rect = referenceElement.getBoundingClientRect();
        return `
            polygon(
                0% 0%,
                0% 100%,
                ${rect.left}px 100%,
                ${rect.left}px ${rect.top}px,
                ${rect.right}px ${rect.top}px,
                ${rect.right}px ${rect.bottom}px,
                ${rect.left}px ${rect.bottom}px,
                ${rect.left}px 100%,
                100% 100%,
                100% 0%
            )
        `;
    };

    return createPortal(
        <>
            <div
                className="fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300 ease-in-out pointer-events-auto walkthrough-overlay"
                style={{
                    zIndex: '2147483647',
                    clipPath: referenceElement ? generateClipPath(referenceElement) : undefined,
                }}
            />
            {referenceElement && (
                <div
                    ref={overlayRef}
                    className="fixed transition-all duration-300 ease-in-out border-2 border-indigo-500 rounded-lg pointer-events-none"
                    style={{
                        top: referenceElement.getBoundingClientRect().top,
                        left: referenceElement.getBoundingClientRect().left,
                        width: referenceElement.getBoundingClientRect().width,
                        height: referenceElement.getBoundingClientRect().height,
                        zIndex: '2147483647',
                    }}
                />
            )}
            <div
                ref={setPopperElement}
                style={{
                    ...isCentered ? {
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                    } : styles.popper,
                    zIndex: '2147483647',
                }}
                {...attributes.popper}
            >
                <WalkthroughStep
                    step={step}
                    currentStep={currentStep}
                    totalSteps={walkthrough.steps.length}
                    isProgressiveWalkthrough={walkthrough.type === 'progressive'}
                    isWaitingForInteraction={step.waitForInteraction || false}
                    showOverlay={step.showOverlay !== false}
                    onSkip={skipWalkthrough}
                    onPrevious={previousStep}
                    onNext={nextStep}
                    onActionCompleted={nextStep}
                    style={{
                        pointerEvents: 'auto'
                    }}
                />
            </div>
        </>,
        portalContainer
    );
};
