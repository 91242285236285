'use client'

import { useEffect } from 'react'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { getEnv, isProduction } from '@/lib/utils/env'

export default function PostHogContextProvider({ children }: { children: React.ReactNode }) {
  const isProductionEnv = isProduction()
  // const isProduction = true;
  useEffect(() => {
    // Check that PostHog is client-side and environment is production
    if (typeof window !== 'undefined' && isProductionEnv) {
      posthog.init(getEnv('NEXT_PUBLIC_POSTHOG_KEY') || 'phc_stybcWS0ehhvV9YtyNqgwgSzCgLL6bb4ccIJhO9dbVQ', {
        api_host: getEnv('NEXT_PUBLIC_POSTHOG_HOST') || 'https://us.i.posthog.com',
        ui_host: getEnv('NEXT_PUBLIC_POSTHOG_UI_HOST') || 'https://us.posthog.com',
        person_profiles: 'identified_only',
        capture_pageview: false, // Disable automatic pageview capture, as we capture manually
        opt_in_site_apps: true, // Enable site apps (including toolbar)
      })
    }
  }, [])

  // Only render PostHogProvider in production
  if (isProductionEnv) {
    return <PostHogProvider client={posthog}>{children}</PostHogProvider>
  }

  // In non-production environments, just render children without PostHog
  return <>{children}</>
}
