'use client';

import React, { useState, useEffect, useRef, ReactNode } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useRouter } from 'next/navigation';
import { FaSpinner, FaCreditCard, FaCog } from 'react-icons/fa';
import { __, TranslationOptions } from '@/utils/translationUtils';
import { MiniCreditPackages } from './billing/MiniCreditPackages';

interface InsufficientCreditsModalProps {
  onClose?: () => void;
  onManageSubscription?: () => void;
  onPurchaseMinutes?: () => void;
  children?: ReactNode;
}

const InsufficientCreditsModal: React.FC<InsufficientCreditsModalProps> = ({ onClose, onManageSubscription, onPurchaseMinutes, children }) => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [activeButton, setActiveButton] = useState<'manage' | 'purchase' | null>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const [modalWidth, setModalWidth] = useState(0);

  const translationOptions: TranslationOptions = { source: 'billing' };

  useEffect(() => {
    const updateModalWidth = () => {
      if (modalRef.current) {
        setModalWidth(modalRef.current.offsetWidth);
      }
    };

    updateModalWidth();
    window.addEventListener('resize', updateModalWidth);
    return () => window.removeEventListener('resize', updateModalWidth);
  }, []);

  const getFontSize = (baseSize: number) => {
    return `${Math.max(baseSize * (modalWidth / 500), baseSize * 0.8)}px`;
  };

  const handlePurchaseMinutes = async () => {
    setIsLoading(true);
    setActiveButton('purchase');
    if (onPurchaseMinutes) {
      onPurchaseMinutes();
    } else {
      try {
        const response = await fetch('/api/create-checkout-session', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ productType: 'seconds_package' }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || __('Failed to create checkout session', translationOptions));
        }

        const { sessionId } = await response.json();
        
        router.push(`/api/checkout?session_id=${sessionId}`);
      } catch (error) {
        console.error(__('Error creating checkout session:', translationOptions), error);
      } finally {
        setIsLoading(false);
        setActiveButton(null);
      }
    }
  };

  const handleManageSubscription = async () => {
    setIsLoading(true);
    setActiveButton('manage');
    if (onManageSubscription) {
      onManageSubscription();
    } else {
      try {
        router.push('/credits');
      } catch (error) {
        console.error(__('Error navigating to credits page:', translationOptions), error);
      } finally {
        setIsLoading(false);
        setActiveButton(null);
      }
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4 overflow-auto"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <motion.div
          ref={modalRef}
          className="bg-white dark:bg-gray-800 rounded-lg p-6 w-full max-w-lg shadow-2xl max-h-full overflow-auto"
          initial={{ scale: 0.9, y: 20 }}
          animate={{ scale: 1, y: 0 }}
          exit={{ scale: 0.9, y: 20 }}
          style={{ fontSize: getFontSize(16) }}
        >
          <motion.h2 
            className="font-bold mb-4 text-red-600 dark:text-red-400"
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2 }}
            style={{ fontSize: getFontSize(24) }}
          >
            {__("Oops! You've run out of credits", translationOptions)}
          </motion.h2>
          <motion.p 
            className="text-gray-700 dark:text-gray-300 mb-6"
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.3 }}
          >
            {__("Select a package below to top up your credits and continue using our services.", translationOptions)}
          </motion.p>

          <motion.div
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.4 }}
            className="mb-6"
          >
            <MiniCreditPackages
              onPackageSelect={handlePurchaseMinutes}
              isLoading={isLoading}
            />
          </motion.div>

          {children && (
            <motion.div
              className="mb-6"
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              {children}
            </motion.div>
          )}

          {onClose && (
            <motion.button
              onClick={onClose}
              className="mt-4 w-full text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 transition duration-300"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              style={{ fontSize: getFontSize(14) }}
            >
              {__("Close", translationOptions)}
            </motion.button>
          )}
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default InsufficientCreditsModal;