import { create } from 'zustand';
import { RecordingState } from '@/constants/events';

interface AudioStore {
  recordingState: RecordingState;
  accumulatedDuration: number;
  currentRecordingStartTime: number | null;
  audioBlob: Blob | null;
  analyserNode: AnalyserNode | null;
  mediaRecorder: MediaRecorder | null;
  stream: MediaStream | null;
  setRecordingState: (state: RecordingState) => void;
  setAccumulatedDuration: (duration: number) => void;
  setCurrentRecordingStartTime: (time: number | null) => void;
  setAudioBlob: (blob: Blob | null) => void;
  setAnalyserNode: (node: AnalyserNode | null) => void;
  setMediaRecorder: (recorder: MediaRecorder | null) => void;
  setStream: (stream: MediaStream | null) => void;
  resetStore: () => void;
}

export const useAudioStore = create<AudioStore>((set) => ({
  recordingState: RecordingState.IDLE,
  accumulatedDuration: 0,
  currentRecordingStartTime: null,
  audioBlob: null,
  analyserNode: null,
  mediaRecorder: null,
  stream: null,
  setRecordingState: (state) => set({ recordingState: state }),
  setAccumulatedDuration: (duration) => set({ accumulatedDuration: duration }),
  setCurrentRecordingStartTime: (time) => set({ currentRecordingStartTime: time }),
  setAudioBlob: (blob) => set({ audioBlob: blob }),
  setAnalyserNode: (node) => set({ analyserNode: node }),
  setMediaRecorder: (recorder) => set({ mediaRecorder: recorder }),
  setStream: (stream) => set({ stream: stream }),
  resetStore: () => set({
    recordingState: RecordingState.IDLE,
    accumulatedDuration: 0,
    currentRecordingStartTime: null,
    audioBlob: null,
    analyserNode: null,
    mediaRecorder: null,
    stream: null,
  }),
}));