// Centralized environment configuration with documentation
// Remember to update .env.example when modifying these

type EnvVarSpec = {
  /**
   * Default value if environment variable is missing
   * Use 'REQUIRED' for variables that must be set in production
   * Use obvious placeholder values for missing defaults (e.g. 'missing-secret-in-env')
   */
  default: string | number | boolean;
  /** Documentation for developers about this variable */
  description: string;
  /** If true, this env var must be set or an exception will be thrown */
  required?: boolean;
  /** The value of the environment variable, usually set from process.env */
  value?: string;
}

const ENV_SPEC: Record<string, EnvVarSpec> = {
  // Auth Related
  NEXTAUTH_SECRET: {
    default: 'missing-secret-in-env',
    description: 'Secret for signing JWTs and session cookies. Must be 32+ chars in production.',
    value: process.env.NEXTAUTH_SECRET,
  },
  NEXTAUTH_URL: {
    default: 'https://your-production-domain.com',
    description: 'Base URL of the application',
    value: process.env.NEXTAUTH_URL,
  },
  JWT_SECRET: {
    default: 'missing-jwt-secret-in-env',
    description: 'Secret for JWT token signing (if different from NEXTAUTH_SECRET)',
    value: process.env.JWT_SECRET,
  },

  // Email Configuration
  MAILGUN_SMTP_PORT: {
    default: 587,
    description: 'Mailgun SMTP port (587 for TLS)',
    value: process.env.MAILGUN_SMTP_PORT,
  },
  MAILGUN_SMTP_HOST: {
    default: 'REQUIRED',
    description: 'Mailgun SMTP hostname for email services',
    value: process.env.MAILGUN_SMTP_HOST,
  },
  MAILGUN_SMTP_USER: {
    default: 'REQUIRED',
    description: 'Mailgun SMTP username for email authentication',
    value: process.env.MAILGUN_SMTP_USER,
  },
  MAILGUN_SMTP_PASS: {
    default: 'REQUIRED',
    description: 'Mailgun SMTP password for email authentication',
    value: process.env.MAILGUN_SMTP_PASS,
  },
  DEFAULT_EMAIL_SENDER: {
    default: 'noreply@sondeas.com',
    description: 'Default sender address for outgoing emails',
    value: process.env.DEFAULT_EMAIL_SENDER,
  },
  CONTACT_EMAIL: {
    default: 'support@sondeas.com',
    description: 'Default recipient for contact form submissions',
    value: process.env.CONTACT_EMAIL,
  },
  FEEDBACK_EMAIL: {
    default: 'feedback@sondeas.com',
    description: 'Recipient address for user feedback submissions',
    value: process.env.FEEDBACK_EMAIL,
  },

  // API Keys
  OPENAI_API_KEY: {
    default: 'missing-openai-key-in-env',
    description: 'OpenAI API key for AI features',
    value: process.env.OPENAI_API_KEY,
  },
  ANTHROPIC_API_KEY: {
    default: 'missing-anthropic-key-in-env',
    description: 'Anthropic API key for Claude AI features',
    value: process.env.ANTHROPIC_API_KEY,
  },
  GROQ_API_KEY: {
    default: 'missing-groq-key-in-env',
    description: 'Groq API key for AI features',
    value: process.env.GROQ_API_KEY,
  },
  ASSEMBLYAI_API_KEY: {
    default: 'missing-assemblyai-key-in-env',
    description: 'AssemblyAI API key for speech-to-text features',
    value: process.env.ASSEMBLYAI_API_KEY,
  },
  HF_TOKEN: {
    default: 'missing-huggingface-token-in-env',
    description: 'HuggingFace token for AI model access',
    value: process.env.HF_TOKEN,
  },

  // Google Cloud & Analytics
  GOOGLE_CLOUD_PROJECT_ID: {
    default: 'REQUIRED',
    description: 'Google Cloud project ID for GCP services',
    value: process.env.GOOGLE_CLOUD_PROJECT_ID,
  },
  GOOGLE_CLOUD_STORAGE_BUCKET: {
    default: 'REQUIRED',
    description: 'Google Cloud Storage bucket name',
    value: process.env.GOOGLE_CLOUD_STORAGE_BUCKET,
  },
  GOOGLE_CLOUD_CREDENTIALS: {
    default: '',
    description: 'Base64 encoded Google Cloud service account credentials',
    value: process.env.GOOGLE_CLOUD_CREDENTIALS,
  },
  GOOGLE_APPLICATION_CREDENTIALS_JSON: {
    default: '{}',
    description: 'JSON string of Google Cloud service account credentials',
    value: process.env.GOOGLE_APPLICATION_CREDENTIALS_JSON,
  },
  GA_MEASUREMENT_ID: {
    default: '',
    description: 'Google Analytics measurement ID',
    value: process.env.GA_MEASUREMENT_ID,
  },
  GA_API_SECRET: {
    default: '',
    description: 'Google Analytics API secret',
    value: process.env.GA_API_SECRET,
  },

  // OAuth Credentials
  GOOGLE_CLIENT_ID: {
    default: 'REQUIRED',
    description: 'Google OAuth client ID',
    value: process.env.GOOGLE_CLIENT_ID,
  },
  GOOGLE_CLIENT_SECRET: {
    default: 'REQUIRED',
    description: 'Google OAuth client secret',
    value: process.env.GOOGLE_CLIENT_SECRET,
  },
  FACEBOOK_CLIENT_ID: {
    default: 'REQUIRED',
    description: 'Facebook OAuth client ID',
    value: process.env.FACEBOOK_CLIENT_ID,
  },
  FACEBOOK_CLIENT_SECRET: {
    default: 'REQUIRED',
    description: 'Facebook OAuth client secret',
    value: process.env.FACEBOOK_CLIENT_SECRET,
  },

  // Stripe Integration
  STRIPE_SECRET_KEY: {
    default: 'REQUIRED',
    description: 'Stripe secret key for payment processing',
    value: process.env.STRIPE_SECRET_KEY,
  },
  STRIPE_WEBHOOK_SECRET: {
    default: 'REQUIRED',
    description: 'Stripe webhook signing secret',
    value: process.env.STRIPE_WEBHOOK_SECRET,
  },

  // PostHog Analytics
  NEXT_PUBLIC_POSTHOG_KEY: {
    default: 'phc_stybcWS0ehhvV9YtyNqgwgSzCgLL6bb4ccIJhO9dbVQ',
    description: 'PostHog API key',
    value: process.env.NEXT_PUBLIC_POSTHOG_KEY,
  },
  NEXT_PUBLIC_POSTHOG_HOST: {
    default: 'https://us.i.posthog.com',
    description: 'PostHog API host',
    value: process.env.NEXT_PUBLIC_POSTHOG_HOST,
  },
  NEXT_PUBLIC_POSTHOG_UI_HOST: {
    default: 'https://us.posthog.com',
    description: 'PostHog UI host',
    value: process.env.NEXT_PUBLIC_POSTHOG_UI_HOST,
  },

  // Company Information
  COMPANY_NAME: {
    default: 'WESOLVERS LTD',
    description: 'Company name for invoices and legal documents',
    value: process.env.COMPANY_NAME,
  },
  COMPANY_ADDRESS_LINE1: {
    default: 'Kemp House, 152 - 160 City Road',
    description: 'Company address line 1',
    value: process.env.COMPANY_ADDRESS_LINE1,
  },
  COMPANY_ADDRESS_LINE2: {
    default: '',
    description: 'Company address line 2',
    value: process.env.COMPANY_ADDRESS_LINE2,
  },
  COMPANY_CITY: {
    default: 'London',
    description: 'Company city',
    value: process.env.COMPANY_CITY,
  },
  COMPANY_STATE: {
    default: 'England',
    description: 'Company state/region',
    value: process.env.COMPANY_STATE,
  },
  COMPANY_POSTAL_CODE: {
    default: 'EC1V 2NX',
    description: 'Company postal code',
    value: process.env.COMPANY_POSTAL_CODE,
  },
  COMPANY_COUNTRY: {
    default: 'United Kingdom',
    description: 'Company country',
    value: process.env.COMPANY_COUNTRY,
  },
  COMPANY_TAX_ID: {
    default: '11797268',
    description: 'Company tax ID',
    value: process.env.COMPANY_TAX_ID,
  },
  COMPANY_EMAIL: {
    default: 'billing@wesolvers.com',
    description: 'Company billing email',
    value: process.env.COMPANY_EMAIL,
  },

  // Public URLs and Base URLs
  NEXT_PUBLIC_BASE_URL: {
    default: '',
    description: 'Public-facing base URL for client-side redirects',
    value: process.env.NEXT_PUBLIC_BASE_URL,
  },
  NEXT_PUBLIC_APP_URL: {
    default: '',
    description: 'Public-facing application URL',
    value: process.env.NEXT_PUBLIC_APP_URL,
  },
  NEXT_PUBLIC_APP_NAME: {
    default: 'Sondeas',
    description: 'Application name for display purposes',
    value: process.env.NEXT_PUBLIC_APP_NAME,
  },
  NEXT_PUBLIC_COMMIT_HASH: {
    default: 'local',
    description: 'Git commit hash for version tracking (automatically injected)',
    required: true,
    value: process.env.NEXT_PUBLIC_COMMIT_HASH,
  },

  // Database
  DATABASE_URL: {
    default: 'postgresql://postgres:postgres@localhost:5432/postgres',
    description: 'PostgreSQL database connection string',
    value: process.env.DATABASE_URL,
  },

  // Audio Configuration
  NEXT_PUBLIC_AUDIO_START: {
    default: '/audio/start.mp3',
    description: 'Path to audio file for start events',
    value: process.env.NEXT_PUBLIC_AUDIO_START,
  },
  NEXT_PUBLIC_AUDIO_STOP: {
    default: '/audio/stop.mp3',
    description: 'Path to audio file for stop events',
    value: process.env.NEXT_PUBLIC_AUDIO_STOP,
  },
  NEXT_PUBLIC_AUDIO_PAUSE: {
    default: '/audio/stop.mp3',
    description: 'Path to audio file for pause events',
    value: process.env.NEXT_PUBLIC_AUDIO_PAUSE,
  },
  NEXT_PUBLIC_AUDIO_RESUME: {
    default: '/audio/start.mp3',
    description: 'Path to audio file for resume events',
    value: process.env.NEXT_PUBLIC_AUDIO_RESUME,
  },
  NEXT_PUBLIC_AUDIO_CANCEL: {
    default: '/audio/stop.mp3',
    description: 'Path to audio file for cancel events',
    value: process.env.NEXT_PUBLIC_AUDIO_CANCEL,
  },

  // Other Configuration
  ENCRYPTION_KEY: {
    default: 'REQUIRED',
    description: 'Key used for encryption operations',
    value: process.env.ENCRYPTION_KEY,
  },
  FALLBACK_SECRET: {
    default: 'fallback-secret',
    description: 'Fallback secret for encryption when primary is not available',
    value: process.env.FALLBACK_SECRET,
  },
  INVOICE_TERMS: {
    default: 'Payment is due immediately. All sales are final. For questions about your invoice, please contact billing@wesolvers.com',
    description: 'Default terms to display on invoices',
    value: process.env.INVOICE_TERMS,
  },
};

export function getEnv(key: string): string {
  const spec = ENV_SPEC[key];
  const value = spec?.value ?? process.env[key];
  
  if (!value) {
    if (key.startsWith('NEXT_PUBLIC_') && !spec) {
      console.warn({
        message: 'NEXT_PUBLIC_ environment variable accessed without spec - these must be added to ENV_SPEC to be accessible',
        requestedKey: key,
      });
      return '';
    }

    if (spec?.required && process.env.DOCKER_BUILD !== '1') {
      console.error({
        message: 'Missing required environment variable',
        requestedKey: key,
        spec,
        processEnv: process.env,
      });
      throw new Error(`Missing required environment variable: ${key}`);
    }
    if (process.env.NODE_ENV === 'production' && spec?.default === 'REQUIRED') {
      return spec.default.toString();
    }
    return spec?.default?.toString() ?? '';
  }
  
  return value;
}

export function getRequiredEnv(key: string): string {
  const value = getEnv(key);
  if (!value && process.env.DOCKER_BUILD !== '1') {
    console.error({
      message: 'Missing required environment variable', 
      requestedKey: key,
      spec: ENV_SPEC[key],
      processEnv: process.env,
    });
    throw new Error(`Missing required environment variable: ${key}`);
  }
  return value;
}

// Helper to check if we're in production
export function isProduction(): boolean {
  return process.env.NODE_ENV === 'production';
}

// Helper to check if we're in development
export function isDevelopment(): boolean {
  return process.env.NODE_ENV === 'development';
}

// Helper to check if we're in test
export function isTest(): boolean {
  return process.env.NODE_ENV === 'test';
} 