// src/components/TTSMode.tsx
import React, { useEffect, useState, useRef, useMemo, forwardRef } from 'react';
import { useCredits } from '@/contexts/CreditsContext';
import { useSettings } from "@/contexts/SettingsContext";
import { voices } from "@/constants/voices";
import { copyToClipboard } from "@/utils/clipboard";
import { showToast } from "@/utils/toast";
import { VoiceSelector } from "@/components/VoiceSelector";
import AudioPlayer from "@/components/AudioPlayer";
import LoadingSpinner from "@/components/LoadingSpinner";
import { motion } from "framer-motion";
import { generateSpeech } from "@/lib/services/tts";
import { useSession } from 'next-auth/react';
import { eventBus } from "@/utils/EventBus";
import { EVENTS } from "@/constants/events";
import { Switch } from "@/components/ui/switch";
import { Button } from "@/components/ui/button";
import { Note } from '@/lib/notes/Note';
import NoteEditor from '@/components/NoteEditor';
import InsufficientCreditsModal from "@/components/InsufficientCreditsModal";
import { __ } from '@/utils/translationUtils';
import TextArea from "@/components/TextArea";

interface TTSModeProps {
    onTTSComplete: (newItem: Note) => void;
    addHistoryItem: (item: Note) => void;
    initialContent: string;
}

const TTSMode: React.FC<TTSModeProps> = ({ onTTSComplete, addHistoryItem, initialContent }) => {
    const { credits, fetchCredits, insufficientCredits } = useCredits();
    const { autocopy, selectedVoice, autoplayTTS, updateSettings } = useSettings();
    const [content, setContent] = useState(initialContent);
    const [isProcessing, setIsProcessing] = useState(false);
    const [audioUrl, setAudioUrl] = useState<string | null>(null);
    const [audioKey, setAudioKey] = useState(0);
    const [showInsufficientCreditsModal, setShowInsufficientCreditsModal] = useState(false);
    const audioPlayerRef = useRef<HTMLAudioElement>(null);
    const [textAreaKey, setTextAreaKey] = useState(0);
    const [showNoteEditor, setShowNoteEditor] = useState(false);
    const [currentAudioItem, setCurrentAudioItem] = useState<Note | null>(null);
    const [isSpeechGenerated, setIsSpeechGenerated] = useState(false);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const [hasChanges, setHasChanges] = useState(false);

    // Add verboseJson extraction
    const verboseJson = useMemo(() => {
        if (!currentAudioItem?.audios?.[0]?.metas) return undefined;
        const meta = currentAudioItem.audios[0].metas.find(m => m.key === 'verboseJson');
        if (!meta?.value) return undefined;
        try {
            return JSON.parse(meta.value);
        } catch (e) {
            console.error('Error parsing verboseJson:', e);
            return undefined;
        }
    }, [currentAudioItem]);

    useEffect(() => {
        setContent(initialContent);
    }, [initialContent]);

    useEffect(() => {
        if (audioUrl && autoplayTTS && audioPlayerRef.current) {
            audioPlayerRef.current.play();
        }
    }, [audioUrl, autoplayTTS]);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if ((event.ctrlKey || event.metaKey) && event.key === 'Enter') {
            event.preventDefault();
            handleTTS();
        }
    };

    const handleTextChange = (value: string, plainText: string) => {
        setContent(plainText);
        setHasChanges(true);
    };

    const handleTTS = async () => {
        if (insufficientCredits) {
            setShowInsufficientCreditsModal(true);
            return;
        }

        setIsProcessing(true);
        try {
            const note: Note = await generateSpeech(content, selectedVoice);
            await onTTSComplete(note);

            if (addHistoryItem) {
                addHistoryItem(note);
            }

            if (autocopy) {
                await copyToClipboard(content);
            }

            setCurrentAudioItem(note);
            setAudioUrl(`/api/audio/${note.audios[0].id}`); // Assuming the first audio is the TTS audio
            setShowNoteEditor(true);
            setIsSpeechGenerated(true);

            showToast(__('Speech generated successfully!', { source: 'audio' }), 'success');
        } catch (error) {
            console.error('Error:', error);
            showToast(__('Failed to generate speech. Please try again.', { source: 'audio' }), 'error');
        } finally {
            setIsProcessing(false);
            fetchCredits();
        }
    };

    const handleSaveNote = async () => {
        if (!content.trim()) {
            showToast(__('Please enter some text before saving.', { source: 'notes' }), 'error');
            return;
        }

        setIsProcessing(true);
        try {
            const response = await fetch('/api/notes', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ content }),
            });

            if (!response.ok) {
                throw new Error(__('Failed to save note', { source: 'notes' }));
            }

            const note: Note = await response.json();

            await onTTSComplete(note);

            if (addHistoryItem) {
                addHistoryItem(note);
            }

            setCurrentAudioItem(note);
            setShowNoteEditor(true);
            setIsSpeechGenerated(false);

            showToast(__('Note saved successfully!', { source: 'notes' }), 'success');

            eventBus.emit(EVENTS.NOTE_SAVED, note);
        } catch (error) {
            console.error('Error:', error);
            showToast(__('Failed to save note. Please try again.', { source: 'notes' }), 'error');
        } finally {
            setIsProcessing(false);
        }
    };

    const handleNoteUpdate = (updatedItem: Note) => {
        setCurrentAudioItem(updatedItem);
        if (addHistoryItem) {
            addHistoryItem(updatedItem);
        }
        onTTSComplete(updatedItem);
    };

    const handleAutoplayTTSChange = (checked: boolean) => {
        updateSettings({ autoplayTTS: checked });
    };

    const handleNewNote = () => {
        setShowNoteEditor(false);
        setCurrentAudioItem(null);
        setContent('');
        setAudioUrl(null);
        setAudioKey(prevKey => prevKey + 1);
        setTextAreaKey(prevKey => prevKey + 1);
        setIsSpeechGenerated(false);
        
        // Focus the textarea after a short delay to ensure the UI has updated
        setTimeout(() => {
            textAreaRef.current?.focus();
        }, 0);
    };

    return (
        <div className="walkthrough-ttsmode-container notetaker-tts-mode flex flex-col h-full flex-grow relative">
            <div className="walkthrough-ttsmode-autoplay mb-4 flex items-center justify-end gap-2">
                <span className="walkthrough-ttsmode-autoplay-label text-sm text-gray-700 dark:text-gray-300">{__('Autoplay', { source: 'audio' })}</span>
                <Switch
                    checked={autoplayTTS}
                    onCheckedChange={handleAutoplayTTSChange}
                    aria-label={__('Autoplay TTS', { source: 'audio' })}
                    className="walkthrough-ttsmode-autoplay-switch"
                />
            </div>
            {showNoteEditor ? (
                <>
                    <NoteEditor
                        note={currentAudioItem!}
                        onUpdate={handleNoteUpdate}
                        audioControlProps={{
                            showPlay: isSpeechGenerated,
                            showCopy: true,
                            showTranslate: true,
                        }}
                        className="walkthrough-ttsmode-noteeditor tts-mode-note-editor"
                    />
                    {audioUrl && isSpeechGenerated && (
                        <div className="walkthrough-ttsmode-audioplayer shrink-0 mt-2">
                            <AudioPlayer
                                key={audioKey}
                                audioSrc={audioUrl}
                                autoplay={autoplayTTS}
                                className="walkthrough-ttsmode-audioplayer-component tts-mode-audio-player w-full"
                            />
                        </div>
                    )}
                    <div className="walkthrough-ttsmode-newnote mt-4">
                        <Button
                            onClick={handleNewNote}
                            variant="primary"
                            className="walkthrough-ttsmode-newnote-button w-full"
                        >
                            {__('New Note', { source: 'notes' })}
                        </Button>
                    </div>
                </>
            ) : (
                <>
                    <div className="walkthrough-ttsmode-textarea flex flex-col flex-grow">
                        <TextArea
                            ref={textAreaRef}
                            value={content}
                            onChange={handleTextChange}
                            mode="tts"
                            onKeyDown={handleKeyDown}
                            readOnly={isProcessing}
                            verboseJson={verboseJson}
                        />
                        {isProcessing && (
                            <div className="walkthrough-ttsmode-loading absolute inset-0 bg-gray-200 bg-opacity-50 dark:bg-gray-800 dark:bg-opacity-50 flex items-center justify-center">
                                <LoadingSpinner size="2rem" thickness={2} speed={1.5} className="walkthrough-ttsmode-spinner text-indigo-600 dark:text-indigo-400" />
                            </div>
                        )}
                    </div>
                    {audioUrl && isSpeechGenerated && (
                        <div className="walkthrough-ttsmode-audioplayer shrink-0 mt-2">
                            <AudioPlayer
                                key={audioKey}
                                audioSrc={audioUrl}
                                autoplay={autoplayTTS}
                                className="walkthrough-ttsmode-audioplayer-component w-full"
                            />
                        </div>
                    )}
                    <div className="walkthrough-ttsmode-buttons shrink-0 mt-2 flex space-x-2">
                        <motion.button
                            onClick={handleTTS}
                            disabled={isProcessing || !content.trim()}
                            className={`walkthrough-ttsmode-generate tts-mode-generate-button flex-1 px-4 py-2 rounded-full text-xs font-semibold transition-all duration-300 ${isProcessing || !content.trim() ? 'bg-gray-400 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-700'} text-white`}
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            {isProcessing ? __('Processing...', { source: 'ui' }) : __('Generate', { source: 'audio' })}
                        </motion.button>
                        <motion.button
                            onClick={handleSaveNote}
                            disabled={isProcessing || !content.trim()}
                            className={`walkthrough-ttsmode-save tts-mode-save-button flex-1 px-4 py-2 rounded-full text-xs font-semibold transition-all duration-300 ${isProcessing || !content.trim() ? 'bg-gray-400 cursor-not-allowed' : 'bg-green-600 hover:bg-green-700'} text-white`}
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            {__('Save', { source: 'ui' })}
                        </motion.button>
                    </div>
                </>
            )}
            {showInsufficientCreditsModal && (
                <InsufficientCreditsModal
                    onClose={() => setShowInsufficientCreditsModal(false)}
                />
            )}
        </div>
    );
};

export default TTSMode;
