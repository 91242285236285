import React, { useRef, useState, useCallback } from 'react';
import { FiUpload } from 'react-icons/fi';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@radix-ui/react-tooltip';
import { __, TranslationOptions } from '@/utils/translationUtils';

interface FileUploadButtonProps {
    onFileSelect: (file: File) => void;
    accept?: string;
}

export const FileUploadButton: React.FC<FileUploadButtonProps> = ({ onFileSelect, accept = 'audio/*' }) => {
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const dragCounterRef = useRef(0);

    const translationOptions: TranslationOptions = { source: 'uiComponents' };

    const handleDragEnter = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        dragCounterRef.current++;
        setIsDragging(true);
    }, []);

    const handleDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        dragCounterRef.current--;
        if (dragCounterRef.current === 0) {
            setIsDragging(false);
        }
    }, []);

    const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const handleDrop = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        dragCounterRef.current = 0;

        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            onFileSelect(e.dataTransfer.files[0]);
        }
    }, [onFileSelect]);

    const handleClick = useCallback(() => {
        fileInputRef.current?.click();
    }, []);

    const handleFileInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            onFileSelect(e.target.files[0]);
        }
    }, [onFileSelect]);

    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <div
                        className={`walkthrough-fileuploadbutton-container relative rounded-full w-10 h-10 md:w-12 md:h-12 flex items-center justify-center cursor-pointer transition-all duration-300 ${
                            isDragging
                                ? 'walkthrough-fileuploadbutton-dragging bg-green-500 scale-110 shadow-lg'
                                : 'walkthrough-fileuploadbutton-idle bg-blue-500 hover:bg-blue-600 hover:scale-105'
                        }`}
                        onDragEnter={handleDragEnter}
                        onDragLeave={handleDragLeave}
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                        onClick={handleClick}
                    >
                        <FiUpload className={`walkthrough-fileuploadbutton-icon text-white text-xl md:text-2xl transition-all duration-300 ${
                            isDragging ? 'walkthrough-fileuploadbutton-icon-dragging animate-bounce' : ''
                        }`} />
                        <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileInput}
                            accept={accept}
                            className="walkthrough-fileuploadbutton-input hidden"
                        />
                        {isDragging && (
                            <div className="walkthrough-fileuploadbutton-dragoverlay absolute inset-0 border-4 border-white rounded-full animate-pulse"></div>
                        )}
                    </div>
                </TooltipTrigger>
                <TooltipContent className="walkthrough-fileuploadbutton-tooltip bg-gray-800 text-white px-3 py-2 rounded shadow-lg">
                    <p>{__('Click or drag and drop to upload audio', translationOptions)}</p>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
};