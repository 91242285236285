'use client';

import { useEffect, useState } from 'react';
import { create } from 'zustand';
import { FaComments, FaSync, FaClock } from 'react-icons/fa';
import { BsChatDots, BsChatDotsFill } from 'react-icons/bs';
import { __ } from '@/utils/translationUtils';
import { getEnv } from '@/lib/utils/env';

const COMMIT_HASH = getEnv('NEXT_PUBLIC_COMMIT_HASH') || '';

interface VersionStore {
    showUpdateDialog: boolean;
    setShowUpdateDialog: (show: boolean) => void;
}

const useVersionStore = create<VersionStore>((set) => ({
    showUpdateDialog: false,
    setShowUpdateDialog: (show) => set({ showUpdateDialog: show }),
}));

function UpdateDialog({ onRefresh, oldVersion, newVersion, onSkip }: {
    onRefresh: () => void,
    oldVersion: string,
    newVersion: string,
    onSkip: () => void
}) {
    const [isRefreshing, setIsRefreshing] = useState(false);

    const handleRefreshClick = () => {
        setIsRefreshing(true);
        onRefresh();
    };

    return (
        <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center z-[9999]">
            <div className="bg-white dark:bg-gray-800 p-8 rounded-xl shadow-2xl max-w-md mx-4 border border-gray-200 dark:border-gray-700">
                <div className="flex items-center gap-4 mb-6">
                    <div className="relative">
                        <BsChatDots className="w-8 h-8 text-gray-400 absolute" />
                        <BsChatDotsFill className="w-8 h-8 text-blue-500 animate-pulse" />
                    </div>
                    <h2 className="text-2xl font-semibold dark:text-white">{__('New Version Available!')}</h2>
                </div>

                <div className="space-y-4 mb-6">
                    <div className="flex items-center gap-3">
                        <p className="text-gray-600 dark:text-gray-300">
                            {__('Just like being stuck on an old topic while the conversation has moved on, your app needs to catch up with the latest version.')}
                        </p>
                    </div>
                    <div className="flex items-center gap-3">
                        <p className="text-gray-600 dark:text-gray-300">
                            {__('A quick refresh will update your app and keep you in sync with all the latest features and improvements!')}
                        </p>
                    </div>
                </div>

                <div className="flex flex-col gap-3">
                    <button
                        onClick={handleRefreshClick}
                        className="w-full bg-blue-500 hover:bg-blue-600 text-white font-medium py-3 px-4 rounded-lg transition-all transform hover:scale-[1.02] flex items-center justify-center gap-2"
                    >
                        <FaSync className={`w-4 h-4 ${isRefreshing ? 'animate-spin' : ''}`} />
                        {__('Update Now')}
                    </button>
                    <button
                        onClick={onSkip}
                        className="w-full bg-gray-100 hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-200 font-medium py-3 px-4 rounded-lg transition-all flex items-center justify-center gap-2 text-sm"
                    >
                        <FaClock className="w-4 h-4" />
                        {__('Give me a moment to finish my thought')}
                    </button>
                </div>
            </div>
        </div>
    );
}

export function VersionCheckerProvider({ children }: { children: React.ReactNode }) {
    const { showUpdateDialog, setShowUpdateDialog } = useVersionStore();
    const [currentVersion, setCurrentVersion] = useState(COMMIT_HASH);

    useEffect(() => {
        if (!COMMIT_HASH) {
            console.warn('[Version Checker] Commit hash not available, skipping version check.');
            return;
        }

        const checkVersion = async () => {
            try {
                const response = await fetch('/api/version');
                const { version } = await response.json();

                if (version && version !== currentVersion) {
                    setShowUpdateDialog(true);
                }
            } catch (error) {
                console.error('[Version Checker] Failed to check version:', error);
            }
        };

        // Check immediately and then every 15 seconds
        checkVersion();
        const interval = setInterval(checkVersion, 15 * 1000);

        return () => clearInterval(interval);
    }, [currentVersion, setShowUpdateDialog]);

    const handleRefresh = () => {
        window.location.reload();
    };

    const handleSkip = () => {
        setShowUpdateDialog(false);
        setTimeout(() => setShowUpdateDialog(true), 5000);
    };

    return (
        <>
            {showUpdateDialog &&
                <UpdateDialog
                    onRefresh={handleRefresh}
                    oldVersion={currentVersion}
                    newVersion={COMMIT_HASH}
                    onSkip={handleSkip}
                />}
            {children}
        </>
    );
}
