'use client';

import React, { createContext, useContext, useCallback, useEffect } from 'react';
import { eventBus } from '@/utils/EventBus';
import { EVENTS } from '@/constants/events';

export const SHORTCUT_ACTIONS = {
    START_OR_STOP_RECORDING: EVENTS.START_OR_STOP_RECORDING,
} as const;

type ShortcutAction = typeof SHORTCUT_ACTIONS[keyof typeof SHORTCUT_ACTIONS];

interface ShortcutsContextType {
    triggerShortcut: (action: ShortcutAction) => void;
}

const ShortcutsContext = createContext<ShortcutsContextType | undefined>(undefined);

export const ShortcutsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const handleShortcut = useCallback((event: ShortcutAction) => {
        eventBus.emit(event);
    }, []);

    useEffect(() => {
        const shortcutHandler = (e: KeyboardEvent) => {
            if ((e.altKey && e.key === 'h') || (e.metaKey && e.key === 'h') || (e.altKey && e.key === 'r')) {
                console.log('Shortcut triggered: START_OR_STOP_RECORDING');
                handleShortcut(SHORTCUT_ACTIONS.START_OR_STOP_RECORDING);
            }
        };

        window.addEventListener('keydown', shortcutHandler);
        return () => {
            window.removeEventListener('keydown', shortcutHandler);
        };
    }, [handleShortcut]);

    const triggerShortcut = useCallback((action: ShortcutAction) => {
        handleShortcut(action);
    }, [handleShortcut]);

    return (
        <ShortcutsContext.Provider value={{ triggerShortcut }}>
            {children}
        </ShortcutsContext.Provider>
    );
};

export const useShortcuts = () => {
    const context = useContext(ShortcutsContext);
    if (context === undefined) {
        throw new Error('useShortcuts must be used within a ShortcutsProvider');
    }
    return context;
};