'use client';
import { useState, useCallback, useEffect } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';

export function useNavigationLoadingState() {
  const [isNavigating, setIsNavigating] = useState(false);
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    setIsNavigating(false);
  }, [pathname, searchParams]);

  const startNavigation = useCallback(() => {
    setIsNavigating(true);
  }, []);

  return { isNavigating, startNavigation };
}