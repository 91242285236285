// Start of Selection
'use client';

import React, {Fragment, useEffect, useState} from 'react';
import {signOut, useSession} from 'next-auth/react';
import Link from 'next/link';
import {useTheme} from 'next-themes';
import {
    FiBook,
    FiChevronLeft,
    FiChevronRight,
    FiDollarSign,
    FiFileText,
    FiLogOut,
    FiMessageSquare,
    FiMoon,
    FiMoreHorizontal,
    FiSun,
    FiTag,
    FiUsers,
    FiSettings,
    FiUser,
    FiBarChart2,
    FiMic
} from 'react-icons/fi';
import {PiTranslateBold} from 'react-icons/pi';
import {User} from '@/types/api';
import {useCredits} from '@/contexts/CreditsContext';
import {twMerge} from 'tailwind-merge';
import FeedbackForm from './FeedbackForm';
import {usePathname, useRouter} from 'next/navigation';
import {motion} from 'framer-motion';
import {usePermissions} from '@/contexts/PermissionsContext';
import {useNavigationLoadingState} from "@/hooks/useNavigationLoadingState";
import {
    Provider as TooltipProvider,
    Root as TooltipRoot,
    Trigger as TooltipTrigger,
    Portal as TooltipPortal,
    Content as TooltipContent,
    Arrow as TooltipArrow
} from '@radix-ui/react-tooltip';
import {
    Root as PopoverRoot,
    Trigger as PopoverTrigger,
    Portal as PopoverPortal,
    Content as PopoverContent,
    Arrow as PopoverArrow
} from '@radix-ui/react-popover';
import NoteTakerButton from './NoteTakerButton';
import {useAudioStore} from '@/stores/audioStore';
import {eventBus} from '@/utils/EventBus';
import {EVENTS} from "@/constants/events";
import {useNoteTaker} from '@/contexts/NoteTakerContext';
import {Mic} from 'lucide-react';
import {__, translationSources} from '@/utils/translationUtils';
import LocaleSwitcher from './LocaleSwitcher';
import {getEnv} from '@/lib/utils/env';

const Tooltip = ({children, content}: { children: React.ReactNode; content: string }) => (
    <TooltipProvider>
        <TooltipRoot>
            <TooltipTrigger asChild>{children}</TooltipTrigger>
            <TooltipPortal>
                <TooltipContent
                    className={twMerge(
                        "walkthrough-sidebar-tooltip bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 px-4 py-2 rounded-md shadow-md text-sm"
                    )}
                    sideOffset={5}
                >
                    {content}
                    <TooltipArrow className="fill-current text-white dark:text-gray-800"/>
                </TooltipContent>
            </TooltipPortal>
        </TooltipRoot>
    </TooltipProvider>
);

const SidebarLink = ({href, icon, children, className, tooltip, isCollapsed, isActive, onClick}: {
    href: string;
    icon: React.ReactNode;
    children: React.ReactNode;
    className?: string;
    tooltip?: string;
    isCollapsed: boolean;
    isActive: boolean;
    onClick?: () => void;
}) => {
    const router = useRouter();
    const {isNavigating, startNavigation} = useNavigationLoadingState();

    const content = (
        <Link
            href={href}
            onClick={(e) => {
                e.preventDefault();
                onClick?.();
                if (!isNavigating) {
                    startNavigation();
                    router.push(href);
                }
            }}
            className="walkthrough-sidebar-link sidebar-link w-full"
        >
            <div
                className={twMerge(
                    "walkthrough-sidebar-link-content sidebar-link-content flex cursor-pointer rounded-lg transform transition-transform active:scale-95 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50 w-full h-full",
                    isCollapsed ? "sidebar-link-collapsed flex-col items-center justify-center p-2" : "sidebar-link-expanded flex-row items-center p-2",
                    isActive ? "sidebar-link-active font-bold text-indigo-700 dark:text-indigo-200" : "sidebar-link-inactive text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700",
                    isNavigating && "sidebar-link-navigating opacity-50 cursor-not-allowed",
                    className
                )}
            >
                {React.cloneElement(icon as React.ReactElement, {
                    size: 20,
                    className: twMerge(
                        isActive ? "text-indigo-600 dark:text-indigo-400" : "text-gray-500 dark:text-gray-400",
                        isNavigating && "animate-slow-pulse"
                    )
                })}
                {!isCollapsed && (
                    <span
                        className={twMerge(
                            "text-xs md:text-base font-medium whitespace-nowrap overflow-hidden text-ellipsis",
                            isActive && "font-semibold",
                            isNavigating && "animate-slow-pulse",
                            "ml-4"
                        )}
                    >
            {children}
          </span>
                )}
            </div>
        </Link>
    );

    if (isCollapsed && tooltip) {
        return <Tooltip content={tooltip}>{content}</Tooltip>;
    }

    return content;
};

const ThemeToggle = ({className, isCollapsed}: {
    className?: string;
    isCollapsed: boolean;
}) => {
    const {theme, resolvedTheme, setTheme} = useTheme();
    const [mounted, setMounted] = useState(false);
    const isDark = (resolvedTheme || theme) === 'dark';

    useEffect(() => {
        setMounted(true);
    }, []);

    if (!mounted) {
        return null;
    }

    const toggleButton = (
        <motion.button
            className={twMerge(
                "walkthrough-sidebar-theme-toggle theme-toggle relative w-14 h-8 rounded-full bg-gray-200 dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500",
                className
            )}
            onClick={() => setTheme(isDark ? 'light' : 'dark')}
            aria-label="Toggle theme"
            whileTap={{scale: 0.95}}
        >
            <motion.div
                className="theme-toggle-slider absolute left-1 top-1 w-6 h-6 rounded-full bg-white shadow-md flex items-center justify-center"
                animate={{
                    x: isDark ? 24 : 0,
                    rotate: isDark ? 0 : 180,
                }}
                transition={{type: "spring", stiffness: 300, damping: 30}}
            >
                {isDark ? (
                    <FiMoon className="text-indigo-500" size={16}/>
                ) : (
                    <FiSun className="text-yellow-500" size={16}/>
                )}
            </motion.div>
            <span className="sr-only">
        {isDark
            ? __('Switch to light mode', {source: translationSources.common})
            : __('Switch to dark mode', {source: translationSources.common})}
      </span>
        </motion.button>
    );

    if (isCollapsed) {
        return (
            <Tooltip
                content={
                    isDark
                        ? __('Light Mode', {source: translationSources.common})
                        : __('Dark Mode', {source: translationSources.common})
                }
            >
                {toggleButton}
            </Tooltip>
        );
    }

    return toggleButton;
};

const LogoutButton = ({handleLogout, className, isCollapsed}: {
    handleLogout: () => void;
    className?: string;
    isCollapsed: boolean;
}) => {
    const button = (
        <motion.button
            onClick={handleLogout}
            className={twMerge(
                "walkthrough-sidebar-logout-button logout-button flex items-center space-x-2 p-2 rounded-lg text-red-600 hover:bg-red-100 dark:hover:bg-red-900 transition-all duration-300 w-full",
                isCollapsed && "logout-button-collapsed justify-center",
                className
            )}
            whileTap={{scale: 0.95}}
            whileHover={{backgroundColor: "rgba(254, 202, 202, 0.2)"}}
        >
            <FiLogOut/>
            {!isCollapsed && <span>{__("Log Out", {source: translationSources.auth})}</span>}
        </motion.button>
    );

    if (isCollapsed) {
        return <Tooltip content={__("Log Out", {source: translationSources.auth})}>{button}</Tooltip>;
    }

    return button;
};

const UserInfo = ({user, availableMinutes, className, isCollapsed}: {
    user: User | null;
    availableMinutes: number | null;
    className?: string;
    isCollapsed: boolean;
}) => {
    const {data: session} = useSession();
    const currentUser = session?.user || user;

    return (
        <Link
            href="/s/profile"
            className={twMerge(
                "walkthrough-sidebar-user-info user-info flex items-center space-x-2 text-sm text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg p-2 transition-colors duration-200",
                isCollapsed && "user-info-collapsed justify-center",
                className
            )}
        >
            <div className="w-8 h-8 flex-shrink-0">
                <img
                    src={currentUser?.image || `https://ui-avatars.com/api/?name=${currentUser?.firstName}+${currentUser?.lastName}`}
                    alt="User avatar"
                    className="user-avatar w-full h-full rounded-full object-cover"
                />
            </div>
            {!isCollapsed && (
                <span className="user-info-details">
          {currentUser?.firstName || __('User', {source: translationSources.common})}
                    {availableMinutes !== null && <span className="user-credits"> ({availableMinutes}m)</span>}
        </span>
            )}
        </Link>
    );
};

const MoreOptions = ({isCollapsed}: { isCollapsed: boolean }) => {
    const [open, setOpen] = useState(false);
    const {permissions} = usePermissions();

    const handleOptionClick = () => {
        setOpen(false);
    };

    return (
        <PopoverRoot open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <button
                    className={twMerge(
                        "walkthrough-sidebar-more-options more-options-button flex items-center p-2 rounded-lg text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 transition-all duration-300 w-full active:bg-gray-200 dark:active:bg-gray-600",
                        isCollapsed ? "justify-center" : "space-x-2"
                    )}
                >
                    <FiMoreHorizontal size={20}/>
                    {!isCollapsed && <span>{__("More", {source: translationSources.common})}</span>}
                </button>
            </PopoverTrigger>
            <PopoverPortal>
                <PopoverContent className="walkthrough-sidebar-more-options-content z-50 mt-2 sm:px-0 lg:max-w-3xl"
                                sideOffset={5}>
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 shadow-md">
                        <div
                            className="relative flex flex-col gap-2 bg-white dark:bg-gray-800 p-4 w-auto min-w-[12rem] max-w-[calc(100vw-2rem)]">
                            <SidebarLink
                                href="/s/transactions"
                                icon={<FiDollarSign/>}
                                isCollapsed={false}
                                isActive={false}
                                onClick={handleOptionClick}
                                className="walkthrough-sidebar-transactions more-options-transactions"
                            >
                                {__("Transactions", {source: translationSources.core})}
                            </SidebarLink>
                            <SidebarLink
                                href="/changelog"
                                icon={<FiFileText/>}
                                isCollapsed={false}
                                isActive={false}
                                onClick={handleOptionClick}
                                className="walkthrough-sidebar-changelog more-options-changelog"
                            >
                                {__("Changelog", {source: translationSources.common})}
                            </SidebarLink>
                            <SidebarLink
                                href="/manifesto"
                                icon={<FiFileText/>}
                                isCollapsed={false}
                                isActive={false}
                                onClick={handleOptionClick}
                                className="walkthrough-sidebar-manifesto more-options-manifesto"
                            >
                                {__("Manifesto", {source: translationSources.common})}
                            </SidebarLink>
                            {permissions?.canSeeAllUsers && (
                                <SidebarLink
                                    href="/s/admin/users"
                                    icon={<FiUsers/>}
                                    isCollapsed={false}
                                    isActive={false}
                                    onClick={handleOptionClick}
                                    className="walkthrough-sidebar-user-management more-options-user-management"
                                >
                                    {__("User Management", {source: translationSources.admin})}
                                </SidebarLink>
                            )}
                            {permissions?.canAccessAdminDashboard && (
                                <SidebarLink
                                    href="/s/admin/dashboard"
                                    icon={<FiBarChart2/>}
                                    isCollapsed={false}
                                    isActive={false}
                                    onClick={handleOptionClick}
                                    className="walkthrough-sidebar-admin-dashboard more-options-admin-dashboard"
                                >
                                    {__("Admin Dashboard", {source: translationSources.admin})}
                                </SidebarLink>
                            )}
                        </div>
                    </div>
                    <PopoverArrow className="fill-current text-white dark:text-gray-800"/>
                </PopoverContent>
            </PopoverPortal>
        </PopoverRoot>
    );
};

const LogoutConfirmation = ({onCancel, onConfirm}: { onCancel: () => void; onConfirm: () => void }) => (
    <div
        className="walkthrough-sidebar-logout-confirmation fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50">
        <div className="bg-white dark:bg-gray-800 p-8 rounded-2xl shadow-2xl max-w-md w-full mx-4">
            <h2 className="text-3xl font-bold mb-6 text-gray-900 dark:text-gray-100">
                {__("Confirm Logout", {source: translationSources.auth})}
            </h2>
            <p className="mb-8 text-lg text-gray-600 dark:text-gray-300">
                {__("Are you sure you want to log out? We'll miss you!", {source: translationSources.auth})}
            </p>
            <div className="flex justify-end space-x-4">
                <motion.button
                    onClick={onCancel}
                    className="walkthrough-sidebar-logout-cancel logout-cancel-button px-6 py-3 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300 transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50"
                    whileTap={{scale: 0.95}}
                >
                    {__("Cancel", {source: translationSources.common})}
                </motion.button>
                <motion.button
                    onClick={onConfirm}
                    className="walkthrough-sidebar-logout-confirm logout-confirm-button px-6 py-3 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-50"
                    whileTap={{scale: 0.95}}
                >
                    {__("Logout", {source: translationSources.auth})}
                </motion.button>
            </div>
        </div>
    </div>
);

export const DesktopSidebar = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const {theme, setTheme} = useTheme();
    const {data: session, status} = useSession();
    const {credits} = useCredits();
    const availableMinutes = credits !== null ? Math.floor(credits / 60) : null;
    const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
    const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
    const pathname = usePathname();
    const {permissions} = usePermissions();

    const toggleSidebar = () => setIsCollapsed(!isCollapsed);

    const handleLogout = () => setShowLogoutConfirmation(true);

    const confirmLogout = () => {
        signOut();
        setShowLogoutConfirmation(false);
    };

    const cancelLogout = () => setShowLogoutConfirmation(false);

    const renderContent = () => (
        <nav className={twMerge("mt-8 space-y-2", isCollapsed && "flex flex-col items-center")}>
            <SidebarLink
                href="/s/notes"
                icon={<FiBook/>}
                tooltip={__("Notes", {source: translationSources.uiComponents})}
                isCollapsed={isCollapsed}
                isActive={pathname === '/s/notes'}
                className="walkthrough-sidebar-notes sidebar-notes-link"
            >
                {__("Notes", {source: translationSources.uiComponents})}
            </SidebarLink>
            <SidebarLink
                href="/s/dashboard"
                icon={<FiBarChart2/>}
                tooltip={__("Dashboard", {source: translationSources.uiComponents})}
                isCollapsed={isCollapsed}
                isActive={pathname === '/s/dashboard'}
                className="walkthrough-sidebar-dashboard sidebar-dashboard-link"
            >
                {__("Dashboard", {source: translationSources.uiComponents})}
            </SidebarLink>
            <SidebarLink
                href="/s/conversation-mode"
                icon={<PiTranslateBold/>}
                tooltip={__("Conversation (alpha)", {source: translationSources.uiComponents})}
                isCollapsed={isCollapsed}
                isActive={pathname === '/s/conversation-mode'}
                className="walkthrough-sidebar-conversation sidebar-conversation-link"
            >
                {__("Conversation (alpha)", {source: translationSources.uiComponents})}
            </SidebarLink>
            <SidebarLink
                href="/credits"
                icon={<FiTag/>}
                tooltip={__("Subscriptions", {source: translationSources.core})}
                isCollapsed={isCollapsed}
                isActive={pathname === '/credits'}
                className="walkthrough-sidebar-pricing more-options-pricing"
            >
                {__("Credits", {source: translationSources.core})}
            </SidebarLink>
            <MoreOptions isCollapsed={isCollapsed}/>
        </nav>
    );

    const renderFooter = () => (
        <div className={twMerge("p-4 space-y-4", isCollapsed && "flex flex-col items-center")}>
            {status === 'authenticated' && (
                <UserInfo user={session.user as User} availableMinutes={availableMinutes} isCollapsed={isCollapsed}/>
            )}
            {!isCollapsed && (
                <ThemeToggle
                    className="walkthrough-sidebar-theme-toggle desktop-sidebar-theme-toggle p-2"
                    isCollapsed={isCollapsed}
                />
            )}
            <div className={twMerge("flex flex-col gap-2", isCollapsed && "items-center")}>
                <TooltipProvider>
                    <TooltipRoot>
                        <TooltipTrigger asChild>
                            <motion.button
                                onClick={() => setIsFeedbackOpen(true)}
                                className={twMerge(
                                    "walkthrough-sidebar-feedback feedback-button flex items-center space-x-2 p-2 rounded-lg text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 transition-all duration-300 w-full",
                                    isCollapsed && "justify-center"
                                )}
                                whileTap={{scale: 0.95}}
                                whileHover={{backgroundColor: "rgba(229, 231, 235, 0.2)"}}
                            >
                                <FiMessageSquare/>
                                {!isCollapsed && <span>{__("Feedback", {source: translationSources.core})}</span>}
                            </motion.button>
                        </TooltipTrigger>
                        <TooltipPortal>
                            <TooltipContent
                                className="bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 px-4 py-2 rounded-md shadow-md text-sm"
                                sideOffset={5}
                            >
                                {isCollapsed ? __("Provide Feedback", {source: translationSources.core}) : ""}
                                <TooltipArrow className="fill-current text-white dark:text-gray-800"/>
                            </TooltipContent>
                        </TooltipPortal>
                    </TooltipRoot>
                </TooltipProvider>
                <SidebarLink
                    href="/s/settings"
                    icon={<FiSettings/>}
                    tooltip={__("Settings", {source: translationSources.core})}
                    isCollapsed={isCollapsed}
                    isActive={pathname === '/s/settings'}
                    className="walkthrough-sidebar-settings sidebar-settings-link"
                >
                    {__("Settings", {source: translationSources.core})}
                </SidebarLink>
                {status === 'authenticated' && (
                    <LogoutButton
                        handleLogout={handleLogout}
                        className={twMerge(isCollapsed && "justify-center p-2")}
                        isCollapsed={isCollapsed}
                    />
                )}
                <LocaleSwitcher/>
            </div>
        </div>
    );

    return (
        <>
            <div
                className={twMerge(
                    "walkthrough-sidebar-container desktop-sidebar h-screen bg-white dark:bg-gray-800 border-r border-gray-200 dark:border-gray-700 flex flex-col justify-between overflow-hidden transition-all duration-300 ease-in-out",
                    isCollapsed ? 'desktop-sidebar-collapsed w-16' : 'desktop-sidebar-expanded w-64'
                )}
            >
                <div className="desktop-sidebar-content">
                    <div
                        className="walkthrough-sidebar-header desktop-sidebar-header p-4 flex items-center justify-between">
                        <Link href="/" className="walkthrough-sidebar-logo desktop-sidebar-logo flex-shrink-0">
                            <div className="logo-container h-8 w-8 rounded-full overflow-hidden">
                                <img className="logo-image h-full w-full object-cover" src="/images/logo-128x128.png"
                                     alt="Logo"/>
                            </div>
                        </Link>
                        {!isCollapsed && (
                            <>
                                <h1 className="walkthrough-sidebar-app-name app-name text-xl font-bold text-indigo-600 dark:text-indigo-400 transition-opacity duration-300">
                                    {getEnv('NEXT_PUBLIC_APP_NAME')}
                                </h1>
                                <ThemeToggle
                                    className="walkthrough-sidebar-theme-toggle desktop-sidebar-theme-toggle p-2"
                                    isCollapsed={isCollapsed}
                                />
                            </>
                        )}
                    </div>
                    {renderContent()}
                </div>
                <div className="desktop-sidebar-footer mt-auto">
                    {renderFooter()}
                    <button
                        onClick={toggleSidebar}
                        className="walkthrough-sidebar-toggle sidebar-toggle w-full p-4 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 transition-all duration-300 flex justify-end border-t border-gray-200 dark:border-gray-700"
                    >
                        {isCollapsed ? <FiChevronRight size={24}/> : <FiChevronLeft size={24}/>}
                    </button>
                </div>
            </div>
            {showLogoutConfirmation && (
                <LogoutConfirmation onCancel={cancelLogout} onConfirm={confirmLogout}/>
            )}
            <FeedbackForm isOpen={isFeedbackOpen} onClose={() => setIsFeedbackOpen(false)}/>
        </>
    );
};

const MobilePopover = ({renderContent, className}: {
    renderContent: (close: () => void) => React.ReactNode;
    className?: string
}) => {
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <PopoverRoot open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <button
                    className={twMerge(
                        "walkthrough-sidebar-mobile-more-options mobile-more-options flex flex-col items-center justify-center p-2 text-[10px] text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 transition-all duration-300 focus:outline-none rounded-lg active:bg-gray-200 dark:active:bg-gray-600",
                        className
                    )}
                >
                    <FiMoreHorizontal size={16}/>
                </button>
            </PopoverTrigger>
            <PopoverPortal>
                <PopoverContent
                    className="walkthrough-sidebar-mobile-popover mobile-menu-more-options-popover fixed z-50 w-[calc(60vw)] max-w-sm bottom-2 right-0.5"
                    sideOffset={5}
                    align="end"
                >
                    {renderContent(handleClose)}
                    <PopoverArrow className="fill-current text-white dark:text-gray-800"/>
                </PopoverContent>
            </PopoverPortal>
        </PopoverRoot>
    );
};

export const MobileNavbar = () => {
    const {theme, setTheme} = useTheme();
    const {data: session, status} = useSession();
    const {credits} = useCredits();
    const availableMinutes = credits !== null ? Math.floor(credits / 60) : null;
    const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
    const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
    const pathname = usePathname();
    const {permissions} = usePermissions();
    const {recordingState} = useAudioStore();
    const {toggleExpanded} = useNoteTaker();

    const handleLogout = () => setShowLogoutConfirmation(true);

    const confirmLogout = () => {
        signOut();
        setShowLogoutConfirmation(false);
    };

    const cancelLogout = () => setShowLogoutConfirmation(false);

    const renderMobilePopoverContent = (close: () => void) => (
        <div
            className="walkthrough-sidebar-mobile-popover-content bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden">
            <div className="p-4 space-y-3 max-h-[60vh] overflow-y-auto">
                {status === 'authenticated' && (
                    <Link href="/s/profile" className="block w-full" onClick={close}>
                        <UserInfo user={session.user as User} availableMinutes={availableMinutes} isCollapsed={false}
                                  className="w-full"/>
                    </Link>
                )}
                <ThemeToggle
                    className="walkthrough-sidebar-theme-toggle desktop-sidebar-theme-toggle p-2"
                    isCollapsed={false}
                />
                <SidebarLink
                    href="/pricing"
                    icon={<FiTag/>}
                    isCollapsed={false}
                    isActive={pathname === '/pricing'}
                    onClick={close}
                    className="walkthrough-sidebar-mobile-pricing mobile-pricing-link"
                >
                    {__("Subscriptions", {source: translationSources.core})}
                </SidebarLink>
                <SidebarLink
                    href="/s/transactions"
                    icon={<FiDollarSign/>}
                    isCollapsed={false}
                    isActive={pathname === '/s/transactions'}
                    onClick={close}
                    className="walkthrough-sidebar-mobile-transactions mobile-transactions-link"
                >
                    {__("Transactions", {source: translationSources.core})}
                </SidebarLink>
                <SidebarLink
                    href="/changelog"
                    icon={<FiFileText/>}
                    isCollapsed={false}
                    isActive={pathname === '/changelog'}
                    onClick={close}
                    className="walkthrough-sidebar-mobile-changelog mobile-changelog-link"
                >
                    {__("Changelog", {source: translationSources.common})}
                </SidebarLink>
                <SidebarLink
                    href="/manifesto"
                    icon={<FiFileText/>}
                    isCollapsed={false}
                    isActive={pathname === '/manifesto'}
                    onClick={close}
                    className="walkthrough-sidebar-mobile-manifesto mobile-manifesto-link"
                >
                    {__("Manifesto", {source: translationSources.common})}
                </SidebarLink>
                {permissions?.canSeeAllUsers && (
                    <SidebarLink
                        href="/s/admin/users"
                        icon={<FiUsers/>}
                        isCollapsed={false}
                        isActive={pathname === '/s/admin/users'}
                        onClick={close}
                        className="walkthrough-sidebar-mobile-user-management mobile-user-management-link"
                    >
                        {__("User Management", {source: translationSources.core})}
                    </SidebarLink>
                )}
                {permissions?.canAccessAdminDashboard && (
                    <SidebarLink
                        href="/s/admin/dashboard"
                        icon={<FiBarChart2/>}
                        isCollapsed={false}
                        isActive={pathname === '/s/admin/dashboard'}
                        onClick={close}
                        className="walkthrough-sidebar-mobile-admin-dashboard mobile-admin-dashboard-link"
                    >
                        {__("Admin Dashboard", {source: translationSources.core})}
                    </SidebarLink>
                )}
                <SidebarLink
                    href="/s/conversation-mode"
                    icon={<PiTranslateBold/>}
                    isCollapsed={false}
                    isActive={pathname === '/s/conversation-mode'}
                    onClick={close}
                    className="mobile-conversation-link"
                >
                    {__("Conversation (alpha)", {source: translationSources.uiComponents})}
                </SidebarLink>
                <SidebarLink
                    href="#"
                    icon={<FiMessageSquare/>}
                    isCollapsed={false}
                    isActive={false}
                    onClick={() => {
                        setIsFeedbackOpen(true);
                        close();
                    }}
                    className="mobile-feedback-link"
                >
                    {__("Feedback", {source: translationSources.core})}
                </SidebarLink>
                {status === 'authenticated' && (
                    <>
                        <SidebarLink
                            href="/s/settings"
                            icon={<FiSettings/>}
                            isCollapsed={false}
                            isActive={pathname === '/s/settings'}
                            onClick={close}
                        >
                            {__("Settings", {source: translationSources.core})}
                        </SidebarLink>
                        <LogoutButton
                            handleLogout={() => {
                                handleLogout();
                                close();
                            }}
                            isCollapsed={false}
                            className="w-full"
                        />
                        <LocaleSwitcher/>
                    </>
                )}
            </div>
        </div>
    );

    return (
        <>
            <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 z-50">
                <NoteTakerButton className="mobile-navbar-link note-taker-button" size={60}/>
            </div>
            <nav
                className="mobile-navbar fixed bottom-0 left-0 right-0 flex justify-around items-stretch w-full px-2 py-2 bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700">
                <div className="w-16"></div>
                <SidebarLink
                    href="/s/notes"
                    icon={<FiBook/>}
                    isCollapsed={true}
                    isActive={pathname === '/s/notes'}
                    tooltip={__("Notes", {source: translationSources.uiComponents})}
                    className="mobile-navbar-link mobile-notes-link flex-1"
                >
                    <span
                        className="mobile-navbar-link-text">{__("Notes", {source: translationSources.uiComponents})}</span>
                </SidebarLink>
                <div className="flex-1"></div>
                <SidebarLink
                    href="/s/dashboard"
                    icon={<FiBarChart2/>}
                    isCollapsed={true}
                    isActive={pathname === '/s/dashboard'}
                    tooltip={__("Dashboard", {source: translationSources.uiComponents})}
                    className="mobile-navbar-link mobile-dashboard-link flex-1"
                >
                    <span
                        className="mobile-navbar-link-text">{__("Dashboard", {source: translationSources.uiComponents})}</span>
                </SidebarLink>
                <MobilePopover renderContent={renderMobilePopoverContent} className="mobile-more-options"/>
            </nav>
            {showLogoutConfirmation && (
                <LogoutConfirmation onCancel={cancelLogout} onConfirm={confirmLogout}/>
            )}
            <FeedbackForm isOpen={isFeedbackOpen} onClose={() => setIsFeedbackOpen(false)}/>
        </>
    );
};
// End of Selectio