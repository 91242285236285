import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/app/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/ClientErrorBoundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClientProviders"] */ "/app/src/components/ClientProviders.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/CustomToaster.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/ErrorTrigger.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/GoogleAnalytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/InstallPrompt.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/LocaleSwitcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/NavBar.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/NewRelicMonitoring.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/NoteTaker.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/PostHogPageView.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/record-anywhere/RecordAnywhere.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RedirectToNotes"] */ "/app/src/components/RedirectToNotes.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DesktopSidebar","MobileNavbar"] */ "/app/src/components/Sidebar.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/WalkthroughController.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FocusProvider"] */ "/app/src/contexts/FocusContext.tsx");
