'use client';

import React, { createContext, useContext, useState, useCallback, useRef, useEffect } from 'react';
import { RecordingState } from '@/constants/events';
import { setupAudioRecording, pauseRecording, resumeRecording, getCurrentDuration } from '@/utils/audioRecordingUtils';
import { create } from 'zustand';
import { isMobile, isIOS } from '@/utils/deviceDetection';

interface AudioRecordingStore {
  recordingState: RecordingState;
  setRecordingState: (state: RecordingState) => void;
}

const useAudioRecordingStore = create<AudioRecordingStore>((set) => ({
  recordingState: RecordingState.IDLE,
  setRecordingState: (state) => set({ recordingState: state }),
}));

interface AudioRecordingContextType {
  recordingId: string;
  recordingState: RecordingState;
  accumulatedDuration: number;
  currentRecordingStartTime: number | null;
  audioBlob: Blob | null;
  analyserNode: AnalyserNode | null;
  audioContext: AudioContext | null;
  mediaRecorder: MediaRecorder | null;
  stream: MediaStream | null;
  startRecording: () => void;
  stopRecording: () => Promise<Blob | null>;
  pauseRecording: () => void;
  resumeRecording: () => void;
  cancelRecording: () => void;
  getCurrentDuration: () => number;
  setRecordingState: (state: RecordingState) => void;
}

const AudioRecordingContext = createContext<AudioRecordingContextType | undefined>(undefined);

export const AudioRecordingProvider: React.FC<{ children: React.ReactNode; recordingId: string }> = ({ children, recordingId }) => {
  const [recordingState, setRecordingState] = useState<RecordingState>(RecordingState.IDLE);
  const [accumulatedDuration, setAccumulatedDuration] = useState(0);
  const [currentRecordingStartTime, setCurrentRecordingStartTime] = useState<number | null>(null);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const [analyserNode, setAnalyserNode] = useState<AnalyserNode | null>(null);
  const [audioContext, setAudioContext] = useState<AudioContext | null>(null);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const [stream, setStream] = useState<MediaStream | null>(null);

  const audioContextRef = useRef<AudioContext | null>(null);
  const chunksRef = useRef<Blob[]>([]);
  const wakeLockRef = useRef<WakeLockSentinel | null>(null);

  const { setRecordingState: setStoreRecordingState } = useAudioRecordingStore();

  useEffect(() => {
    setStoreRecordingState(recordingState);
  }, [recordingState, setStoreRecordingState]);

  const resetContext = useCallback(() => {
    setRecordingState(RecordingState.IDLE);
    setAccumulatedDuration(0);
    setCurrentRecordingStartTime(null);
    setAudioBlob(null);
    setAnalyserNode(null);
    setMediaRecorder(null);
    setStream(null);
  }, []);

  const acquireWakeLock = async () => {
    if ('wakeLock' in navigator && !wakeLockRef.current) {
      try {
        wakeLockRef.current = await navigator.wakeLock.request('screen');
      } catch (err) {
        console.error('Failed to acquire wake lock:', err);
      }
    }
  };

  const releaseWakeLock = () => {
    if (wakeLockRef.current) {
      wakeLockRef.current.release()
        .then(() => {
          wakeLockRef.current = null;
        })
        .catch((err) => {
          console.error('Failed to release wake lock:', err);
        });
    }
  };

  const startRecording = useCallback(() => {
    const handleDataAvailable = (data: Blob) => {
      if (data.size > 0) {
        chunksRef.current.push(data);
      }
    };

    setupAudioRecording({
      onDataAvailable: handleDataAvailable,
      sampleRate: 48000,
      isIOS: isIOS()
    })
      .then(({ stream, analyserNode, mediaRecorder, audioContext }) => {
        audioContextRef.current = audioContext;
        setAudioContext(audioContext);
        setAnalyserNode(analyserNode);
        setMediaRecorder(mediaRecorder);
        setStream(stream);

        mediaRecorder.onstop = () => {
          const blob = new Blob(chunksRef.current, { type: mediaRecorder.mimeType });
          setAudioBlob(blob);
          chunksRef.current = [];
        };

        mediaRecorder.start(100);
        setRecordingState(RecordingState.RECORDING);
        setCurrentRecordingStartTime(Date.now());

        acquireWakeLock();
      })
      .catch(err => {
        console.error('Error accessing microphone:', err);
        alert(`Error accessing microphone: ${err.message}`);
      });
  }, []);

  const stopRecording = useCallback((): Promise<Blob | null> => {
    return new Promise((resolve) => {
      if (mediaRecorder && mediaRecorder.state !== 'inactive') {
        mediaRecorder.onstop = () => {
          const blob = new Blob(chunksRef.current, {
            type: isMobile() ? 'audio/mp4' : 'audio/webm;codecs=opus'
          });
          setAudioBlob(blob);
          chunksRef.current = [];

          if (stream) {
            stream.getTracks().forEach(track => track.stop());
          }

          setStream(null);
          setMediaRecorder(null);
          setRecordingState(RecordingState.IDLE);
          releaseWakeLock();
          resolve(blob);
        };
        mediaRecorder.stop();
      } else {
        resolve(null);
      }
    });
  }, [mediaRecorder, stream]);

  const pauseRecordingFn = useCallback(() => {
    pauseRecording(
      mediaRecorder,
      recordingState,
      setRecordingState,
      currentRecordingStartTime,
      accumulatedDuration,
      setAccumulatedDuration,
      setCurrentRecordingStartTime
    );

    if (mediaRecorder && mediaRecorder.state === 'recording') {
      mediaRecorder.pause();
      releaseWakeLock();
    }
  }, [mediaRecorder, recordingState, currentRecordingStartTime, accumulatedDuration]);

  const resumeRecordingFn = useCallback(() => {
    resumeRecording(
      mediaRecorder,
      recordingState,
      setRecordingState,
      setCurrentRecordingStartTime
    );

    if (mediaRecorder && mediaRecorder.state === 'paused') {
      mediaRecorder.resume();
      acquireWakeLock();
    }
  }, [mediaRecorder, recordingState]);

  const cancelRecording = useCallback(() => {
    if (mediaRecorder && mediaRecorder.state !== 'inactive') {
      mediaRecorder.stop();
    }
    if (stream) {
      stream.getTracks().forEach(track => track.stop());
    }
    setStream(null);
    setMediaRecorder(null);
    resetContext();
    releaseWakeLock();
  }, [mediaRecorder, stream, resetContext]);

  const getCurrentDurationFn = useCallback(() => {
    return getCurrentDuration(
      recordingState,
      currentRecordingStartTime,
      accumulatedDuration
    );
  }, [recordingState, currentRecordingStartTime, accumulatedDuration]);

  const setRecordingStateAndUpdate = useCallback((state: RecordingState) => {
    setRecordingState(state);
    if (state === RecordingState.IDLE) {
      setAccumulatedDuration(0);
      setCurrentRecordingStartTime(null);
      releaseWakeLock();
    }
  }, []);

  const value: AudioRecordingContextType = {
    recordingId,
    recordingState,
    accumulatedDuration,
    currentRecordingStartTime,
    audioBlob,
    analyserNode,
    audioContext: audioContextRef.current,
    mediaRecorder,
    stream,
    startRecording,
    stopRecording,
    pauseRecording: pauseRecordingFn,
    resumeRecording: resumeRecordingFn,
    cancelRecording,
    getCurrentDuration: getCurrentDurationFn,
    setRecordingState: setRecordingStateAndUpdate,
  };

  return <AudioRecordingContext.Provider value={value}>{children}</AudioRecordingContext.Provider>;
};

export const useAudioRecordingContext = (recordingId: string) => {
  const context = useContext(AudioRecordingContext);
  if (context === undefined) {
    throw new Error(`useAudioRecordingContext must be used within an AudioRecordingProvider for recording ${recordingId}`);
  }
  return context;
};

export { useAudioRecordingStore };
