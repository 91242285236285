'use client';

import React, { createContext, useContext, useState, useCallback, useRef, useEffect, use } from 'react';
import { useCredits } from "@/contexts/CreditsContext";
import { useSettings } from "@/contexts/SettingsContext";
import { eventBus } from "@/utils/EventBus";
import { EVENTS } from "@/constants/events";
import { showToast } from "@/utils/toast";
import { copyToClipboard } from "@/utils/clipboard";
import { RateLimiter } from "@/lib/rateLimiter";
import debounce from 'lodash/debounce';
import { Note } from '@/lib/notes/Note';

interface TranscriptionContextType {
    sttText: string;
    isProcessing: boolean;
    failedRecording: Blob | null;
    hasTranscription: boolean;
    isTranscribing: boolean;
    retryCountdown: number | null;
    provider: string;
    model: string;
    handleSTT: (audioFile: File | Blob | null, tmpfilepath?: string) => Promise<Note | null>;
    handleRetry: () => Promise<void>;
    setProvider: (provider: string) => void;
    setModel: (model: string) => void;
    setSttText: (text: string) => void;
    credits: number | null;
    detectSpeakers: boolean;
    setDetectSpeakers: (detectSpeakers: boolean) => void;
    numberOfSpeakers: number;
    setNumberOfSpeakers: (numberOfSpeakers: number) => void;
    language: string;
    setLanguage: (language: string) => void;
}

const TranscriptionContext = createContext<TranscriptionContextType | undefined>(undefined);

export const TranscriptionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const rateLimiter = new RateLimiter(5000);
    const { credits, fetchCredits } = useCredits();
    const { autocopy } = useSettings();
    const [sttText, setSttText] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [failedRecording, setFailedRecording] = useState<Blob | null>(null);
    const [retryCountdown, setRetryCountdown] = useState<number | null>(null);
    const [provider, setProvider] = useState('openai');
    const [model, setModel] = useState('whisper-1');
    const [hasTranscription, setHasTranscription] = useState(false);
    const [isTranscribing, setIsTranscribing] = useState(false);
    const lastTranscriptionRef = useRef<string | null>(null);
    const [detectSpeakers, setDetectSpeakers] = useState(false);
    const [numberOfSpeakers, setNumberOfSpeakers] = useState<number>(2);
    const [language, setLanguage] = useState<string>('auto');

    const showSuccessToastRef = useRef(
        debounce((message: string) => {
            showToast(message, 'success');
        }, 300)
    );

    const handleSTT = useCallback(async (
        audioFile: File | Blob | null, 
        tmpfilepath?: string
    ): Promise<Note | null> => {
        if (isTranscribing) {
            console.log('Transcription already in progress, skipping');
            return null;
        }
        if ((!audioFile || audioFile.size === 0) && !tmpfilepath) {
            console.error('No audio data provided');
            showToast('No audio data provided. Please try again.', 'error');
            return null;
        }
        setIsTranscribing(true);
        setIsProcessing(true);

        const formData = new FormData();
        if (audioFile) {
            formData.append('file', audioFile, 'audio');
            formData.append('fileType', audioFile instanceof File ? audioFile.type : 'audio/webm');
        } else if (tmpfilepath) {
            formData.append('tmpfilepath', tmpfilepath);
        }
        formData.append('provider', provider);
        formData.append('model', model);
        formData.append('detectSpeakers', detectSpeakers ? 'true' : 'false');
        if (detectSpeakers) {
            formData.append('numberOfSpeakers', numberOfSpeakers.toString());
        }
        if (language && language !== 'auto') {
            formData.append('language', language);
        }

        try {
            const response = await fetch('/api/notes', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to transcribe audio');
            }

            const data: Note = await response.json();

            const transcriptionText = data.content || '';

            if (!transcriptionText || !data.id) {
                throw new Error('Invalid response from transcription service');
            }

            if (transcriptionText !== lastTranscriptionRef.current) {
                setSttText(transcriptionText);
                setHasTranscription(true);
                lastTranscriptionRef.current = transcriptionText;
                setFailedRecording(null); // Reset failedRecording when transcription succeeds

                if (autocopy) {
                    console.log('Copying to clipboard:', transcriptionText);
                    console.log('value of autocopy', autocopy);
                    await copyToClipboard(transcriptionText);
                }

                showSuccessToastRef.current('Audio transcribed successfully!');

                eventBus.emit(EVENTS.TRANSCRIPTION_COMPLETED, data);
                return data;
            } else {
                console.log('Duplicate transcription, skipping update');
                return null;
            }
        } catch (error) {
            console.error('Error in handleSTT:', error);
            showToast(error instanceof Error ? error.message : 'Failed to transcribe audio. Please try again.', 'error');
            setFailedRecording(audioFile instanceof Blob ? audioFile : null);
            return null;
        } finally {
            setIsProcessing(false);
            setIsTranscribing(false);
            fetchCredits();
        }
    }, [isTranscribing, autocopy, fetchCredits, provider, model, detectSpeakers, language, numberOfSpeakers]);

    const handleRetry = useCallback(async () => {
        if (retryCountdown !== null) {
            showToast('Please wait before retrying', 'error');
            return;
        }

        if (!rateLimiter.allowRequest('retry')) {
            showToast('Rate limit exceeded. Please wait before retrying.', 'error');
            return;
        }

        if (!failedRecording) return;

        try {
            setIsProcessing(true);
            setHasTranscription(false);
            const note = await handleSTT(failedRecording, undefined);
            if (note) {
                setFailedRecording(null);
                eventBus.emit(EVENTS.TRANSCRIPTION_COMPLETED, note);
            }
        } finally {
            setIsProcessing(false);
            setRetryCountdown(5);
            const countdownInterval = setInterval(() => {
                setRetryCountdown((prev) => {
                    if (prev === null || prev <= 1) {
                        clearInterval(countdownInterval);
                        return null;
                    }
                    return prev - 1;
                });
            }, 1000);
        }
    }, [retryCountdown, failedRecording, handleSTT, rateLimiter, eventBus]);

    const value: TranscriptionContextType = {
        sttText,
        isProcessing,
        failedRecording,
        hasTranscription,
        isTranscribing,
        retryCountdown,
        provider,
        model,
        handleSTT,
        handleRetry,
        setProvider,
        setModel,
        setSttText,
        credits,
        detectSpeakers,
        setDetectSpeakers,
        numberOfSpeakers,
        setNumberOfSpeakers,
        language,
        setLanguage,
    };

    return <TranscriptionContext.Provider value={value}>{children}</TranscriptionContext.Provider>;
};

export const useTranscription = () => {
    const context = useContext(TranscriptionContext);
    if (context === undefined) {
        throw new Error('useTranscription must be used within a TranscriptionProvider');
    }
    return context;
};