import {RecordingState} from '@/constants/events';

// Add this at the top level outside any function
let cachedStream: MediaStream | null = null;
let cachedAudioContext: AudioContext | null = null;

// Pre-initialization function that can be called when the app loads
export const preinitializeAudio = async () => {
    try {
        const constraints = {
            audio: {
                channelCount: 1,
                sampleRate: 48000,
                echoCancellation: false,
                noiseSuppression: false,
            }
        };

        cachedStream = await navigator.mediaDevices.getUserMedia(constraints);
        cachedAudioContext = new (window.AudioContext || (window as any).webkitAudioContext)({
            sampleRate: 48000,
            latencyHint: 'interactive'
        });

        // Keep the audio context running
        await cachedAudioContext.resume();

        return true;
    } catch (e) {
        console.warn('Audio pre-initialization failed:', e);
        return false;
    }
};

interface AudioSetupOptions {
    onDataAvailable?: (data: Blob) => void;
    sampleRate?: number;
    isIOS?: boolean;
}

export const setupAudioRecording = async ({
                                              onDataAvailable,
                                              sampleRate = 48000,
                                              isIOS = false
                                          }: AudioSetupOptions = {}) => {
    // Use cached instances if available
    let stream = cachedStream;
    let audioContext = cachedAudioContext;

    // Fall back to creating new instances if needed
    if (!stream || !audioContext) {
        const constraints = {
            audio: {
                channelCount: 1,
                sampleRate,
                echoCancellation: false,
                noiseSuppression: false,
            }
        };

            stream = await navigator.mediaDevices.getUserMedia(constraints);
            audioContext = new (window.AudioContext || (window as any).webkitAudioContext)({
                sampleRate,
                latencyHint: 'interactive'
            });
    }

    const source = audioContext.createMediaStreamSource(stream);
    const analyserNode = audioContext.createAnalyser();
    source.connect(analyserNode);

    const options = {
        audioBitsPerSecond: 64000,
        mimeType: isIOS ? 'audio/mp4' : 'audio/webm'
    };

    let mediaRecorder: MediaRecorder;
    try {
        mediaRecorder = new MediaRecorder(stream, options);
    } catch (e) {
        console.warn('Failed to create MediaRecorder with specified options, trying with codec specification');
        try {
            mediaRecorder = new MediaRecorder(stream, {
                ...options,
                mimeType: 'audio/webm;codecs=opus'
            });
        } catch (e2) {
            console.warn('Failed to create MediaRecorder with codec specification, falling back to defaults');
            mediaRecorder = new MediaRecorder(stream);
        }
    }

    if (onDataAvailable) {
        mediaRecorder.ondataavailable = (e) => {
            if (e.data.size > 0) {
                onDataAvailable(e.data);
            }
        };
    }

    return {
        stream,
        analyserNode,
        mediaRecorder,
        audioContext
    };
};

export const stopRecording = (
    mediaRecorder: MediaRecorder | null,
    recordingState: RecordingState,
    setRecordingState: (state: RecordingState) => void,
    stream: MediaStream | null,
    setCurrentRecordingStartTime: (time: number | null) => void
) => {
    if (mediaRecorder && recordingState !== RecordingState.IDLE) {
        mediaRecorder.stop();
        setRecordingState(RecordingState.IDLE);
        if (stream) {
            stream.getTracks().forEach(track => track.stop());
        }
        setCurrentRecordingStartTime(null);
    }
};

export const pauseRecording = (
    mediaRecorder: MediaRecorder | null,
    recordingState: RecordingState,
    setRecordingState: (state: RecordingState) => void,
    currentRecordingStartTime: number | null,
    accumulatedDuration: number,
    setAccumulatedDuration: (duration: number) => void,
    setCurrentRecordingStartTime: (time: number | null) => void
) => {
    if (mediaRecorder && recordingState === RecordingState.RECORDING) {
        mediaRecorder.pause();
        setRecordingState(RecordingState.PAUSED);
        if (currentRecordingStartTime) {
            const pauseDuration = Math.floor((Date.now() - currentRecordingStartTime) / 1000);
            setAccumulatedDuration(accumulatedDuration + pauseDuration);
        }
        setCurrentRecordingStartTime(null);
    }
};

export const resumeRecording = (
    mediaRecorder: MediaRecorder | null,
    recordingState: RecordingState,
    setRecordingState: (state: RecordingState) => void,
    setCurrentRecordingStartTime: (time: number) => void
) => {
    if (mediaRecorder && recordingState === RecordingState.PAUSED) {
        mediaRecorder.resume();
        setRecordingState(RecordingState.RECORDING);
        setCurrentRecordingStartTime(Date.now());
    }
};

export const cancelRecording = (
    mediaRecorder: MediaRecorder | null,
    stream: MediaStream | null,
    audioContext: AudioContext | null
) => {
    if (mediaRecorder && mediaRecorder.state !== 'inactive') {
        mediaRecorder.stop();
    }

    // Only stop the stream if it's not the cached one
    if (stream && stream !== cachedStream) {
        stream.getTracks().forEach(track => track.stop());
    }

    // Only close the context if it's not the cached one
    if (audioContext && audioContext !== cachedAudioContext) {
        audioContext.close();
    }
};

export const getCurrentDuration = (
    recordingState: RecordingState,
    currentRecordingStartTime: number | null,
    accumulatedDuration: number
) => {
    if (recordingState === RecordingState.RECORDING && currentRecordingStartTime) {
        const currentTime = Date.now();
        return Math.floor((currentTime - currentRecordingStartTime) / 1000) + accumulatedDuration;
    }
    return accumulatedDuration;
};
