import React, { useState, useRef, useEffect } from 'react';
import { FaMicrophone, FaPlay, FaStop } from 'react-icons/fa';
import { Provider as TooltipProvider, Root as TooltipRoot, Trigger as TooltipTrigger, Content as TooltipContent } from '@radix-ui/react-tooltip';
import { __, TranslationOptions } from '@/utils/translationUtils';
import { setupAudioRecording } from '@/utils/audioRecordingUtils';
import { isIOS } from '@/utils/deviceDetection';

interface MicrophoneTestProps {
  onTestStart: () => void;
  onTestEnd: () => void;
}

export const MicrophoneTest: React.FC<MicrophoneTestProps> = ({ onTestStart, onTestEnd }) => {
  const [state, setState] = useState<'idle' | 'recording' | 'playing'>('idle');
  const [countdown, setCountdown] = useState(5);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const recordingTimerRef = useRef<NodeJS.Timeout | null>(null);
  const countdownTimerRef = useRef<NodeJS.Timeout | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);

  const startRecording = async () => {
    try {
      const handleDataAvailable = (data: Blob) => {
        if (data.size > 0) {
          audioChunksRef.current.push(data);
        }
      };

      const { stream, mediaRecorder } = await setupAudioRecording({
        onDataAvailable: handleDataAvailable,
        sampleRate: 48000,
        isIOS: isIOS()
      });

      streamRef.current = stream;
      mediaRecorderRef.current = mediaRecorder;
      audioChunksRef.current = [];

      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: mediaRecorder.mimeType });
        const audioUrl = URL.createObjectURL(audioBlob);
        if (audioRef.current) {
          audioRef.current.src = audioUrl;
        }
        setState('playing');
        onTestEnd();
        playRecording();
      };

      mediaRecorder.start(100);
      setState('recording');
      onTestStart();
      setCountdown(5);

      recordingTimerRef.current = setTimeout(() => {
        stopRecording();
      }, 5000);

      countdownTimerRef.current = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    } catch (error) {
      console.error(__('Error starting recording:', { source: 'audio' }), error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
      }
    }
    if (recordingTimerRef.current) {
      clearTimeout(recordingTimerRef.current);
    }
    if (countdownTimerRef.current) {
      clearInterval(countdownTimerRef.current);
    }
  };

  const playRecording = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  const handleTestClick = () => {
    if (state === 'idle') {
      startRecording();
    } else if (state === 'recording') {
      stopRecording();
    }
  };

  useEffect(() => {
    return () => {
      if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
        mediaRecorderRef.current.stop();
        if (streamRef.current) {
          streamRef.current.getTracks().forEach(track => track.stop());
        }
      }
      if (recordingTimerRef.current) {
        clearTimeout(recordingTimerRef.current);
      }
      if (countdownTimerRef.current) {
        clearInterval(countdownTimerRef.current);
      }
    };
  }, []);

  return (
    <TooltipProvider>
      <TooltipRoot>
        <TooltipTrigger asChild>
          <div
            className={`walkthrough-microphonetest-button test-microphone-button relative rounded-full w-10 h-10 md:w-12 md:h-12 flex flex-col items-center justify-center cursor-pointer transition-all duration-300 ${
              state === 'recording' ? 'bg-red-500' : state === 'playing' ? 'bg-green-500' : 'bg-blue-500 hover:bg-blue-600'
            }`}
            onClick={handleTestClick}
          >
            {state === 'idle' && (
              <div className="walkthrough-microphonetest-idle-content flex flex-col items-center">
                <FaMicrophone className="walkthrough-microphonetest-icon text-white text-xs md:text-sm mb-1" />
                <span className="walkthrough-microphonetest-text text-white text-xs md:text-sm font-bold">{__('TEST', { source: 'audio' })}</span>
              </div>
            )}
            {state === 'recording' && (
              <>
                <FaStop className="walkthrough-microphonetest-stop-icon text-white text-xs md:text-sm mb-1" />
                <span className="walkthrough-microphonetest-countdown text-white text-xs font-bold">{countdown}</span>
              </>
            )}
            {state === 'playing' && <FaPlay className="walkthrough-microphonetest-play-icon text-white text-base md:text-xl" />}
          </div>
        </TooltipTrigger>
        <TooltipContent className="walkthrough-microphonetest-tooltip bg-gray-800 text-white px-2 py-1 rounded shadow-lg">
          <p>
            {state === 'idle' && __('Test microphone (5 sec)', { source: 'audio' })}
            {state === 'recording' && __('Recording in progress', { source: 'audio' })}
            {state === 'playing' && __('Playing test audio', { source: 'audio' })}
          </p>
        </TooltipContent>
      </TooltipRoot>
      <audio 
        ref={audioRef} 
        onEnded={() => setState('idle')} 
        style={{ display: 'none' }} 
      />
    </TooltipProvider>
  );
};
