export class RateLimiter {
    private lastRequestTime: Map<string, number> = new Map();
    private cooldownPeriod: number;

    constructor(cooldownPeriod: number) {
        this.cooldownPeriod = cooldownPeriod;
    }

    allowRequest(userId: string): boolean {
        const now = Date.now();
        const lastRequest = this.lastRequestTime.get(userId) || 0;

        if (now - lastRequest >= this.cooldownPeriod) {
            this.lastRequestTime.set(userId, now);
            return true;
        }

        return false;
    }
}