import React, { useCallback, useEffect, useState } from 'react';
import { useAudioRecordingContext } from '@/contexts/AudioRecordingContext';
import { EVENTS, RecordingState } from '@/constants/events';
import { AnimatePresence, motion } from 'framer-motion';
import { FaMicrophone, FaPause, FaPlay, FaQuestionCircle, FaStop, FaTimes } from 'react-icons/fa';
import { Provider as TooltipProvider, Root as TooltipRoot, Trigger as TooltipTrigger, Content as TooltipContent } from '@radix-ui/react-tooltip';
import debounce from 'lodash/debounce';
import { eventBus } from '@/utils/EventBus';
import { __, translationSources } from '@/utils/translationUtils';

interface RecordingControlsProps {
    onStartRecording: () => Promise<void>;
    onStopRecording: () => void;
    onCancelRecording: () => void;
}

export const RecordingControls: React.FC<RecordingControlsProps> = ({ onStartRecording, onStopRecording, onCancelRecording }) => {
    const recordingId = "stt-mode";
    const audioRecording = useAudioRecordingContext(recordingId);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [cancelCountdown, setCancelCountdown] = useState(0);
    const [displayedDuration, setDisplayedDuration] = useState(0);

    useEffect(() => {
        let intervalId: NodeJS.Timeout;
        if (audioRecording.recordingState === RecordingState.RECORDING || audioRecording.recordingState === RecordingState.PAUSED) {
            intervalId = setInterval(() => {
                setDisplayedDuration(audioRecording.getCurrentDuration());
            }, 1000);
        } else {
            setDisplayedDuration(0);
        }
        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [audioRecording.recordingState, audioRecording.getCurrentDuration]);

    useEffect(() => {
        let countdownInterval: NodeJS.Timeout;
        if (cancelCountdown > 0) {
            countdownInterval = setInterval(() => {
                setCancelCountdown(prev => {
                    if (prev <= 1) {
                        clearInterval(countdownInterval);
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        }
        return () => {
            if (countdownInterval) clearInterval(countdownInterval);
        };
    }, [cancelCountdown]);

    const debouncedStartRecording = useCallback(
        debounce(async () => {
            await onStartRecording();
            setIsButtonDisabled(false);
        }, 300),
        [onStartRecording]
    );

    const handleStartRecording = useCallback(() => {
        if (!isButtonDisabled) {
            setIsButtonDisabled(true);
            // Use optional chaining to safely call the catch method
            debouncedStartRecording()?.catch(error => {
                console.error('Failed to start recording:', error);
                setIsButtonDisabled(false);
            });
        }
    }, [isButtonDisabled, debouncedStartRecording]);

    const handleStopRecording = useCallback(() => {
        onStopRecording();
        setIsButtonDisabled(false);
    }, [onStopRecording]);

    const handleTogglePauseResume = () => {
        if (audioRecording.recordingState === RecordingState.RECORDING) {
            audioRecording.pauseRecording();
        } else if (audioRecording.recordingState === RecordingState.PAUSED) {
            audioRecording.resumeRecording();
        }
    };

    const handleCancelClick = () => {
        if (cancelCountdown === 0) {
            setCancelCountdown(3);
        } else {
            onCancelRecording();
            setCancelCountdown(0);
        }
    };

    const formatDuration = (duration: number) => {
        const minutes = Math.floor(duration / 60);
        const seconds = duration % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    useEffect(() => {
        const handleStartOrStopRecording = () => {
            if (audioRecording.recordingState === RecordingState.IDLE) {
                handleStartRecording();
            } else if (audioRecording.recordingState === RecordingState.RECORDING || audioRecording.recordingState === RecordingState.PAUSED) {
                handleStopRecording();
            }
        };

        eventBus.on(EVENTS.START_OR_STOP_RECORDING, handleStartOrStopRecording);

        return () => {
            eventBus.off(EVENTS.START_OR_STOP_RECORDING, handleStartOrStopRecording);
        };
    }, [audioRecording.recordingState, handleStartRecording, handleStopRecording]);


    return (
        <div className="walkthrough-recordingcontrols-container recording-controls relative flex flex-col items-center mt-4">
            {audioRecording.recordingState !== RecordingState.IDLE && (
                <div className="walkthrough-recordingcontrols-duration mb-4 text-sm sm:text-lg font-medium recording-duration">
                    {formatDuration(displayedDuration)}
                </div>
            )}
            <div className="walkthrough-recordingcontrols-buttons flex gap-3 sm:gap-4">
                <AnimatePresence mode="wait">
                    {audioRecording.recordingState === RecordingState.IDLE ? (
                        <TooltipProvider key="record">
                            <TooltipRoot>
                                <TooltipTrigger asChild>
                                    <motion.button
                                        onMouseDown={handleStartRecording}
                                        onTouchStart={handleStartRecording}
                                        disabled={isButtonDisabled}
                                        className={`walkthrough-recordingcontrols-startbutton recording-start-button 
                                            w-[clamp(3rem,8vw,4.5rem)] h-[clamp(3rem,8vw,4.5rem)]
                                            bg-red-500 rounded-full flex items-center justify-center 
                                            text-white text-[clamp(1rem,4vw,1.5rem)] shadow-lg 
                                            transition-all duration-300 
                                            ${isButtonDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                                        whileHover={{ scale: 1.1, boxShadow: "0 0 0 min(8px,2vw) rgba(239, 68, 68, 0.3)" }}
                                        whileTap={{ scale: 0.9 }}
                                        initial={{ opacity: 0, scale: 0.8 }}
                                        animate={{ opacity: 1, scale: 1 }}
                                        exit={{ opacity: 0, scale: 0.8 }}
                                    >
                                        <FaMicrophone className="scale-90 sm:scale-100" />
                                    </motion.button>
                                </TooltipTrigger>
                                <TooltipContent className="walkthrough-recordingcontrols-starttooltip bg-gray-800 text-white px-2 py-1 rounded shadow-lg">
                                    <p>{__("Start recording (Alt+H or Alt+R)", { source: translationSources.audio })}</p>
                                </TooltipContent>
                            </TooltipRoot>
                        </TooltipProvider>
                    ) : (
                        <>
                            <TooltipProvider key="pause-resume">
                                <TooltipRoot>
                                    <TooltipTrigger asChild>
                                        <motion.button
                                            onClick={handleTogglePauseResume}
                                            className={`walkthrough-recordingcontrols-pauseresumebutton 
                                                w-[clamp(2.5rem,7vw,3.5rem)] h-[clamp(2.5rem,7vw,3.5rem)]
                                                ${audioRecording.recordingState === RecordingState.PAUSED ? 'bg-green-500' : 'bg-yellow-500'} 
                                                rounded-full flex items-center justify-center 
                                                text-white text-[clamp(0.9rem,3vw,1.25rem)] shadow-lg 
                                                transition-all duration-300`}
                                            whileHover={{ scale: 1.1, boxShadow: "0 0 0 min(6px,1.5vw) rgba(245, 158, 11, 0.3)" }}
                                            whileTap={{ scale: 0.9 }}
                                            initial={{ opacity: 0, scale: 0.8 }}
                                            animate={{ opacity: 1, scale: 1 }}
                                            exit={{ opacity: 0, scale: 0.8 }}
                                        >
                                            {audioRecording.recordingState === RecordingState.RECORDING ? 
                                                <FaPause className="scale-90 sm:scale-100" /> : 
                                                <FaPlay className="scale-90 sm:scale-100" />}
                                        </motion.button>
                                    </TooltipTrigger>
                                    <TooltipContent className="walkthrough-recordingcontrols-pauseresumetooltip bg-gray-800 text-white px-2 py-1 rounded shadow-lg">
                                        <p>{audioRecording.recordingState === RecordingState.RECORDING ?
                                            __("Pause recording", { source: translationSources.audio }) :
                                            __("Resume recording", { source: translationSources.audio })}
                                        </p>
                                    </TooltipContent>
                                </TooltipRoot>
                            </TooltipProvider>
                            <TooltipProvider key="stop">
                                <TooltipRoot>
                                    <TooltipTrigger asChild>
                                        <motion.button
                                            onClick={handleStopRecording}
                                            className="walkthrough-recordingcontrols-stopbutton 
                                                w-[clamp(3rem,8vw,4.5rem)] h-[clamp(3rem,8vw,4.5rem)]
                                                bg-green-500 rounded-full flex items-center justify-center 
                                                text-white text-[clamp(1rem,4vw,1.5rem)] shadow-lg 
                                                transition-all duration-300"
                                            whileHover={{ scale: 1.1, boxShadow: "0 0 0 min(8px,2vw) rgba(34, 197, 94, 0.3)" }}
                                            whileTap={{ scale: 0.9 }}
                                            initial={{ opacity: 0, scale: 0.8 }}
                                            animate={{ opacity: 1, scale: 1 }}
                                            exit={{ opacity: 0, scale: 0.8 }}
                                        >
                                            <FaStop className="scale-90 sm:scale-100" />
                                        </motion.button>
                                    </TooltipTrigger>
                                    <TooltipContent className="walkthrough-recordingcontrols-stoptooltip bg-gray-800 text-white px-2 py-1 rounded shadow-lg">
                                        <p>{__("Stop recording (Alt+H or Alt+R)", { source: translationSources.audio })}</p>
                                    </TooltipContent>
                                </TooltipRoot>
                            </TooltipProvider>
                            <TooltipProvider key="cancel">
                                <TooltipRoot>
                                    <TooltipTrigger asChild>
                                        <motion.button
                                            onClick={handleCancelClick}
                                            className="walkthrough-recordingcontrols-cancelbutton 
                                                w-[clamp(2.5rem,7vw,3.5rem)] h-[clamp(2.5rem,7vw,3.5rem)]
                                                bg-red-500 rounded-full flex items-center justify-center 
                                                text-white text-[clamp(0.9rem,3vw,1.25rem)] shadow-lg 
                                                transition-all duration-300"
                                            whileHover={{ scale: 1.1, boxShadow: "0 0 0 min(6px,1.5vw) rgba(239, 68, 68, 0.3)" }}
                                            whileTap={{ scale: 0.9 }}
                                            initial={{ opacity: 0, scale: 0.8 }}
                                            animate={{ opacity: 1, scale: 1 }}
                                            exit={{ opacity: 0, scale: 0.8 }}
                                        >
                                            <AnimatePresence mode="wait">
                                                {cancelCountdown > 0 ? (
                                                    <motion.div
                                                        key="countdown"
                                                        initial={{ opacity: 0, scale: 0.5 }}
                                                        animate={{ opacity: 1, scale: 1 }}
                                                        exit={{ opacity: 0, scale: 0.5 }}
                                                        className="walkthrough-recordingcontrols-cancelcountdown flex flex-col items-center"
                                                    >
                                                        <FaQuestionCircle className="mb-1 scale-90 sm:scale-100" />
                                                        <span className="text-[0.65rem] sm:text-xs">{cancelCountdown}</span>
                                                    </motion.div>
                                                ) : (
                                                    <FaTimes className="scale-90 sm:scale-100" />
                                                )}
                                            </AnimatePresence>
                                        </motion.button>
                                    </TooltipTrigger>
                                    <TooltipContent className="walkthrough-recordingcontrols-canceltooltip bg-gray-800 text-white px-2 py-1 rounded shadow-lg">
                                        <p>{cancelCountdown > 0 ?
                                            __("Click again to confirm cancel", { source: translationSources.audio }) :
                                            __("Cancel recording", { source: translationSources.audio })}
                                        </p>
                                    </TooltipContent>
                                </TooltipRoot>
                            </TooltipProvider>
                        </>
                    )}
                </AnimatePresence>
            </div>
        </div>
    );
};