'use client';

import React, { forwardRef, useCallback, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaArrowLeft, FaArrowRight, FaTimes, FaCheck } from 'react-icons/fa';
import { WalkthroughStep as WalkthroughStepType } from '@/data/walkthroughs';
import { twMerge } from 'tailwind-merge';

// This component is responsible for rendering a single step in the walkthrough.
// It handles the presentation of the step content and navigation buttons.

interface WalkthroughStepProps {
  step: WalkthroughStepType;
  currentStep: number;
  totalSteps: number;
  isProgressiveWalkthrough: boolean;
  isWaitingForInteraction: boolean;
  showOverlay: boolean;
  onSkip: () => void;
  onPrevious: () => void;
  onNext: () => void;
  onActionCompleted: () => void;
  className?: string;
  style?: React.CSSProperties;
}

export const WalkthroughStep = forwardRef<HTMLDivElement, WalkthroughStepProps>(
  ({ step, currentStep, totalSteps, isProgressiveWalkthrough, isWaitingForInteraction, showOverlay, onSkip, onPrevious, onNext, onActionCompleted, className, style }, ref) => {
    const activeElementRef = useRef<Element | null>(null);

    const getNextButtonLabel = () => {
      if (step.nextOrFinishButton) {
        return step.nextOrFinishButton;
      }
      if (isProgressiveWalkthrough) {
        return 'Got it';
      }
      return currentStep < totalSteps - 1 ? 'Next' : 'Finish';
    };

    const renderContent = () => {
      if (typeof step.content === 'function') {
        return step.content();
      } else if (React.isValidElement(step.content)) {
        return step.content;
      } else {
        return <div dangerouslySetInnerHTML={{ __html: step.content as string }} />;
      }
    };

    const handleEvent = useCallback((handler: (e: React.SyntheticEvent) => void) => (e: React.SyntheticEvent) => {
      e.preventDefault();
      e.stopPropagation();
      const activeElement = document.activeElement;
      
      // Use setTimeout to defer the execution of the handler
      setTimeout(() => {
        handler(e);
        if (activeElement instanceof HTMLElement) {
          activeElement.focus();
        }
      }, 0);
    }, []);

    useEffect(() => {
      const preventClose = (e: MouseEvent | TouchEvent) => {
        e.stopPropagation();
      };

      // Check if ref is a RefObject and has a current property
      if (ref && 'current' in ref && ref.current) {
        ref.current.addEventListener('mousedown', preventClose, true);
        ref.current.addEventListener('touchstart', preventClose, true);

        return () => {
          ref.current?.removeEventListener('mousedown', preventClose, true);
          ref.current?.removeEventListener('touchstart', preventClose, true);
        };
      }
    }, [ref]);

    return (
      <AnimatePresence>
        <motion.div
          ref={ref}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          className={twMerge('walkthrough-step relative z-50 w-[90vw] max-w-[600px] p-4 sm:p-6 bg-white dark:bg-gray-800 rounded-lg shadow-2xl border border-gray-200 dark:border-gray-700', className)}
          style={style}
          onClick={handleEvent((e) => e.stopPropagation())}
          onMouseDown={handleEvent((e) => e.stopPropagation())}
          onTouchStart={handleEvent((e) => e.stopPropagation())}
        >
          <button
            onClick={handleEvent(onSkip)}
            className="absolute top-2 right-2 p-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 transition-colors duration-200"
            aria-label="Skip walkthrough"
          >
            <FaTimes />
          </button>
          <h3 className="text-lg sm:text-xl font-semibold mb-2 sm:mb-3 text-gray-800 dark:text-white">{step.title}</h3>
          <div className={twMerge('text-sm sm:text-base text-gray-600 dark:text-gray-300 mb-4 sm:mb-5', step.contentClasses)}>
            {renderContent()}
          </div>
          <div className="flex flex-col space-y-3">
            <div className="flex justify-between items-center">
              <div className="flex-1">
                {!isProgressiveWalkthrough && currentStep > 0 ? (
                  <button
                    onClick={handleEvent(onPrevious)}
                    className="px-3 py-1 sm:px-4 sm:py-2 bg-white dark:bg-gray-600 text-indigo-600 dark:text-indigo-300 border border-indigo-600 dark:border-indigo-300 rounded-full hover:bg-indigo-50 dark:hover:bg-gray-700 transition-colors duration-200 text-xs sm:text-sm font-medium"
                  >
                    <FaArrowLeft className="inline mr-1" /> Prev
                  </button>
                ) : (
                  <div className="invisible">Placeholder</div>
                )}
              </div>
              <div className="flex-1 text-right">
                {!isWaitingForInteraction && (
                  <button
                    onClick={handleEvent(onNext)}
                    className="px-4 py-1 sm:px-6 sm:py-2 bg-indigo-600 text-white rounded-full hover:bg-indigo-700 transition-colors duration-200 text-xs sm:text-sm font-medium"
                  >
                    {getNextButtonLabel()}
                    {!isProgressiveWalkthrough && currentStep < totalSteps - 1 && (
                      <FaArrowRight className="inline ml-1" />
                    )}
                  </button>
                )}
              </div>
            </div>
            {isWaitingForInteraction && (
              <div className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                Click on the highlighted element to continue
                <button
                  onClick={handleEvent(onActionCompleted)}
                  className="ml-2 text-indigo-600 dark:text-indigo-400 hover:underline focus:outline-none"
                  aria-label="Mark action as completed"
                >
                  Skip
                </button>
              </div>
            )}
          </div>
        </motion.div>
      </AnimatePresence>
    );
  }
);

WalkthroughStep.displayName = 'WalkthroughStep';