'use client';

import React, {createContext, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {TextSelectionPopover} from '@/components/TextSelectionPopover';

type PopoverOption = {
    label: string;
    render: (props: { selectedText: string; isEditable: boolean; closePopover: () => void }) => React.ReactNode;
    isAvailable?: (text: string, isEditable: boolean) => boolean;
};

type TextSelectionContextType = {
    selectedText: string;
    isEditable: boolean;
    position: { x: number; y: number } | null;
    setPosition: React.Dispatch<React.SetStateAction<{ x: number; y: number } | null>>;
    registerOption: (option: PopoverOption) => void;
    unregisterOption: (label: string) => void;
    updateOption: (label: string, updatedOption: Partial<PopoverOption>) => void;
    clearSelection: () => void;
};

const TextSelectionContext = createContext<TextSelectionContextType | undefined>(undefined);

export const TextSelectionProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const [selectedText, setSelectedText] = useState('');
    const [isEditable, setIsEditable] = useState(false);
    const [position, setPosition] = useState<{ x: number; y: number } | null>(null);
    const [options, setOptions] = useState<PopoverOption[]>([]);
    const [optionsVersion, setOptionsVersion] = useState(0);
    const selectionTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const registerOption = useCallback((option: PopoverOption) => {
        setOptions((prevOptions) => {
            const newOptions = [...prevOptions, option];
            setOptionsVersion(v => v + 1);
            return newOptions;
        });
    }, []);

    const unregisterOption = useCallback((label: string) => {
        setOptions((prevOptions) => {
            const newOptions = prevOptions.filter((option) => option.label !== label);
            setOptionsVersion(v => v + 1);
            return newOptions;
        });
    }, []);

    const updateOption = useCallback((label: string, updatedOption: Partial<PopoverOption>) => {
        setOptions((prevOptions) => {
            const index = prevOptions.findIndex(option => option.label === label);
            if (index !== -1) {
                const newOptions = [...prevOptions];
                newOptions[index] = {...newOptions[index], ...updatedOption};
                setOptionsVersion(v => v + 1);
                return newOptions;
            }
            return prevOptions;
        });
    }, []);

    const clearSelection = useCallback(() => {
        setSelectedText('');
        setPosition(null);
        setIsEditable(false);
    }, []);

    const updateSelectionState = useCallback((selection: Selection | null, element?: HTMLElement) => {
        if (!selection && !element) {
            clearSelection();
            return;
        }

        let selectedText = '';
        let rect: DOMRect | undefined;
        let isEditable = false;

        const findTextAreaOrInput = (node: Node | null): HTMLTextAreaElement | HTMLInputElement | null => {
            if (!node) return null;
            if (node instanceof HTMLTextAreaElement || node instanceof HTMLInputElement) return node;
            if (node instanceof HTMLElement) {
                const textarea = node.querySelector('textarea');
                if (textarea instanceof HTMLTextAreaElement) return textarea;
                const input = node.querySelector('input[type="text"]');
                if (input instanceof HTMLInputElement) return input;
            }
            return node.parentElement ? findTextAreaOrInput(node.parentElement) : null;
        };

        if (selection) {
            if (!selection.isCollapsed) {
                selectedText = selection.toString();
                const range = selection.getRangeAt(0);
                rect = range.getBoundingClientRect();
                isEditable = isEditableElement(selection.anchorNode);
            } else if (selection.anchorNode) {
                const specialElement = findTextAreaOrInput(selection.anchorNode);
                if (specialElement) {
                    selectedText = specialElement.value;
                    rect = specialElement.getBoundingClientRect();
                    isEditable = true;
                }
            }
        } else if (element) {
            const specialElement = findTextAreaOrInput(element);
            if (specialElement) {
                selectedText = specialElement.value;
                rect = specialElement.getBoundingClientRect();
                isEditable = true;
            } else if (element.nodeType === Node.TEXT_NODE && element.parentElement) {
                const range = document.createRange();
                range.selectNodeContents(element);
                selectedText = range.toString();
                rect = range.getBoundingClientRect();
                isEditable = isEditableElement(element.parentElement);
            }
        }

        if (selectedText.trim() === '' && !isEditable) {
            clearSelection();
            return;
        }

        if (!rect) {
            clearSelection();
            return;
        }

        const newPosition = {x: rect.left + window.scrollX, y: rect.bottom + window.scrollY};
        setSelectedText(selectedText);
        setPosition(newPosition);
        setIsEditable(isEditable);
    }, [clearSelection]);


    const handleSelection = useCallback(() => {
        const selection = window.getSelection();
        if (selection) {
            updateSelectionState(selection, selection.anchorNode as HTMLTextAreaElement | HTMLInputElement);
        }
    }, [updateSelectionState]);

    useEffect(() => {
        const handleKeyUp = (event: KeyboardEvent) => {
            const isSelectionShortcut =
                (event.shiftKey && ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Home', 'End'].includes(event.key)) ||
                ((event.ctrlKey || event.metaKey) && event.key === 'a');

            if (isSelectionShortcut) {
                handleSelection();
            }
        };

        const handleMouseUp = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            if (target.tagName === 'TEXTAREA' || target.tagName === 'INPUT') {
                const element = target as HTMLTextAreaElement | HTMLInputElement;
                const start = element.selectionStart;
                const end = element.selectionEnd;
                if (start !== end) {
                    updateSelectionState(null, element);
                }
            } else {
                handleSelection();
            }
        };

        document.addEventListener('selectionchange', handleSelection);
        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('keyup', handleKeyUp);
        return () => {
            document.removeEventListener('selectionchange', handleSelection);
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('keyup', handleKeyUp);
        };
    }, [handleSelection, updateSelectionState]);

    const isEditableElement = (node: Node | null): boolean => {
        if (!node) return false;
        const element = node.nodeType === Node.ELEMENT_NODE ? node as HTMLElement : node.parentElement;
        return element?.isContentEditable || element?.tagName === 'INPUT' || element?.tagName === 'TEXTAREA';
    };

    const contextValue: TextSelectionContextType = {
        selectedText,
        isEditable,
        position,
        setPosition,
        registerOption,
        unregisterOption,
        updateOption,
        clearSelection,
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (position && !(event.target as Element).closest('.text-selection-popover')) {
                clearSelection();
            }
        };

        const handleEscKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                clearSelection();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleEscKey);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleEscKey);
        };
    }, [position, clearSelection]);

    return (
        <TextSelectionContext.Provider value={contextValue}>
            {children}
            {selectedText && position && (
                <TextSelectionPopover key={optionsVersion} options={options}/>
            )}
        </TextSelectionContext.Provider>
    );
};

export const useTextSelection = () => {
    const context = useContext(TextSelectionContext);
    if (!context) {
        throw new Error('useTextSelection must be used within a TextSelectionProvider');
    }
    return context;
};
