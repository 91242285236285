'use client';

import { walkthroughs, Walkthrough, WalkthroughStep } from '@/data/walkthroughs';
import { getFirstVisibleElement, isElementVisible } from '@/utils/walkthroughUtils';

type WalkthroughId = keyof typeof walkthroughs;

type WalkthroughState = 'NotStarted' | 'InProgress' | 'WaitingForCondition' | 'Completed';

interface WalkthroughMeta {
	completed: boolean;
	lastShown: number | null;
	timesShown: number;
	lastSkipped: number | null;
	currentStep: number | null;
	lastCompleted: number | null;
	completedSteps: number[];
	currentState: WalkthroughState;
	lastAttemptedStep: number | null;
}

class WalkthroughsStateManager {
	private _meta: Record<WalkthroughId, WalkthroughMeta> | null;
	public currentWalkthrough: WalkthroughId | null;
	public currentStep: number;

	constructor() {
		this._meta = null;
		this.currentWalkthrough = null;
		this.currentStep = 0;
	}

	get meta(): Record<WalkthroughId, WalkthroughMeta> | null {
		return this._meta;
	}

	initializeMeta(data: Record<WalkthroughId, WalkthroughMeta>): void {
		this._meta = data;
		this.currentWalkthrough = null;
		this.currentStep = 0;
	}

	private ensureInitialized(): void {
		if (this._meta === null) {
			throw new Error('WalkthroughsStateManager not initialized. Call initializeMeta first.');
		}
	}

	private getMetaEntry(id: WalkthroughId): WalkthroughMeta {
		this.ensureInitialized();
		return this._meta![id] || {
			completed: false,
			lastShown: null,
			timesShown: 0,
			lastSkipped: null,
			currentStep: null,
			lastCompleted: null,
			completedSteps: [],
			currentState: 'NotStarted',
			lastAttemptedStep: null
		};
	}

	private updateMetaEntry(id: WalkthroughId, updatedMeta: Partial<WalkthroughMeta>): WalkthroughMeta {
		this.ensureInitialized();
		const currentMeta = this.getMetaEntry(id);
		const newMeta = { ...currentMeta, ...updatedMeta };

		// Ensure coherence across properties
		if (newMeta.completed) {
			newMeta.currentState = 'Completed';
			newMeta.lastCompleted = Date.now();
			newMeta.currentStep = null;
		}

		if (newMeta.currentState === 'Completed' && !newMeta.completed) {
			newMeta.completed = true;
			newMeta.lastCompleted = Date.now();
		}

		if (newMeta.currentState === 'InProgress' && newMeta.currentStep === null) {
			newMeta.currentStep = 0;
		}

		if (newMeta.currentStep !== null && newMeta.currentStep >= 0) {
			newMeta.lastAttemptedStep = Math.max(newMeta.lastAttemptedStep ?? -1, newMeta.currentStep);
		}

		// Always update lastShown when the walkthrough is interacted with
		newMeta.lastShown = Date.now();

		this._meta![id] = newMeta;
		return newMeta;
	}

	startWalkthrough(id: WalkthroughId): WalkthroughMeta {
		this.ensureInitialized();
		const metaEntry = this.getMetaEntry(id);
		if (metaEntry.completed) return metaEntry;

		const walkthrough = walkthroughs[id];
		const initialStepIndex = metaEntry.currentStep ?? 0;
		const firstStepIndex = this.findNextValidStepIndex(walkthrough, initialStepIndex, metaEntry);

		if (firstStepIndex !== null) {
			this.currentWalkthrough = id;
			this.currentStep = firstStepIndex;
			return this.updateMetaEntry(id, {
				lastShown: Date.now(),
				timesShown: metaEntry.timesShown + 1,
				currentStep: firstStepIndex,
				currentState: 'InProgress',
				lastAttemptedStep: firstStepIndex
			});
		} else {
			this.currentWalkthrough = null;
			this.currentStep = 0;
			return this.updateMetaEntry(id, { 
				currentState: 'WaitingForCondition',
				currentStep: null
			});
		}
	}

	nextStep(): WalkthroughMeta | null {
		this.ensureInitialized();
		if (!this.currentWalkthrough) {
			return null;
		}

		const walkthrough = walkthroughs[this.currentWalkthrough];
		const metaEntry = this.getMetaEntry(this.currentWalkthrough);

		const nextIndex = this.findNextValidStepIndex(walkthrough, this.currentStep + 1, metaEntry);

		if (nextIndex !== null) {
			this.currentStep = nextIndex;
			return this.updateMetaEntry(this.currentWalkthrough, {
				currentStep: nextIndex,
				completedSteps: Array.from(new Set([...metaEntry.completedSteps, this.currentStep])),
				lastAttemptedStep: nextIndex,
				currentState: 'InProgress'
			});
		} else if (this.currentStep === walkthrough.steps.length - 1) {
			return this.endWalkthrough();
		} else {
			const id = this.currentWalkthrough;
			this.currentWalkthrough = null;
			this.currentStep = 0;
			return this.updateMetaEntry(id, {
				currentStep: null,
				completedSteps: Array.from(new Set([...metaEntry.completedSteps, this.currentStep])),
				currentState: 'WaitingForCondition',
				lastAttemptedStep: this.currentStep + 1
			});
		}
	}

	previousStep(): WalkthroughMeta | null {
		this.ensureInitialized();
		if (!this.currentWalkthrough || this.currentStep === 0) {
			return null;
		}

		const metaEntry = this.getMetaEntry(this.currentWalkthrough);
		this.currentStep--;

		return this.updateMetaEntry(this.currentWalkthrough, {
			currentStep: this.currentStep,
			completedSteps: metaEntry.completedSteps.filter((step) => step !== this.currentStep + 1),
			lastAttemptedStep: this.currentStep,
			currentState: 'InProgress'
		});
	}

	skipWalkthrough(): WalkthroughMeta | null {
		this.ensureInitialized();
		if (!this.currentWalkthrough) return null;

		const updatedMeta = this.updateMetaEntry(this.currentWalkthrough, {
			lastSkipped: Date.now(),
			currentStep: null,
			currentState: 'NotStarted'
		});
		this.currentWalkthrough = null;
		this.currentStep = 0;
		return updatedMeta;
	}

	endWalkthrough(): WalkthroughMeta | null {
		this.ensureInitialized();
		if (!this.currentWalkthrough) return null;

		const updatedMeta = this.updateMetaEntry(this.currentWalkthrough, {
			completed: true,
			lastCompleted: Date.now(),
			currentState: 'Completed',
			currentStep: null
		});
		this.currentWalkthrough = null;
		this.currentStep = 0;
		return updatedMeta;
	}

	getCurrentStepContent(): WalkthroughStep | null {
		this.ensureInitialized();
		if (!this.currentWalkthrough) return null;
		const walkthrough = walkthroughs[this.currentWalkthrough];
		const step = walkthrough.steps[this.currentStep];
		if (!step) return null;
		if (step.target) {
			const element = getFirstVisibleElement(step.target);
			return element ? step : null;
		}
		return step;
	}

	getNextAvailableWalkthrough(): WalkthroughId | null {
		this.ensureInitialized();
		const now = Date.now();
		const tenSecondsAgo = now - 10000;
		const recentActivity = Object.values(this._meta!).some((entry) => {
			if (!entry || typeof entry !== 'object') {
				return false;
			}
			return (
				(entry.lastCompleted && entry.lastCompleted > tenSecondsAgo) || 
				(entry.lastSkipped && entry.lastSkipped > tenSecondsAgo) || 
				(entry.lastShown && entry.lastShown > tenSecondsAgo)
			);
		});

		for (const id of Object.keys(walkthroughs) as WalkthroughId[]) {
			const walkthrough = walkthroughs[id];
			const metaEntry = this.getMetaEntry(id);
			if (metaEntry.completed) continue;
			if (metaEntry.currentState === 'InProgress') return id;
			if (recentActivity && metaEntry.currentState !== 'WaitingForCondition') continue;
			const areRequiredElementsVisible = walkthrough.requiredVisibleElements?.every(isElementVisible) ?? true;
			const isNotRecentlySkipped = !metaEntry.lastSkipped || now - metaEntry.lastSkipped > Math.max(walkthrough.skipDelay ?? 0, 12 * 60 * 60 * 1000);
			if (areRequiredElementsVisible && metaEntry.currentState !== 'Completed' && isNotRecentlySkipped) {
				const nextStepIndex = this.findNextValidStepIndex(walkthrough, metaEntry.lastAttemptedStep ?? 0, metaEntry);
				if (nextStepIndex !== null) {
					this.updateMetaEntry(id, {
						currentState: 'InProgress',
						currentStep: nextStepIndex,
						lastAttemptedStep: nextStepIndex,
						timesShown: metaEntry.timesShown + 1
					});
					return id;
				}
			}
		}
		return null;
	}

	reevaluateWalkthroughs(): void {
		this.ensureInitialized();
		for (const [id, walkthrough] of Object.entries(walkthroughs)) {
			const metaEntry = this.getMetaEntry(id as WalkthroughId);
			if (metaEntry.currentState === 'WaitingForCondition') {
				const nextStep = this.findNextValidStepIndex(walkthrough, (metaEntry.lastAttemptedStep ?? 0) + 1, metaEntry);
				if (nextStep !== null) {
					this.updateMetaEntry(id as WalkthroughId, {
						currentState: 'InProgress',
						currentStep: nextStep,
						lastAttemptedStep: nextStep,
						timesShown: metaEntry.timesShown + 1
					});
				}
			}
		}
	}

	private findNextValidStepIndex(walkthrough: Walkthrough, fromIndex: number, meta: WalkthroughMeta): number | null {
		for (let i = fromIndex; i < walkthrough.steps.length; i++) {
			const step = walkthrough.steps[i];
			if (!meta.completedSteps?.includes(i)) {
				if (this.isStepAvailable(step)) {
					return i;
				} else if (!step.optional) {
					// If the step is not optional and not available, null the current walkthrough and step
					if (this.currentWalkthrough) {
						this.updateMetaEntry(this.currentWalkthrough, {
							currentState: 'WaitingForCondition',
							currentStep: null,
							lastAttemptedStep: i
						});
						this.currentWalkthrough = null;
						this.currentStep = 0;
					}
					return null;
				}
			}
		}
		return null;
	}

	private isStepAvailable(step: WalkthroughStep): boolean {
		if (step === undefined) {
			throw new Error(`Invalid step: undefined. All meta data: ${JSON.stringify(this._meta)}`);
		}

		// Handle welcome step or any step with null target (centered steps)
		if (step.target === null || step.placement === 'center') {
			return true;
		}

		if (typeof step.target !== 'string') {
			throw new Error(`Invalid step target: ${JSON.stringify(step.target)}. All meta data: ${JSON.stringify(this._meta)}`);
		}

		const isVisible = isElementVisible(step.target);
		const conditionMet = !step.condition || step.condition();
		
		return isVisible && conditionMet;
	}
}

export default WalkthroughsStateManager;
