'use client';

import React, { useCallback, useState, useEffect } from 'react';
import { useNoteTaker } from '@/contexts/NoteTakerContext';
import TTSMode from './TTSMode';
import { STTMode } from '@/features/stt';
import { ModeToggle } from './ModeToggle';
import { Note } from '@/lib/notes/Note';
import { useDebouncedCallback } from 'use-debounce';
import { useSettings } from '@/contexts/SettingsContext';
import { showToast } from "@/utils/toast";
import { useSearchHistory } from '@/hooks/useSearchHistoryStore';
import VisualViewportWrapper from './VisualViewportWrapper';
import { __, TranslationOptions } from '@/utils/translationUtils';

const NoteTakerContent: React.FC = () => {
  const { mode, setMode } = useNoteTaker();
  const { updateSettings } = useSettings();
  const [ttsContent, setTtsContent] = useState('');
  const [sttTranscription, setSttTranscription] = useState('');
  const { loadNewer } = useSearchHistory();

  const translationOptions: TranslationOptions = { source: 'uiComponents' };

  const debouncedUpdateSettings = useDebouncedCallback(
    async (newMode: 'tts' | 'stt') => {
      try {
        await updateSettings({ lastsstortts: newMode });
      } catch (error) {
        console.error('Failed to update lastsstortts setting:', error);
        showToast(__('Failed to update setting. Please try again.', translationOptions), 'error');
      }
    },
    300
  );

  const handleModeChange = (newMode: 'tts' | 'stt') => {
    setMode(newMode);
    debouncedUpdateSettings(newMode);
  };

  const handleInternalTTSComplete = useCallback((newItem: Note) => {
    setTtsContent(newItem.content);
    loadNewer();
  }, [loadNewer]);

  const handleInternalSTTComplete = useCallback((data: Note) => {
    setSttTranscription(data.content);
    loadNewer();
  }, [loadNewer]);

  useEffect(() => {
    const handleStartOrStopRecording = () => {
      setMode('stt');
      debouncedUpdateSettings('stt');
    };

    window.addEventListener('START_OR_STOP_RECORDING', handleStartOrStopRecording);

    return () => {
      window.removeEventListener('START_OR_STOP_RECORDING', handleStartOrStopRecording);
    };
  }, [setMode, debouncedUpdateSettings]);

  return (
    <VisualViewportWrapper className="walkthrough-notetakercontent-wrapper flex flex-col h-full notetaker-content">
      <div className="walkthrough-notetakercontent-main flex flex-col flex-grow h-full p-4 overflow-hidden notetaker-main">
        <div className="walkthrough-notetakercontent-contentwrapper flex flex-col flex-grow h-full min-h-0 relative notetaker-content-wrapper">
          <div className="walkthrough-notetakercontent-modecontainer flex flex-col flex-grow h-full notetaker-mode-container">
            <div className={`${mode === 'tts' ? 'block' : 'hidden'} absolute inset-0`}>
              <TTSMode
                onTTSComplete={handleInternalTTSComplete}
                initialContent={ttsContent}
                addHistoryItem={loadNewer}
              />
            </div>
            <div className={`${mode === 'stt' ? 'block' : 'hidden'} absolute inset-0`}>
              <STTMode
                onSTTComplete={handleInternalSTTComplete}
                onRecordingStateChange={() => {}}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="walkthrough-notetakercontent-modetogglecontainer flex flex-row items-center justify-center pb-4 notetaker-mode-toggle-container">
        <ModeToggle
          mode={mode}
          onModeChange={handleModeChange}
          containerClassName="walkthrough-notetakercontent-modetoggle bg-gradient-to-r from-purple-500 to-indigo-600 p-0.5 rounded-full shadow-lg notetaker-mode-toggle"
          toggleClassName="bg-white dark:bg-gray-800"
          sliderClassName="bg-gradient-to-r from-pink-500 to-orange-500"
          buttonClassName="text-xs font-semibold"
          activeButtonClassName="text-white"
          inactiveButtonClassName="text-gray-600 dark:text-gray-300"
        />
      </div>
    </VisualViewportWrapper>
  );
};

export default NoteTakerContent;
