'use client';

import React, { useState, useRef, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { FiCheck, FiChevronDown, FiGlobe } from 'react-icons/fi';
import { Root, Trigger, Portal, Content } from '@radix-ui/react-popover';
import { __, getLocale, setLocale, supportedLanguages, SupportedLocale, TranslationOptions } from '@/utils/translationUtils';
import { usePathname } from 'next/navigation';
import { getMaxZIndex } from '@/utils/zIndexUtils';

interface LanguageSwitcherProps {
    onLanguageChange?: (locale: SupportedLocale) => void;
    className?: string;
}

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({
    onLanguageChange,
    className = '',
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [zIndex, setZIndex] = useState(0);
    const triggerRef = useRef<HTMLButtonElement>(null);
    const pathname = usePathname();

    const translationOptions: TranslationOptions = { source: 'uiComponents' };

    const urlLocale = pathname?.split('/')[1] as SupportedLocale;
    setLocale(urlLocale);   

    const currentLanguage = getLocale();
    const currentLanguageName = supportedLanguages.find(lang => lang.code === currentLanguage)?.name || __('Language', translationOptions);

    const handleLanguageChange = (locale: SupportedLocale) => {
        setLocale(locale);
        setIsOpen(false);
        if (onLanguageChange) {
            onLanguageChange(locale);
        }

        // Update the URL with the new locale
        if (pathname) {
            const newPathname = pathname.replace(/^\/[^\/]+/, `/${locale}`);
            window.location.href = newPathname;
        }
    };

    useEffect(() => {
        if (triggerRef.current) {
            setZIndex(getMaxZIndex(triggerRef.current));
        }
    }, []);

    return (
        <Root open={isOpen} onOpenChange={setIsOpen}>
            <Trigger asChild>
                <button
                    ref={triggerRef}
                    className={`walkthrough-localeswitcher-trigger flex items-center space-x-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-full px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150 ease-in-out ${className}`}
                >
                    <FiGlobe className="walkthrough-localeswitcher-icon w-5 h-5 text-gray-400"/>
                    <span className="walkthrough-localeswitcher-language">{currentLanguageName}</span>
                    <FiChevronDown
                        className={`walkthrough-localeswitcher-arrow w-4 h-4 text-gray-400 transition-transform duration-200 ${isOpen ? 'transform rotate-180' : ''}`}/>
                </button>
            </Trigger>
            <Portal>
                <Content
                    sideOffset={5}
                    align="end"
                    style={{ zIndex: zIndex }}
                    className="walkthrough-localeswitcher-dropdown mt-2 w-56 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 overflow-hidden"
                >
                    <AnimatePresence>
                        {isOpen && (
                            <motion.div
                                initial={{opacity: 0, y: -10}}
                                animate={{opacity: 1, y: 0}}
                                exit={{opacity: 0, y: -10}}
                                transition={{duration: 0.2}}
                                className="walkthrough-localeswitcher-options py-1 max-h-60 overflow-auto custom-scrollbar"
                            >
                                {supportedLanguages.map((language) => (
                                    <button
                                        key={language.code}
                                        onClick={() => handleLanguageChange(language.code)}
                                        className={`walkthrough-localeswitcher-option flex items-center w-full px-4 py-2 text-sm ${
                                            currentLanguage === language.code
                                                ? 'bg-indigo-50 dark:bg-indigo-900 text-indigo-600 dark:text-indigo-200'
                                                : 'text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700'
                                        } transition duration-150 ease-in-out`}
                                    >
                                        <span className="walkthrough-localeswitcher-flag w-6 inline-flex justify-center mr-2">{language.flag || ' '}</span>
                                        <span className="walkthrough-localeswitcher-name flex-grow text-left">{__(language.name, translationOptions)}</span>
                                        {currentLanguage === language.code && (
                                            <FiCheck className="walkthrough-localeswitcher-check w-4 h-4 text-indigo-600 dark:text-indigo-400"/>
                                        )}
                                    </button>
                                ))}
                            </motion.div>
                        )}
                    </AnimatePresence>
                </Content>
            </Portal>
        </Root>
    );
};

export default LanguageSwitcher;