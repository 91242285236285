'use client';

import { SessionProvider, useSession } from "next-auth/react";
import { BaseProviders, AuthProviders } from "@/contexts/Providers";

interface ClientProvidersProps {
  children: React.ReactNode;
  locale: string;
  session: any;
}

export function ClientProviders({ children, locale, session }: ClientProvidersProps) {
  return (
    <SessionProvider session={session}>
      <BaseProviders locale={locale}>
        <ConditionalAuthProviders>
          {children}
        </ConditionalAuthProviders>
      </BaseProviders>
    </SessionProvider>
  );
}

function ConditionalAuthProviders({ children }: { children: React.ReactNode }) {
  const { data: session } = useSession();
  
  return session ? (
    <AuthProviders>
      {children}
    </AuthProviders>
  ) : (
    <>{children}</>
  );
}
