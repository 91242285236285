'use client';

import React, { createContext, ReactNode, useContext, useEffect, useRef, useState, useMemo } from 'react';
import { RecordingState } from '@/constants/events';
import { transcribeAudio } from '@/utils/audioApi';
import { setupAudioRecording, stopRecording, pauseRecording, resumeRecording, cancelRecording, getCurrentDuration } from '@/utils/audioRecordingUtils';
import { debounce } from 'lodash';

interface RecordingContextState {
    recordingState: RecordingState;
    cursorPosition: { x: number; y: number } | null;
    duration: number;
    startRecording: (x: number, y: number, inputElement: HTMLInputElement | HTMLTextAreaElement, caretPosition: number) => Promise<void>;
    stopRecording: () => void;
    pauseRecording: () => void;
    resumeRecording: () => void;
    cancelRecording: () => void;
    isTranscribing: boolean;
    transcriptionError: string | null;
    creditError: string | null;
    retryTranscription: () => void;
    analyserNode: AnalyserNode | null;
    isLoading: boolean;
}

const RecordAnywhereContext = createContext<RecordingContextState | undefined>(undefined);

export const useRecordAnywhere = () => {
    const context = useContext(RecordAnywhereContext);
    if (!context) {
        throw new Error('useRecordAnywhere must be used within a RecordAnywhereProvider');
    }
    return context;
};

export const RecordAnywhereProvider = React.memo(({ children }: { children: ReactNode }) => {
    const [recordingState, setRecordingState] = useState<RecordingState>(RecordingState.IDLE);
    const [cursorPosition, setCursorPosition] = useState<{ x: number; y: number } | null>(null);
    const [duration, setDuration] = useState(0);
    const [accumulatedDuration, setAccumulatedDuration] = useState(0);
    const [currentRecordingStartTime, setCurrentRecordingStartTime] = useState<number | null>(null);
    const [isTranscribing, setIsTranscribing] = useState(false);
    const [transcriptionError, setTranscriptionError] = useState<string | null>(null);
    const [creditError, setCreditError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const streamRef = useRef<MediaStream | null>(null);
    const audioContextRef = useRef<AudioContext | null>(null);
    const analyserNodeRef = useRef<AnalyserNode | null>(null);
    const activeInputRef = useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);
    const caretPositionRef = useRef<number | null>(null);
    const wakeLockRef = useRef<WakeLockSentinel | null>(null);

    const chunksRef = useRef<Blob[]>([]);
    const currentRecordingRef = useRef<Blob | null>(null);

    const loadingTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const recordingStartedRef = useRef(false);

    const checkAudioLevel = useMemo(() => debounce(() => {
        if (analyserNodeRef.current && !recordingStartedRef.current) {
            const bufferLength = analyserNodeRef.current.frequencyBinCount;
            const dataArray = new Uint8Array(bufferLength);
            analyserNodeRef.current.getByteTimeDomainData(dataArray);

            const silenceThreshold = 5;
            const isSilent = dataArray.every(value => Math.abs(value - 5) < silenceThreshold);

            if (!isSilent) {
                recordingStartedRef.current = true;
                setIsLoading(false);
                if (loadingTimeoutRef.current) {
                    clearTimeout(loadingTimeoutRef.current);
                }
            }
        }
    }, 100), []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setDuration(getCurrentDuration(recordingState, currentRecordingStartTime, accumulatedDuration));
        }, 1000);

        return () => clearInterval(intervalId);
    }, [recordingState, currentRecordingStartTime, accumulatedDuration]);

    const acquireWakeLock = async () => {
        if ('wakeLock' in navigator) {
            try {
                wakeLockRef.current = await navigator.wakeLock.request('screen');
                console.log('Wake Lock acquired');
            } catch (err) {
                console.error('Failed to acquire Wake Lock:', err);
            }
        }
    };

    const releaseWakeLock = () => {
        if (wakeLockRef.current) {
            wakeLockRef.current.release()
                .then(() => {
                    console.log('Wake Lock released');
                    wakeLockRef.current = null;
                })
                .catch((err) => console.error('Failed to release Wake Lock:', err));
        }
    };

    const startRecording = async (x: number, y: number, inputElement: HTMLInputElement | HTMLTextAreaElement, caretPosition: number) => {
        if (recordingState !== RecordingState.IDLE) return;

        setIsLoading(true);
        recordingStartedRef.current = false;

        try {
            const { stream, analyserNode, mediaRecorder, audioContext } = await setupAudioRecording({
                onDataAvailable: (data) => {
                    chunksRef.current.push(data);
                }
            });

            streamRef.current = stream;
            audioContextRef.current = audioContext;
            analyserNodeRef.current = analyserNode;
            mediaRecorderRef.current = mediaRecorder;

            mediaRecorder.onstart = () => {
                setCurrentRecordingStartTime(Date.now());
            };

            mediaRecorder.onstop = () => {
                handleRecordingStop();
            };

            mediaRecorder.start();
            setRecordingState(RecordingState.RECORDING);

            const checkAudioLevelInterval = setInterval(checkAudioLevel, 100);

            loadingTimeoutRef.current = setTimeout(() => {
                setIsLoading(false);
                clearInterval(checkAudioLevelInterval);
            }, 1000);

            activeInputRef.current = inputElement;
            caretPositionRef.current = caretPosition;

            setCursorPosition({x, y});
            await acquireWakeLock();
        } catch (error) {
            console.error('Failed to start recording:', error);
            setIsLoading(false);
        }
    };

    const handleRecordingStop = async () => {
        if (recordingState === RecordingState.CANCELLED) {
            setRecordingState(RecordingState.IDLE);
            return;
        }

        await new Promise(resolve => setTimeout(resolve, 100));

        const blob = new Blob(chunksRef.current, {type: 'audio/webm'});
        currentRecordingRef.current = blob;
        chunksRef.current = [];

        const inputElement = activeInputRef.current;
        const caretPosition = caretPositionRef.current;

        if (inputElement && caretPosition !== null && blob.size > 0) {
            await transcribeAndInsertText(blob, inputElement, caretPosition);
        }

        activeInputRef.current = null;
        caretPositionRef.current = null;
        setRecordingState(RecordingState.IDLE);
        setDuration(accumulatedDuration);
    };

    const handleStopRecording = () => {
        stopRecording(
            mediaRecorderRef.current,
            recordingState,
            setRecordingState,
            streamRef.current,
            setCurrentRecordingStartTime
        );
        releaseWakeLock();
    };

    const transcribeAndInsertText = async (blob: Blob, inputElement: HTMLInputElement | HTMLTextAreaElement, caretPosition: number) => {
        setIsTranscribing(true);
        setTranscriptionError(null);
        setCreditError(null);

        try {
            const {text, language} = await transcribeAudio(blob);

            inputElement.focus();
            inputElement.setSelectionRange(caretPosition, caretPosition);

            const words = text.split(' ');
            let insertedText = '';
            const currentValue = inputElement.value;
            const needsLeftSpace = caretPosition > 0 && currentValue[caretPosition - 1] !== ' ';
            const needsRightSpace = caretPosition < currentValue.length && currentValue[caretPosition] !== ' ';

            if (needsLeftSpace) {
                insertedText += ' ';
            }

            for (const word of words) {
                insertedText += word + ' ';
                document.execCommand('insertText', false, insertedText);
                await new Promise(resolve => setTimeout(resolve, 5));
                insertedText = '';
            }

            if (!needsRightSpace) {
                inputElement.setSelectionRange(caretPosition + text.length, caretPosition + text.length + 1);
                document.execCommand('delete', false);
            }
        } catch (error) {
            if (error instanceof Error) {
                if (error.message.includes('Insufficient credits')) {
                    setCreditError('Insufficient credits. Please purchase more credits to continue using the transcription service.');
                } else {
                    setTranscriptionError('Failed to transcribe audio. Would you like to try again?');
                }
            }
        } finally {
            setIsTranscribing(false);
        }
    };

    const retryTranscription = async () => {
        const blob = currentRecordingRef.current;
        const inputElement = activeInputRef.current;
        const caretPosition = caretPositionRef.current;

        if (blob && inputElement && caretPosition !== null) {
            await transcribeAndInsertText(blob, inputElement, caretPosition);
        }
    };

    const handlePauseRecording = () => {
        pauseRecording(
            mediaRecorderRef.current,
            recordingState,
            setRecordingState,
            currentRecordingStartTime,
            accumulatedDuration,
            setAccumulatedDuration,
            setCurrentRecordingStartTime
        );
        releaseWakeLock();
    };

    const handleResumeRecording = async () => {
        resumeRecording(
            mediaRecorderRef.current,
            recordingState,
            setRecordingState,
            setCurrentRecordingStartTime
        );
        await acquireWakeLock();
    };

    const handleCancelRecording = () => {
        setRecordingState(RecordingState.CANCELLED);
        cancelRecording(
            mediaRecorderRef.current,
            streamRef.current,
            audioContextRef.current
        );
        setCursorPosition(null);
        setDuration(0);
        setAccumulatedDuration(0);
        setCurrentRecordingStartTime(null);
        chunksRef.current = [];
        currentRecordingRef.current = null;
        activeInputRef.current = null;
        caretPositionRef.current = null;
        releaseWakeLock();
    };

    useEffect(() => {
        return () => {
            if (recordingState !== RecordingState.IDLE) {
                handleCancelRecording();
            }
        };
    }, []);

    const contextValue = useMemo(() => ({
        recordingState,
        cursorPosition,
        duration,
        isTranscribing,
        transcriptionError,
        creditError,
        startRecording,
        stopRecording: handleStopRecording,
        pauseRecording: handlePauseRecording,
        resumeRecording: handleResumeRecording,
        cancelRecording: handleCancelRecording,
        retryTranscription,
        analyserNode: analyserNodeRef.current,
        isLoading,
    }), [
        recordingState,
        cursorPosition,
        duration,
        isTranscribing,
        transcriptionError,
        creditError,
        isLoading
    ]);

    return (
        <RecordAnywhereContext.Provider value={contextValue}>
            {children}
        </RecordAnywhereContext.Provider>
    );
});

RecordAnywhereProvider.displayName = 'RecordAnywhereProvider';
