'use client';

import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useSession } from 'next-auth/react';
import {EUPPrompt, FloatingProgressNotification} from '@/components/EUPPrompt';
import LoadingSpinner from '@/components/LoadingSpinner';
import { __ } from '@/utils/translationUtils';

// Cache durations
const EUP_CACHE_DURATION = 60000; // 1 minute cache duration
const JOBS_CACHE_DURATION = 300000; // 5 minutes cache duration
const FORCE_CHECK_INTERVAL = 30000; // 30 seconds
let lastEUPCheckTime = 0;
let lastJobsCheckTime = 0;
let lastEUPSetTime = 0;
let cachedEUPStatus: { isSet: boolean; isNewDevice: boolean } | null = null;
let cachedPendingJob: any = null;

interface EUPContextType {
    isEUPSet: boolean;
    isProcessing: boolean;
    progress: number;
    setEUP: (up: string, inBackground: boolean) => Promise<string>;
    processEncryptionJob: (jobId: string) => Promise<void>;
    jobId: string | null;
    isNewDevice: boolean;
    isSkipped: boolean;
    skipEUPSetup: () => void;
    verifyEUP: (up: string) => Promise<boolean>;
    continueInBackground: boolean;
    setContinueInBackground: (value: boolean) => void;
    isLoading: boolean;
    needsEUPSetup: boolean;
    showFloatingNotification: boolean;
    setShowFloatingNotification: (value: boolean) => void;
    hasShownSuccess: boolean;
    setHasShownSuccess: (value: boolean) => void;
    showPromptAfterSkip: boolean;
    setShowPromptAfterSkip: (value: boolean) => void;
    showSuccessPrompt: boolean;
    setShowSuccessPrompt: (value: boolean) => void;
}

const EUPContext = createContext<EUPContextType | undefined>(undefined);

export const useEUP = () => {
    const context = useContext(EUPContext);
    if (context === undefined) {
        throw new Error(__('useEUP must be used within an EUPProvider'));
    }
    return context;
};

export const EUPProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { data: session, status } = useSession();
    const [isEUPSet, setIsEUPSet] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [progress, setProgress] = useState(0);
    const [jobId, setJobId] = useState(null);
    const [isNewDevice, setIsNewDevice] = useState(false);
    const [isSkipped, setIsSkipped] = useState(false);
    const [continueInBackground, setContinueInBackground] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [showFloatingNotification, setShowFloatingNotification] = useState(false);
    const [needsEUPSetup, setNeedsEUPSetup] = useState(false);
    const processingRef = useRef(false);
    const eupPromptRef = useRef<React.ReactElement | null>(null);
    const [hasShownSuccess, setHasShownSuccess] = useState(false);
    const [showPromptAfterSkip, setShowPromptAfterSkip] = useState(false);
    const [showSuccessPrompt, setShowSuccessPrompt] = useState(false);

    const checkPendingJobs = async (forceCheck = false) => {
        if (!session?.user?.id) return;
        
        const now = Date.now();
        const timeSinceLastCheck = now - lastJobsCheckTime;
        const timeSinceEUPSet = now - lastEUPSetTime;
        
        // Return cached job if:
        // 1. Not forcing a check
        // 2. Within cache duration
        // 3. Not in the 5-minute window after EUP set OR within the 30-second check interval
        if (!forceCheck && 
            timeSinceLastCheck < JOBS_CACHE_DURATION && 
            (timeSinceEUPSet > JOBS_CACHE_DURATION || timeSinceLastCheck < FORCE_CHECK_INTERVAL)) {
            return cachedPendingJob;
        }

        try {
            const jobsResponse = await fetch('/api/auth/check-pending-jobs');
            const { pendingJob } = await jobsResponse.json();

            lastJobsCheckTime = now;
            cachedPendingJob = pendingJob;

            if (pendingJob) {
                setJobId(pendingJob.id);
                setIsProcessing(true);
                setProgress(pendingJob.progress || 0);
            } else {
                setIsProcessing(false);
                setJobId(null);
            }

            return pendingJob;
        } catch (error) {
            console.error(__('Error checking pending jobs:'), error);
            return null;
        }
    };

    const processEncryptionJob = async (jobId: string): Promise<void> => {
        if (!session?.user?.id) return;

        try {
            while (true) {
                const response = await fetch('/api/auth/process-encryption-job', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({jobId}),
                });

                if (!response.ok) {
                    throw new Error(__('Failed to process encryption job'));
                }

                const {progress, status, processedItems, failedItems} = await response.json();
                setProgress(progress);

                const lowerStatus = status.toLowerCase();

                if (lowerStatus === 'completed') {
                    const pendingJob = await checkPendingJobs(true);
                    if (!pendingJob) {
                        // Only show success if we haven't shown it before
                        if (!hasShownSuccess && !isNewDevice) {
                            setShowFloatingNotification(false);
                            setHasShownSuccess(true);
                            // The EUPPrompt component will handle showing the success step
                        }
                        break;
                    }
                } else if (lowerStatus === 'processing') {
                    await new Promise(resolve => setTimeout(resolve, 1000));
                } else {
                    throw new Error(__('Unexpected job status: {{status}}', { placeholders: { status } }));
                }
            }
        } catch (error) {
            console.error(__('Error processing encryption job:'), error);
        }
    };

    const checkEUPStatus = async (skipCache = false) => {
        if (!session?.user?.id) return null;

        if (!skipCache && cachedEUPStatus && Date.now() - lastEUPCheckTime < EUP_CACHE_DURATION) {
            return cachedEUPStatus;
        }

        try {
            const eupResponse = await fetch('/api/auth/check-eup-set');
            cachedEUPStatus = await eupResponse.json();
            lastEUPCheckTime = Date.now();
            return cachedEUPStatus;
        } catch (error) {
            console.error(__('Error checking EUP status:'), error);
            return null;
        }
    };

    useEffect(() => {
        const checkEUPSetAndProcessJobs = async () => {
            if (!session?.user?.id || processingRef.current) return;

            processingRef.current = true;
            setIsLoading(true);

            try {
                const eupStatus = await checkEUPStatus();
                if (!eupStatus) return;

                const { isSet, isNewDevice: newDevice } = eupStatus;
                setIsEUPSet(isSet);
                setIsNewDevice(newDevice);
                setNeedsEUPSetup(!isSet || newDevice);
                setShowSuccessPrompt(newDevice);

                // Only check pending jobs if EUP is set and it's not a new device
                if (isSet && !newDevice) {
                    const pendingJob = await checkPendingJobs();
                    if (pendingJob) {
                        await processEncryptionJob(pendingJob.id);
                    }
                }
            } catch (error) {
                console.error(__('Error checking EUP and processing jobs:'), error);
            } finally {
                processingRef.current = false;
                setIsLoading(false);
            }
        };

        checkEUPSetAndProcessJobs();

        // Only set up interval if we need to monitor ongoing jobs
        const intervalId = isProcessing ? 
            setInterval(checkEUPSetAndProcessJobs, FORCE_CHECK_INTERVAL) : 
            undefined;

        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [session, isProcessing]); // Only re-run when session or isProcessing changes

    const setEUP = async (up: string, inBackground: boolean): Promise<string> => {
        if (!session?.user?.id) {
            throw new Error(__('User is not authenticated'));
        }

        if (processingRef.current) {
            throw new Error(__('Another operation is in progress'));
        }

        processingRef.current = true;
        try {
            const response = await fetch('/api/auth/set-eup', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ up }),
            });

            if (!response.ok) {
                throw new Error(__('Failed to set EUP'));
            }

            const { jobId: newJobId } = await response.json();
            setJobId(newJobId);
            setIsProcessing(true);
            setIsEUPSet(true);
            setContinueInBackground(inBackground);
            lastEUPSetTime = Date.now(); // Record when EUP was set
            
            if (!inBackground) {
                await processEncryptionJob(newJobId);
            } else {
                // Start the encryption job in the background
                processEncryptionJob(newJobId).catch(console.error);
            }
            
            return newJobId;
        } catch (error) {
            console.error(__('Error setting EUP:'), error);
            throw error;
        } finally {
            processingRef.current = false;
        }
    };

    const handleSkipEUPSetup = () => {
        setIsSkipped(true);
        // Set a timeout to show the prompt again after 15 seconds
        setTimeout(() => {
            setShowPromptAfterSkip(true);
        }, 15000);
    };

    const verifyEUP = async (up: string): Promise<boolean> => {
        if (!session?.user?.id) {
            throw new Error(__('User is not authenticated'));
        }

        try {
            const response = await fetch('/api/auth/verify-eup', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ up }),
            });

            if (!response.ok) {
                if (response.status === 401) {
                    throw new Error('401');
                }
                throw new Error(__('Failed to verify EUP'));
            }

            const { success } = await response.json();
            if (success) {
                // Check EUP status immediately after verification, skipping cache
                const eupStatus = await checkEUPStatus(true);
                if (eupStatus) {
                    setIsEUPSet(eupStatus.isSet);
                    setIsNewDevice(eupStatus.isNewDevice);
                    setNeedsEUPSetup(!eupStatus.isSet || eupStatus.isNewDevice);
                }
            }
            return success;
        } catch (error) {
            console.error(__('Error verifying EUP:'), error);
            throw error;
        }
    };

    const contextValue: EUPContextType = {
        isEUPSet,
        isProcessing,
        progress,
        setEUP,
        processEncryptionJob,
        jobId,
        isNewDevice,
        isSkipped,
        skipEUPSetup: handleSkipEUPSetup,
        verifyEUP,
        continueInBackground,
        setContinueInBackground,
        showFloatingNotification,
        setShowFloatingNotification,
        isLoading,
        needsEUPSetup,
        hasShownSuccess,
        setHasShownSuccess,
        showPromptAfterSkip,
        setShowPromptAfterSkip,
        showSuccessPrompt,
        setShowSuccessPrompt,
    };

    if (!eupPromptRef.current) {
        eupPromptRef.current = <EUPPrompt/>;
    }

    return (
        <EUPContext.Provider value={contextValue}>
            {status === 'loading' ? (
                <LoadingSpinner
                    fullViewport={true}
                    size="3em"
                    thickness={3}
                    speed={0.8}
                    title={__('Loading')}
                    messages={[__('Please wait while we set up your session...')]}
                />
            ) : status !== 'unauthenticated' ? (
                <>
                    {children}
                    {(needsEUPSetup || isProcessing || showSuccessPrompt) && eupPromptRef.current}
                    {showFloatingNotification && isProcessing && (
                        <FloatingProgressNotification
                            progress={progress}
                            onExpand={() => setShowFloatingNotification(false)}
                        />
                    )}
                </>
            ) : null}
        </EUPContext.Provider>
    );
};
