'use client';

import React, { createContext, useContext, ReactNode } from 'react';
import { EventEmitter, eventBus } from '@/utils/EventBus';

const EventContext = createContext<EventEmitter | undefined>(undefined);

export const EventProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <EventContext.Provider value={eventBus}>
      {children}
    </EventContext.Provider>
  );
};

export const useEventBus = () => {
  const context = useContext(EventContext);
  if (context === undefined) {
    throw new Error('useEventBus must be used within an EventProvider');
  }
  return context;
};