'use client';

import { useState } from 'react';
import { FiDollarSign, FiClock, FiArrowRight } from 'react-icons/fi';
import { __ } from '@/utils/translationUtils';
import { motion } from 'framer-motion';

interface MiniCreditPackagesProps {
  onPackageSelect: (amount: number) => void;
  isLoading?: boolean;
}

export function MiniCreditPackages({ onPackageSelect, isLoading }: MiniCreditPackagesProps) {
  const [selectedAmount, setSelectedAmount] = useState<number | null>(null);

  const packages = [
    { amount: 10, minutes: 300, label: __('Starter', { source: 'billing' }) },
    { amount: 50, minutes: 1500, label: __('Popular', { source: 'billing' }), recommended: true },
  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
      {packages.map((pkg) => (
        <motion.div
          key={pkg.amount}
          whileHover={{ y: -2 }}
          className={`relative bg-white dark:bg-gray-800 rounded-lg shadow p-4 cursor-pointer border ${
            selectedAmount === pkg.amount 
              ? 'border-blue-500 ring-1 ring-blue-500/50' 
              : 'border-transparent'
          }`}
          onClick={() => {
            setSelectedAmount(pkg.amount);
            onPackageSelect(pkg.amount);
          }}
        >
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-base font-semibold text-gray-900 dark:text-white">{pkg.label}</h3>
          </div>

          <div className="mb-2">
            <div className="flex items-center">
              <FiDollarSign className="w-5 h-5 text-blue-500 mr-1" />
              <span className="text-2xl font-bold text-gray-900 dark:text-white">${pkg.amount}</span>
            </div>
            <div className="flex items-center text-sm text-gray-600 dark:text-gray-300">
              <FiClock className="w-4 h-4 mr-1" />
              <span>{pkg.minutes} {__('minutes', { source: 'billing' })}</span>
            </div>
          </div>

          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={(e) => {
              e.stopPropagation();
              onPackageSelect(pkg.amount);
            }}
            disabled={isLoading}
            className="w-full flex items-center justify-center px-4 py-2 text-sm bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
          >
            {isLoading && selectedAmount === pkg.amount ? (
              <span className="flex items-center">
                <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                {__('Processing...', { source: 'core' })}
              </span>
            ) : (
              <span className="flex items-center">
                {__('Select', { source: 'billing' })}
                <FiArrowRight className="ml-2" />
              </span>
            )}
          </motion.button>
        </motion.div>
      ))}
    </div>
  );
} 