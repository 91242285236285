export const isElementVisible = (selector: string): boolean => {
    if (typeof window === 'undefined') return false;
    const element = document.querySelector(selector);
    if (!element) return false;

    const rect = element.getBoundingClientRect();
    const style = window.getComputedStyle(element);

    // Check if the element is in the viewport and has non-zero dimensions
    if (
        rect.width === 0 ||
        rect.height === 0 ||
        style.visibility === 'hidden' ||
        style.display === 'none' ||
        rect.bottom < 0 ||
        rect.top > window.innerHeight ||
        rect.right < 0 ||
        rect.left > window.innerWidth
    ) {
        return false;
    }

    // Check if the element is not covered by other elements (excluding the walkthrough overlay)
    const points = [
        { x: rect.left, y: rect.top },
        { x: rect.right - 1, y: rect.top },
        { x: rect.left, y: rect.bottom - 1 },
        { x: rect.right - 1, y: rect.bottom - 1 },
        { x: (rect.left + rect.right) / 2, y: (rect.top + rect.bottom) / 2 },
    ];

    const isElementTopmost = (point: { x: number; y: number }) => {
        const elements = document.elementsFromPoint(point.x, point.y);
        for (const el of elements) {
            if (el.classList.contains('walkthrough-overlay')) continue;
            return element.contains(el) || element === el;
        }
        return false;
    };

    return points.some(isElementTopmost);
};



export const getFirstVisibleElement = (selector: string): Element | null => {
    if (typeof window === 'undefined') return null;
    const elements = document.querySelectorAll(selector);
    for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        const rect = element.getBoundingClientRect();
        const style = window.getComputedStyle(element);

        if (
            rect.width > 0 &&
            rect.height > 0 &&
            style.visibility !== 'hidden' &&
            style.display !== 'none' &&
            rect.top < window.innerHeight &&
            rect.bottom > 0 &&
            rect.left < window.innerWidth &&
            rect.right > 0
        ) {
            return element;
        }
    }
    return null;
};
