import { useState, useEffect } from 'react';

const DISMISS_COOKIE_NAME = 'install_prompt_dismissed';

export const useInstallPrompt = () => {
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
  const [showInstallPrompt, setShowInstallPrompt] = useState(false);
  const [isIOS, setIsIOS] = useState(false);

  const isDesktop = typeof window !== 'undefined' && !('ontouchstart' in window);

  useEffect(() => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
    setIsIOS(isIOS);

    const handleBeforeInstallPrompt = (e: Event) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  useEffect(() => {
    const checkInstallability = () => {
      const isInstallable = deferredPrompt !== null || isIOS;
      const isAppInstalledValue = isAppInstalled();
      const isPromptDismissedValue = isPromptDismissed();
      const shouldShowPrompt = isInstallable && !isPromptDismissedValue && !isAppInstalledValue;

      setShowInstallPrompt(shouldShowPrompt);
    };

    checkInstallability();

    const handleBeforeInstallPrompt = (e: Event) => {
      e.preventDefault();
      setDeferredPrompt(e);
      checkInstallability();
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, [isIOS, deferredPrompt]);

  const isAppInstalled = () => {
    const installed = window.matchMedia('(display-mode: standalone)').matches ||
      (window.navigator as any).standalone === true;
    return installed;
  };

  const isPromptDismissed = () => {
    const dismissedUntil = localStorage.getItem(DISMISS_COOKIE_NAME);
    const dismissed = dismissedUntil && new Date(dismissedUntil) > new Date();
    return dismissed;
  };

  const handleInstall = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult: { outcome: string }) => {
        if (choiceResult.outcome === 'accepted') {
        } else {
        }
        setDeferredPrompt(null);
        setShowInstallPrompt(false);
      });
    }
  };

  const handleDismiss = () => {
    const dismissUntil = new Date();
    dismissUntil.setDate(dismissUntil.getDate() + 1);
    localStorage.setItem(DISMISS_COOKIE_NAME, dismissUntil.toISOString());
    setShowInstallPrompt(false);
  };


  return { showInstallPrompt, handleInstall, handleDismiss, isIOS, isDesktop };
};