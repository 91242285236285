// src/services/tts.ts
import { eventBus } from '@/utils/EventBus';
import { EVENTS } from '@/constants/events';
import { Note } from '@/lib/notes/Note';

export const generateSpeech = async (text: string, voice: string): Promise<Note> => {
    const response = await fetch('/api/read', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({text, voice}),
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to generate speech');
    }

    const data: Note = await response.json();
    
    // Trigger the SPEECH_GENERATED event
    eventBus.emit(EVENTS.SPEECH_GENERATED, data);

    return data;
};
