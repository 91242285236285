export const transcribeAudio = async (audioBlob: Blob): Promise<{ text: string; language: string; verboseJson?: any }> => {
  try {
    const response = await fetch('/api/v2/transcribe', {
      method: 'POST',
      body: audioBlob,
      headers: {
        'Content-Type': audioBlob.type,
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Transcription failed');
    }

    const result = await response.json();
    return { 
      text: result.text, 
      language: result.language,
      verboseJson: result.verboseJson
    };
  } catch (error) {
    console.error('Transcription error:', error);
    throw error;
  }
};