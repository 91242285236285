'use client';

import React, { createContext, useContext, useState, useCallback } from 'react';
import debounce from 'lodash/debounce';

type UserMetaContextType = {
    getUserMeta: (key: string) => Promise<string | null>;
    setUserMeta: (key: string, value: string) => void;
};

const UserMetaContext = createContext<UserMetaContextType | undefined>(undefined);

export const UserMetaProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const getUserMeta = async (key: string): Promise<string | null> => {
        const response = await fetch(`/api/user-meta?key=${encodeURIComponent(key)}`);
        if (!response.ok) {
            throw new Error('Failed to get user meta');
        }
        const data = await response.json();
        return data.value;
    };

    const setUserMetaDebounced = useCallback(
        debounce(async (key: string, value: string) => {
            const response = await fetch('/api/user-meta', {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ key, value }),
            });
            if (!response.ok) {
                throw new Error('Failed to set user meta');
            }
        }, 300),
        []
    );

    const setUserMeta = (key: string, value: string): void => {
        setUserMetaDebounced(key, value);
    };

    return (
        <UserMetaContext.Provider value={{ getUserMeta, setUserMeta }}>
            {children}
        </UserMetaContext.Provider>
    );
};

export const useUserMeta = () => {
    const context = useContext(UserMetaContext);
    if (context === undefined) {
        throw new Error('useUserMeta must be used within a UserMetaProvider');
    }
    return context;
};